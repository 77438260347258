import styled from 'styled-components';

const handleBackColor = (color) => {
  switch (color) {
    case 'secondary':
      return `
              background-color: var(--dark-blue);
              border: 0.2rem solid var(--dark-blue);
            `;
    case 'dark-blue':
      return `
              background-color: var(--white);
              border: 0.2rem solid var(--dark-blue);
            `;
    case 'line-dark-blue':
      return `
              background-color: var(--white);
              border: 0.2rem solid var(--dark-blue);
            `;
    case 'line-light-blue':
      return `
              background-color: var(--white);
              border: 0.2rem solid var(--azul-claro);
            `;
    case 'light-blue':
      return `
              background-color: var(--azul-claro);
              border: 0.2rem solid var(--azul-claro);
            `;
    case 'white':
      return `
              background-color: var(--white);
              border: 0.2rem solid var(--white);
              `;
    case 'disabled':
      return `
              background-color: var(--disabled-secondary);
              border: 0.2rem solid var(--disabled-primary);
            `;
    default:
      return `
              background-color: var(--green-primary);
              border: 0.2rem solid var(--green-primary);
            `;
  }
};

const handleTextColor = (color) => {
  switch (color) {
    case 'secondary':
      return `
              color: var(--white);
            `;
    case 'dark-blue':
      return `
              color: var(--dark-blue);
            `;
    case 'line-dark-blue':
      return `
              color: var(--dark-blue);
            `;
    case 'line-light-blue':
      return `
              color: var(--azul-claro);
            `;
    case 'light-blue':
      return `
              color: var(--white);
            `;
    case 'white':
      return `
              color: var(--dark-blue);
              `;
    case 'disabled':
      return `
              color: var(--disabled-terciary);
            `;
    default:
      return `
              color: var(--white);
            `;
  }
};
const handleBackColorHover = (color) => {
  switch (color) {
    case 'secondary':
      return `
              background-color: var(--azul-claro);
              border: 0.2rem solid var(--azul-claro);
           `;
    case 'dark-blue':
      return `
              background-color: var(--dark-blue);
              border: 0.2rem solid var(--dark-blue);
           `;
    case 'line-dark-blue':
      return `
              background-color: var(--white);
              border: 0.2rem solid var(--dark-blue);
            `;
    case 'line-light-blue':
      return `
              background-color: var(--white);
              border: 0.2rem solid var(--azul-claro);
            `;
    case 'light-blue':
      return `
              background-color: var(--dark-blue);
              border: 0.2rem solid var(--dark-blue);
            `;
    case 'white':
      return `
              background-color: var(--dark-blue);
              border: 0.2rem solid var(--dark-blue);
              `;
    case 'disabled':
      return `
              background-color: var(--disabled-secondary );
              border: 0.2rem solid var(--disabled-primary);
            `;
    default:
      return `
              background-color: var(--dark-blue);
              border: 0.2rem solid var(--dark-blue);
            `;
  }
};

const handleTextColorHover = (color) => {
  switch (color) {
    case 'secondary':
      return `
              color: var(--white);
           `;
    case 'dark-blue':
      return `
              color: var(--white);
           `;
    case 'line-dark-blue':
      return `
              color: var(--dark-blue);
            `;
    case 'line-light-blue':
      return `
              color: var(--azul-claro);
            `;
    case 'light-blue':
      return `
              color: var(--white);
            `;
    case 'white':
      return `
               color: var(--white);
              `;
    case 'disabled':
      return `
              color: var(--disabled-terciary);
            `;
    default:
      return `
              color: var(--white);
            `;
  }
};

const StyledButton = styled.button`
  ${({ color }) => handleBackColorHover(color)};
  border: none;
  border-radius: 10rem;
  cursor: pointer;
  font: 400 1.4rem var(--font-Base);
  height: 4.2rem;
  letter-spacing: 0.05rem;
  min-width: 12.8rem;
  overflow: hidden;
  padding: 1.2rem;
  position: relative;
  width: fit-content;
  z-index: 0;
  span {
    ${({ color }) => handleTextColor(color)};
    position: relative;
    z-index: 2;
  }
  &:before {
    ${({ color }) => handleBackColor(color)};
    border-radius: 10rem;
    content: '';
    height: 4.2rem;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    width: 100%;
    z-index: 1;
  }
  &:after {
    ${({ color }) => handleBackColorHover(color)};
    ${({ color }) => handleTextColorHover(color)};
    align-items: center;
    border-radius: 10rem;
    content: '${({ text }) => text}';
    display: flex;
    height: 4.2rem;
    justify-content: center;
    left: -100%;
    position: absolute;
    top: 0;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    width: 100%;
    z-index: 3;
  }
  &:hover {
    &:before {
      left: 100%;
      transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }
    &:after {
      left: 0;
      transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }
  }
`;

const AnimatedButton = ({ color, children, ...rest }) => (
  <StyledButton color={color} text={children} {...rest}>
    <span>{children}</span>
  </StyledButton>
);

export default AnimatedButton;
