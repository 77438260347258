import React, {useState} from 'react';
import backContactUs from "../Assets/Images/back_top_contact_mobile.png";
import backContactUsDesktop from "../Assets/Images/back_top_contact_desktop.png";

import {Header1} from "../features/Utilities/FontStles";


import ArrowDown from "../features/ui/Arrows/ArrowDown";
import BackBlueAbout from "../features/ui/Hero/BackBlueAbout";
import HeroSecondary from "../features/ui/Hero/HeroSecondary";
import LayoutTitle from "../features/ui/Layout/LayoutTitle";


import styled from 'styled-components';
import FormContactUs from "../features/ContactUs/FormContactUs";
import ContactUsSuccess from "../features/ContactUs/ContactUsSuccess";
import {useDispatch} from "react-redux";
import {contactUs} from "../redux/actions";

const StyleLayoutContactUs = styled.div`

`;

const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


const ContactUs = () => {
    const [currentStep, setCurrentStep] = useState(0);

    const dispatch = useDispatch();

    const handleSubmit = (values) => {
      dispatch(contactUs.contactUsAction({data:values}))
      setCurrentStep(prevState => prevState + 1);
    }
  const handleNextStep = () => {
    setCurrentStep(prevState => prevState + 1);
  }
    const steps = [
        {
            element :  <FormContactUs
                handleSubmit = {handleSubmit}
                nextStep={handleNextStep}
            />
        },
        {
            element: <ContactUsSuccess/>
        }
    ]
    return (
        <StyleLayoutContactUs>
            <HeroSecondary heroBackground={backContactUs}
                           heroBackgroundDesktop={backContactUsDesktop}>
                <LayoutTitle>
                    <Header1>
                        ¿Quieres comunicarte con nosotros?
                    </Header1>
                </LayoutTitle>
            </HeroSecondary>
            <ArrowDown/>
            <ContactUsWrapper>
                { steps[currentStep].element}
            </ContactUsWrapper>
            <BackBlueAbout/>
        </StyleLayoutContactUs>
    );
};

export default ContactUs;
