import React from 'react';
import Navbar from "../navbar/Navbar";
import styled from "styled-components";

const StyledHeader = styled.div`
    font: 450 1.4rem/1.5rem "CircularStdFont";
    background-image: url(${({heroBackground}) =>heroBackground});
    background-size: cover;
    background-position: bottom;
    @media(min-width: 995px){
    background-image: url(${({heroBackgroundDesktop}) =>heroBackgroundDesktop});
  }
`;


function HeroSecondary({heroBackground, heroBackgroundDesktop, children}) {
    return (
        <StyledHeader heroBackground={heroBackground}
                      heroBackgroundDesktop={heroBackgroundDesktop}>
            <Navbar/>
            {children}
        </StyledHeader>

        );
}

export default HeroSecondary;