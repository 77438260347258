import {
    MISSIONS_FAIL,
    MISSIONS_SUCCESS,
    START_MISSIONS,
    START_MISSION_BY_USER,
    FAIL_MISSION_BY_USER, SUCCESS_MISSION_BY_USER
} from "../types/missionTypes";

const initState ={
    loading : false,
    error: null,
    missionsArray: [],
    missionByUser : []
};

const missionsReducer = ( state = initState, action ) => {
    switch ( action.type ){
        case START_MISSIONS:
        case START_MISSION_BY_USER:
            return {
                ...state,
                loading: true
            }
        case MISSIONS_SUCCESS:
            return {
                ...state,
                missionsArray : action.payload,
                loading:false
            }
        case MISSIONS_FAIL:
        case FAIL_MISSION_BY_USER:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case SUCCESS_MISSION_BY_USER:
            return{
                ...state,
                missionByUser: action.payload
            }
        default:
            return state;
    }
}
export  default missionsReducer;
