import React from 'react';
import backRegister from "../Assets/Images/back_register_mobile.png";
import backRegisterDesktop from "../Assets/Images/back_register_desktop.png";
import styled from "styled-components";
import HeroSecondary from "../features/ui/Hero/HeroSecondary";
import {Header5} from "../features/Utilities/FontStles";
import {Link} from "react-router-dom";
import routes from "../helpers/routes";
import FormRegister from "../features/Register/FormRegister";
const StyleLayoutAbout = styled.div`
    
  .register__wrapper {
    min-height: 60rem;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    padding-bottom: 4rem;
  }
  .register_title{
    font: 400 2.4rem/2.6rem var(--font-Base-Bold);
    color: var(--black);
    text-align: center;
  }
  .register_description{
    font: 400 1.6rem/2rem var(--font-Base);
    margin-top: 1.1rem;
    text-align: center;
  }
  .mt-2{
    margin-top: 2.4rem;
  }

  .is-center{
    text-align: center;
  }
  @media( min-width: 995px){
    .register__wrapper{
      justify-content: flex-end;
      margin-right: 26rem;
    }
  }
    
`;
const Register = () => {

    return (
        <StyleLayoutAbout>
            <HeroSecondary
                heroBackground={backRegister}
                heroBackgroundDesktop={backRegisterDesktop}
            >
                <div className="register__wrapper">
                    <div className="register__form">
                        <h1 className="register_title is-center">Regístrate</h1>
                        <h2 className="register_description is-center">¡Gracias por elegir ser parte de Smart Points!</h2>
                        <FormRegister/>
                        <Header5 className="is-center mt-2">
                            ¿Ya tienes cuenta?  <Link to={routes.logIn} className="is_blue is_underlined"> Inicia sesión</Link>.
                        </Header5>
                    </div>
                </div>

            </HeroSecondary>
        </StyleLayoutAbout>
    );
};

export default Register;