import * as Yup from 'yup';


export const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .required('Por favor, introduce tu nombre'),
    email: Yup.string()
        .email('Por favor, introduce una dirección de correo electrónico válida')
        .required('Por favor, introduce tu dirección de correo electrónico'),
    message: Yup.string()
        .required('Por favor, introduce un mensaje'),
});