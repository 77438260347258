import { Form, Formik } from 'formik';
import { ForgotSchema } from './ForgotSchema';
import FormikControl from '../ui/Forms/FormikControl';
import { StyledInlineErrorMessage } from '../Register/styles';
import { Link } from 'react-router-dom';
import AnimatedButton from '../ui/Buttons/AnimatedButton';
import React from 'react';
import { useSelector } from 'react-redux';
import routes from '../../helpers/routes';

const FormForgotPassword = props => {
  const { loading } = useSelector(state => state.auth);

  return (
    <>
      <div className='top_text'>
        <h1>Recupera tu contraseña</h1>
        <h2>Te enviaremos un email con un enlace para que puedas restablecer tu contraseña.</h2>
      </div>
      <Formik
        initialValues={props.data}
        validationSchema={ForgotSchema}
        onSubmit={(values, { resetForm }) => {
          resetForm();
          props.handleSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className='form-item'>
              <FormikControl
                name='email'
                control='input'
                type='email'
                label='Correo electrónico'
                placeholder='Escribe tu correo electrónico'
              />

              {errors.email && touched.email ? (
                <StyledInlineErrorMessage>{errors.email}</StyledInlineErrorMessage>
              ) : (
                <StyledInlineErrorMessage />
              )}
            </div>

            <div className='buttons'>
              <Link to={routes.logIn}>
                <AnimatedButton color='dark-blue'>Cancelar</AnimatedButton>
              </Link>
              <AnimatedButton color='secondary' type='submit'>
                {loading ? 'Cargando' : 'Restablecer contraseña'}
              </AnimatedButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormForgotPassword;
