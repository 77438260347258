import axios from 'axios';

const instance = axios.create({
  // baseURL:
  //   process.env.NODE_ENV === "production"
  //     ? process.env.REACT_APP_API_URL
  //     : "http://localhost:1337/api",
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

instance.interceptors.request.use(config => {
  const token = sessionStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token.replace(/\"/g, '')}`;
  return config;
});

export default instance;
