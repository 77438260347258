import React from "react";

import HeroSecondary from "../features/ui/Hero/HeroSecondary";
import LayoutTitle from "../features/ui/Layout/LayoutTitle";

import backAbout from "../Assets/Images/back_about_mobile.png";
import backAboutDesktop from "../Assets/Images/back_about_desktop.png";
import ArrowDown from "../features/ui/Arrows/ArrowDown";

import { Header1, Header4, Header5 } from "../features/Utilities/FontStles";
import styled from "styled-components";
import BackBlueAbout from "../features/ui/Hero/BackBlueAbout";
import { Link } from "react-router-dom";
import routes from "../helpers/routes";

import Doc1 from "../Assets/Images/doc4.png";
import Doc2 from "../Assets/Images/doc5.png";
import Doc3 from "../Assets/Images/doc6.png";
import ElipseBlue from "../Assets/Images/elipse_blue.png";
import ElipseGreen from "../Assets/Images/elipse_green.png";
import CarouselHand from "../features/ui/Carousel/CarouselHand";

const StyleLayoutAbout = styled.div`
  text-align: center;
  background-color: var(--white);

  .about__what-is {
    padding-block-start: 5.2rem;
    padding-block-end: 2rem;
    padding-inline-start: 2.5rem;
    padding-inline-end: 2.5rem;
  }
  .mt-30 {
    margin-top: 3rem;
  }
  .about__how-works {
    background-color: var(--gris-claro);
    padding-block-start: 23rem;
    padding-block-end: 10rem;
  }
  .is-blue {
    color: var(--dark-blue);
  }
  .is-underline {
    text-decoration-line: underline;
  }
  .mb-50 {
    margin-bottom: 5rem;
  }
  .mb-30 {
    margin-bottom: 3rem;
  }
.images{
  display: none;
}
.images_left{
  display: none;
}
.container_carousel{
  display: flex;
  justify-content: center;
}
  @media (min-width: 995px) {
    .about__what-is {
      padding-block-start: 11rem;
      padding-block-end: 4rem;
      padding-inline-start: 20rem;
      padding-inline-end: 20rem;
    }
    .about__how-works {
      padding-block-start: 23rem;
      /* padding-block-end: 10rem; */
    }
  }
  @media (min-width: 1200px) {
    .images {
      position: absolute;
      display: block;
      display: flex;
      flex-direction: row-reverse;
      right: 200px;
      bottom: 0px;
      top: 800px;
      .zoom-in-out-box {
        animation: zoom-in-zoom-out 4s ease infinite;
        animation-delay: 2s;
      }
      @keyframes zoom-in-zoom-out {
        0% {
          transform: scale(1, 1);
        }
        50% {
          transform: scale(1.1, 1.1);
        }
        100% {
          transform: scale(1, 1);
        }
      }
      .images_rigth {
        display: flex;
        flex-direction: column;
        img {
          width: 350px;
        }
        .elipse {
          position: absolute;
          width: 50px;
          top: 250px;
        }
      }
    }
    .images_left {
      position: absolute;
      display: block;
      display: flex;
      flex-direction: column;
      left: 300px;
      margin-bottom: 600px;
      top: 800px;
      .zoom-in-out-box {
        animation: zoom-in-zoom-out 7s ease infinite;
      }
      @keyframes zoom-in-zoom-out {
        0% {
          transform: scale(1, 1);
        }
        50% {
          transform: scale(1.2, 1.2);
        }
        100% {
          transform: scale(1, 1);
        }
      }
      .elipse {
        position: absolute;
        bottom: -100px;
        left: 250px;
        img {
          width: 40px !important;
        }
      }
      .docs {
        width: 300px;
        position: absolute;
      }
      .first {
        margin-top: 100px;
        left: 100px;
        z-index: 2;
      }
    }
  }
`;
const HeaderQuestion = styled.h2`
  font: normal 1.8rem/1.9rem var(--font-Base);
  color: var(--dark-blue);
`;

export default function About() {
  return (
    <StyleLayoutAbout className="about">
      <HeroSecondary
        heroBackground={backAbout}
        heroBackgroundDesktop={backAboutDesktop}
      >
        <LayoutTitle>
          <Header1>Conoce un poco más sobre Smart Points</Header1>
        </LayoutTitle>
      </HeroSecondary>
      <ArrowDown />
      <div className="about__what-is">
        <Header4 style={{ color: "var(--green-primary)" }}>
          ¿QUÉ ES SMART POINTS?
        </Header4>
        <Header5 className="mt-30">
          Es un programa que reconoce tu fidelidad y valora el compromiso que tienes con la salud femenina. En Bayer, sabemos sobre el esfuerzo que pones en actualizarte día con día y queremos devolverte un poco de lo que das.        </Header5>
        <Header1 style={{ marginTop: "40px", marginBottom: "180px", textTransform:'uppercase' }}>
          ¡Entre más misiones realices, <br />{" "}
          <span style={{ color: "#02BCFF" }}>más puntos acumulas!</span>
        </Header1>
      </div>
      <div className="images">
        <div className="images_rigth">
          <img className="zoom-in-out-box" src={Doc1} alt="image_animation"/>
          <img className="elipse zoom-in-out-box" src={ElipseGreen} alt="image_animation"/>
        </div>
      </div>
      <div className="images_left">
        <img className="docs first zoom-in-out-box" src={Doc2} alt="image_animation"/>
        <img className="docs zoom-in-out-box" src={Doc3} alt="image_animation"/>
        <img className="elipse zoom-in-out-box" src={ElipseBlue} alt="image_animation"/>
      </div>
      <div className="about__how-works">
        <Header4 color="green" className="mb-50">
          ¿CÓMO FUNCIONA SMART POINTS?
        </Header4>

        <div className="container_carousel">
          <CarouselHand />
        </div>
        <HeaderQuestion className="mb-30">¿Aún con dudas?</HeaderQuestion>
        <Header5>
          Ve a{" "}
          <Link to={routes.frequentQuestions} className="is_blue is_underlined">
            {" "}
            preguntas frecuentes
          </Link>{" "}
          y te poyaremos a resolveras.
        </Header5>
      </div>

      <BackBlueAbout />
    </StyleLayoutAbout>
  );
}
