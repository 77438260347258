import React from "react";

const QuestionParagraph = (props) => {
  const {answer} = props;

  return (
    <p>{answer}</p>
  );
};

export default QuestionParagraph;
