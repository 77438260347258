import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import routes from "../helpers/routes";
import Footer from "../features/ui/Footers";

const PublicRoute = () => {
    const {user} = useSelector(state => state.auth);

    let location = useLocation();

    if (user) {
        return <Navigate to={routes.dashboard} state={{from: location}} replace/>
    }

    return (
        <Fragment>
        <Outlet/>

        <Footer/>

    </Fragment>)
};

export default PublicRoute;