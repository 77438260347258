import React from 'react';
import styled from 'styled-components';
import { CardLayout } from './Card';
import { Link } from 'react-router-dom';

import routes from '../../../helpers/routes';
import Button from '../Buttons/Button';

import card_back from '../../../Assets/Images/back_blue_home_secondary_desktop.png';
import doctor from '../../../Assets/Images/doctor-help.png';
import icn_pregunta from '../../../Assets/icons/icn_pregunta.svg';

const CardHelpWrapper = styled(CardLayout)`
  display: none;
  gap: 2rem;
  color: var(--white);
  line-height: 1.5rem;
  min-width: 14.3rem;
  background: url(${card_back}) no-repeat;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  margin-top: 7.5rem;
  padding: 2rem;
  position: relative;
  button {
    margin: auto;
  }
  h1 {
    font: 700 1.9rem/1.6rem var(--font-Base-Bold);
    line-height: 1.9rem;
  }
  img.doctor {
    left: -1.5rem;
    position: absolute;
    top: -7.5rem;
  }
  img.icon {
    margin-left: auto;
    width: fit-content;
  }
  @media (min-width: 992px) {
    display: flex;
  }
`;

const CardHelp = () => {
  const { role } = JSON.parse(sessionStorage.getItem('user'));
  return (
    <CardHelpWrapper>
      <img alt='doctor' className='doctor' src={doctor} />
      <img alt='icon-doubt' className='icon' src={icn_pregunta} />
      <h1>¿Necesitas ayuda?</h1>
      <p>Comunícate con nosotros para aclarar tus dudas.</p>

      <Button color='white'>
        <Link
          to={
            role === 'DISTRIBUTOR'
              ? routes.dashboardRoutes.contactInfo
              : routes.dashboardRoutes.contact
          }
        >
          Ir al contacto
        </Link>
      </Button>
    </CardHelpWrapper>
  );
};

export default CardHelp;
