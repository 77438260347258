import { useState } from 'react';
import { auth } from '../../redux/actions';
import { Form, Formik } from 'formik';
import FormikControl from '../ui/Forms/FormikControl';
import { StyledInlineErrorMessage } from '../Register/styles';
import AnimatedButton from '../ui/Buttons/AnimatedButton';
import { LogInSchema } from './LogInSchema';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

const ButtonAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledLabel = styled.div`
label{
  margin-left: 10px;
}
`;
const initState = {
  email: '',
  password: '',
};

const FormLogin = () => {
  const [data, setData] = useState(initState);

  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    dispatch(auth.logInAction(data));
    setData(initState);
  };

  return (
    <StyledLabel>
    <Formik
      initialValues={data}
      validationSchema={LogInSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className='form-item'>
            <FormikControl
              control='input'
              type='email'
              label='Correo electrónico'
              name='email'
              placeholder='Escribe tu correo electrónico'
            />

            {errors.email && touched.email ? (
              <StyledInlineErrorMessage>
                {errors.email}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>

          <div className='form-item'>
            <FormikControl
              control='input'
              type='password'
              label='Contraseña'
              name='password'
              placeholder='Escribe tu contraseña'
            />

            {errors.password && touched.password ? (
              <StyledInlineErrorMessage>
                {errors.password}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>
          <ButtonAction>
            <AnimatedButton type='submit'>INICIAR SESIÓN</AnimatedButton>
          </ButtonAction>
        </Form>
      )}
    </Formik>
    </StyledLabel>
  );
};

export default FormLogin;
