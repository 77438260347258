import axios from 'axios';
import { handleError } from './utils';

class SmartPointsBaseApi {
  constructor() {
    this.smartPointApi = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    });
    this.proxySmartApi = axios.create({
      baseURL: `${process.env.REACT_APP_SMART_PROXY}`,
    });
  }

  /*
       class method to get data
    * */
  getElement = async (url, jwt) => {
    const token = jwt ? jwt : JSON.parse(sessionStorage.getItem('token'));

    try {
      const response = await this.smartPointApi.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (e) {
      const errorObj = handleError(e);
      throw new Error(errorObj.message);
    }
  };
  getElementWithoutToken = async url => {
    try {
      const response = await this.smartPointApi.get(url);

      return response.data;
    } catch (e) {
      const errorObj = handleError(e);
      throw new Error(errorObj.message);
    }
  };
  postElementWithoutToken = async (url, paramsData) => {
    try {
      const response = await this.smartPointApi.post(url, paramsData);
      return response.data;
    } catch (e) {
      const errorObj = handleError(e);
      throw new Error(errorObj.message);
    }
  };
  putElementWithToken = async (url, paramsData) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    try {
      const response = await this.smartPointApi.put(url, paramsData, {
          headers: {Authorization: `Bearer ${token}`}
        }
      )
      return response.data
    } catch (e) {
      throw new Error(e)
    }
  }

  updateElement = async (url, paramsData) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    try {
      const response = await this.smartPointApi.put(url, paramsData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (e) {
      throw new Error(e);
    }
  };
  postProxySmartPoints = async (url, paramsData) => {
    try {
      const response = await this.proxySmartApi.post(url, paramsData);
      return response.data.items;
    } catch (e) {
      throw new Error('Cédula no válida');
    }
  };
  postElementWithToken = async (url, paramsData) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    try {
      const response = await this.smartPointApi.post(url, paramsData, {
        headers: {Authorization: `Bearer ${token}`}
      })
      return response.data
    } catch (e) {
      throw new Error(e)
    }
  }
  updateByIdWithToken = async (url, userId,formData) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    try {
      const response = await this.smartPointApi.put( `${url}/${userId}`, formData, {
        headers: {Authorization: `Bearer ${token}`}
      })
      return response.data
    } catch (e) {
      const errorObj = handleError(e)
      throw new Error(errorObj.message)
    }
  }
  deleteItemWithToken = async (url, itemId) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    try {
      const response = await this.smartPointApi.delete( `${url}/${itemId}`, {
        headers: {Authorization: `Bearer ${token}`}
      })
      return response.data
    } catch (e) {
      const errorObj = handleError(e)
      throw new Error(errorObj.message)
    }
  }
}
const SmartPointsApiBase = new SmartPointsBaseApi();

export default SmartPointsApiBase;
