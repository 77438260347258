import TableDistributor from '../../ui/Tables/TableDistributor/TableDistributor';
import {CircularProgress} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import qs from "qs";
import {getDistributors} from "../../../services/distributors";

const WithOuthDelivery = () => {


  const query = qs.stringify({
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { isLoading,data: distributors } = useQuery({
    queryKey: ['distributors'],
    queryFn: () => getDistributors({ queryStr: query }),
  });

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <>

      <div className='contact-delivery__info'>
        <h2>Aún no tienes un distribuidor</h2>
        <p>Elige a tu nuevo distribuidor y se confirmará en un lapso de 1 semana</p>
      </div>

      <div className='contact-delivery__table'>
        <TableDistributor distributors={distributors} />
      </div>
    </>
  );
};

export default WithOuthDelivery;
