import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import ContactUs from '../pages/ContactUs';
import Register from '../pages/Register';

import routes from '../helpers/routes';
import PublicRoute from './PublicRoute';
import RequireAuth from './RequireAuth';
import Dashboard from '../pages/Dashboard';
import LogIn from '../pages/LogIn';
import ForgotPassword from '../pages/ForgotPassword';
import Missions from '../features/Dashboard/Missions/Missions';
import Levels from '../features/Dashboard/Levels/Levels';
import ContactDelivery from '../features/Dashboard/ContactDelivery/ContactDelivery';
import WhatIs from '../features/Dashboard/WhatIs';
import AccountInfo from '../features/Dashboard/AccountInfo';
import PrivateRoutes from './PrivateRoutes';
import SmartUsers from '../features/Dashboard/SmartUsers';
import SmartUserProfile from '../features/Dashboard/SmartUserProfile';
import SmartUserProfileUpdate from '../features/Dashboard/SmartUserProfile/Update';
import SmartUserProfileHistory from '../features/Dashboard/SmartUserProfile/History';
import ContactInfo from '../features/Dashboard/ContactInfo';
import UploadInstructions from '../features/Dashboard/UploadInstructions';
import Reports from '../features/Dashboard/Reports';
import AdminProfile from '../features/Dashboard/AdminProfile';
import QuestionsPublic from '../features/Dashboard/QuestionPublic';

import { roles } from '../helpers/roles';
import Profile from '../features/Dashboard/Profile/Profile';
import Questions from '../features/Dashboard/Questions';
import UpdateDelivery from '../features/Dashboard/UpdateDelivery/UpdateDelivery';
import ContactAdmin from '../features/Dashboard/Contact/ContactAdmin';
import RecoveryPass from '../features/Dashboard/RecoveryPass';
import RestoresPass from '../pages/RestoresPass';

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path={routes.about} element={<About />} />
        <Route path={routes.frequentQuestions} element={<QuestionsPublic />} />
        <Route path={routes.contactUs} element={<ContactUs />} />
        <Route path={routes.register} element={<Register />} />
        <Route path={routes.logIn} element={<LogIn />} />
        <Route path={routes.main} element={<Home />} />
        <Route path={routes.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.recoveryPassword} element={<RestoresPass />} />
      </Route>
      <Route element={<RequireAuth />}>
        <Route path={routes.dashboard} element={<Dashboard />}>
          <Route index element={<AccountInfo />} />
          <Route element={<PrivateRoutes hasRole={roles.USER} />}>
            <Route path={routes.dashboardRoutes.missions} element={<Missions />} />
            <Route path={routes.dashboardRoutes.levels} element={<Levels />} />

            <Route path={routes.dashboardRoutes.contactDelivery} element={<ContactDelivery />} />
            <Route path={routes.dashboardRoutes.updateDelivery} element={<UpdateDelivery />} />

            <Route path={routes.dashboardRoutes.whatIs} element={<WhatIs />} />
            <Route path={routes.dashboardRoutes.profile} element={<Profile />} />
            <Route path={routes.dashboardRoutes.contact} element={<ContactAdmin />} />
            <Route path={routes.dashboardRoutes.recoveryPass} element={<RecoveryPass />} />
          </Route>
          <Route element={<PrivateRoutes hasRole={roles.DISTRIBUTOR} />}>
            <Route path={routes.dashboardRoutes.smartUsers} element={<SmartUsers />} />
            <Route path={routes.dashboardRoutes.smartUserProfile} element={<SmartUserProfile />} />
            <Route
              path={routes.dashboardRoutes.smartUserProfileUpdate}
              element={<SmartUserProfileUpdate />}
            />
            <Route
              path={routes.dashboardRoutes.smartUserProfileHistory}
              element={<SmartUserProfileHistory />}
            />
            <Route path={routes.dashboardRoutes.reports} element={<Reports />} />
            <Route path={routes.dashboardRoutes.contactInfo} element={<ContactInfo />} />
            <Route
              path={routes.dashboardRoutes.uploadInstructions}
              element={<UploadInstructions />}
            />
            <Route path={routes.dashboardRoutes.adminProfile} element={<AdminProfile />} />
          </Route>

          <Route path={routes.dashboardRoutes.frequentQuestions} element={<Questions />} />
        </Route>
      </Route>

      <Route path='*' element={<Navigate to={routes.main} />} />
    </Routes>
  );
};

export default AppRoutes;
