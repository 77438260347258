import React, { useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from '../features/ui/Sidebar/Sidebar';
import NavBarDashboard from '../features/ui/NavBarDashboard/NavBarDashboard';
import Footer from '../features/ui/Footers';
import { Outlet, useNavigate } from 'react-router-dom';
import { getAllowedRoutes } from '../helpers/utils';
import {routesDashboard, routesNavBar} from '../helpers/routesArray';
import { roles } from '../helpers/roles';
import { default as GlobalRoutes } from '../helpers/routes';
import { useSelector } from 'react-redux';

const DashboardContainer = styled.div`
  display: grid;
  grid-template-areas:
    'sidebar'
    'header'
    'main'
    'footer';
  background-color: var(--azul-oscuro);
  grid-template-rows: auto;
  @media (min-width: 995px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'sidebar header header header header header'
      'sidebar main main main main main'
      'footer footer footer footer footer footer';
  }
`;

const Dashboard = () => {
  let routes = getAllowedRoutes(routesDashboard);

  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (user?.role === roles.DISTRIBUTOR) {
      navigate(GlobalRoutes.dashboardRoutes.smartUsers);
    }
  }, [user?.role]);

  return (
    <DashboardContainer>
      <Sidebar
          routesByRol={routes}
          renderGeneral={user?.role === roles.USER} />
      <NavBarDashboard />
      <Outlet />
      <Footer shortView />
    </DashboardContainer>
  );
};

export default Dashboard;
