import styled from "styled-components";

const LinkText = styled.a`
  color:var(--azul-claro);
`
const QuestionLink = props => {
  const {answer, linkText, linkRef} = props;

  return <p>{answer} <LinkText href={linkRef} target="_blank">{linkText}</LinkText>.</p>

};

export default QuestionLink;
