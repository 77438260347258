import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, CardHeader, CardInfo, CardText } from '../StyledComponents';

import Button from '../../ui/Buttons/Button';

import routes from '../../../helpers/routes';

const UserCard = ({ user }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <img
        src={
          user?.avatarUrl ||
          'https://smartpointsback-dev.bahia360.mx/uploads/Inactiva_18d88a0159.png'
        }
        alt={user?.username}
      />
      <CardHeader>Nivel {user?.level?.numberLevel}</CardHeader>
      <CardInfo>
        <CardText>Nombre de usuario: {user?.username}</CardText>
        <CardText>Email: {user?.email}</CardText>
        <CardText>Número de licencia: {user?.licenseNumber}</CardText>
      </CardInfo>
      <h2>Ptos. {user?.totalPoints}</h2>
      <Button
        color='outline-white'
        onClick={() =>
          navigate(
            `/dashboard/${routes.dashboardRoutes.smartUserProfileHistory.replace(':id', user?.id)}`,
          )
        }
      >
        Ver historial de movimientos
      </Button>
    </Card>
  );
};

export default UserCard;
