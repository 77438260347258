import Level1 from "../Assets/icons/Insignia-1.svg"
import Level1Inactive from "../Assets/icons/Insignia-1_desactivado.svg"
import Level2 from "../Assets/icons/Insignia-2.svg"
import Level2Inactive from "../Assets/icons/Insignia-2_desactivado.svg"
import Level3 from "../Assets/icons/Insignia-3.svg"
import Level3Inactive from "../Assets/icons/Insignia-3_desactivado.svg"
import Level4 from "../Assets/icons/Insignia-4.svg"
import Level4Inactive from "../Assets/icons/Insignia-4_desactivado.svg"

export const levels = [
    {
        level: 1,
        name : "Smart",
        iconActive: Level1,
        iconInactive : Level1Inactive
    },
    {
        level: 2,
        name : "Smart Pro",
        iconActive: Level2,
        iconInactive : Level2Inactive
    },
    {
        level: 3,
        name : "Smart Plus",
        iconActive: Level3,
        iconInactive : Level3Inactive
    },
    {
        level: 4,
        name : "Smart Elite",
        iconActive: Level4,
        iconInactive : Level4Inactive
    }


]
