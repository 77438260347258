import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import * as Yup from 'yup';

import { WrapperSmartUsers } from './StyledComponents';
import { StyledInlineErrorMessage } from '../Register/styles';

import Button from '../ui/Buttons/Button';
import FormikControl from '../ui/Forms/FormikControl';

import { auth } from '../../redux/actions';

import IcnEdit from '../../Assets/icons/icn_perfil.svg';
import PreviewImage from "../ui/PreviewImage";
import IcnPicture from "../../Assets/icons/icn_foto.svg";

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5.6rem;
  justify-self: center;
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 60%;
  }
`;

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const PhotoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  img.avatar {
    border-radius: 1.6rem;
    height: 12.8rem;
    object-fit: cover;
    object-position: top;
    width: 12.8rem;
  }
`;

const FormWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4.2rem;
  width: 100%;
`;

const IconWrapper = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: var(--azul-claro);
  cursor: pointer;
  display: flex;
  font: 450 1.5rem/1.4rem var(--azul-oscuro);
  gap: 0.8rem;
  justify-content: center;
  text-decoration: underline var(--azul-claro);
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1.6rem;
  justify-content: center;
`;
const LabelWrapper = styled.label`
  color: var(--azul-claro);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .gap-1{
    gap: 1rem;
  }
  .icon-img{
    width: 2.5rem;

  }
  .mt-1{
    margin-top: 1rem;
  }
  .form-center {
    display: flex;
    justify-content: center;
    text-decoration: underline;
  }

`;


const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('Por favor, introduce tu nombre'),
  lastName: Yup.string().required('Por favor, introduce tus apellidos'),
  email: Yup.string()
    .email('Por favor, introduce una dirección de correo electrónico válida')
    .required('Por favor, introduce tu dirección de correo electrónico'),
});

const AdminProfile = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector(state => state.auth);
  const [isEdit, setIsEdit] = useState(false);

  const imgUrl = user.avatarUrl === 'noSet'?
    'https://smartpointsback-dev.bahia360.mx/uploads/Inactiva_18d88a0159.png' :
    user.avatarUrl ;

  const handleSubmit = data => {
    dispatch(auth.updateAction(user.id, data));
  };

  return (
    <WrapperSmartUsers>
      <ContentWrapper>
        <TextWrapper>
          <h2>Perfil</h2>
          <p>Actualiza tus datos si es necesario</p>
        </TextWrapper>

        <FormWrapper>
          <Formik initialValues={user} onSubmit={handleSubmit} validationSchema={UserSchema}>
            {({ errors, touched, values,setFieldValue  }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    { isEdit ?
                      <LabelWrapper htmlFor="profilePic" className="custom-file-upload form-profile-pic">

                        {
                          values.profilePic ? <PreviewImage file={values.profilePic}/> : <img src={imgUrl} alt="profile pic"/>
                        }

                        <span className="form-center gap-1 align-center mt-1">
                    <img src={IcnPicture} className="icon-img" alt="phone icon"/>
                      Cambiar fotografia
                  </span>

                        <input
                          style={{ display: "none" }}
                          id="profilePic"
                          name="profilePic"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={e => setFieldValue("profilePic",e.target.files[0])}
                        />

                      </LabelWrapper>
                      :  <PhotoWrapper>

                        <img src={imgUrl}  alt='' />

                      </PhotoWrapper>
                    }

                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikControl
                      control='input'
                      type='text'
                      label='Nombres(s)'
                      name='firstName'
                      placeholder='Escribe tu nombre'
                      disabled={!isEdit}
                    />
                    {errors.firstName && touched.firstName ? (
                      <StyledInlineErrorMessage>{errors.firstName}</StyledInlineErrorMessage>
                    ) : (
                      <StyledInlineErrorMessage />
                    )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikControl
                      control='input'
                      type='text'
                      label='Apellidos'
                      name='lastName'
                      placeholder='Escribe tus apellidos'
                      disabled={!isEdit}
                    />
                    {errors.lastName && touched.lastName ? (
                      <StyledInlineErrorMessage>{errors.lastName}</StyledInlineErrorMessage>
                    ) : (
                      <StyledInlineErrorMessage />
                    )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikControl
                      control='input'
                      type='email'
                      label='Correo electrónico'
                      name='email'
                      placeholder='Escribe tu correo electrónico'
                      disabled={!isEdit}
                    />
                    {errors.email && touched.email ? (
                      <StyledInlineErrorMessage>{errors.email}</StyledInlineErrorMessage>
                    ) : (
                      <StyledInlineErrorMessage />
                    )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikControl
                      control='input'
                      type='password'
                      label='Contraseña'
                      name='password'
                      placeholder='• • • • • • • •'
                      disabled
                    />
                    {errors.password && touched.password ? (
                      <StyledInlineErrorMessage>{errors.password}</StyledInlineErrorMessage>
                    ) : (
                      <StyledInlineErrorMessage />
                    )}
                  </Grid>
                </Grid>
                {isEdit ? (
                  <ButtonsWrapper>
                    <Button color='outline-secondary' onClick={() => setIsEdit(false)}>
                      Regresar al perfil
                    </Button>
                    <Button color='secondary' type='submit'>
                      Guardar cambios
                    </Button>
                  </ButtonsWrapper>
                ) : (
                  <IconWrapper onClick={() => setIsEdit(true)}>
                    <img src={IcnEdit} alt='' />
                    Editar perfil
                  </IconWrapper>
                )}
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </ContentWrapper>
    </WrapperSmartUsers>
  );
};

export default AdminProfile;
