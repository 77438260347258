import React from 'react';
import { Formik, Form } from 'formik';
import AnimatedButton from '../ui/Buttons/AnimatedButton';
import { StyledInlineErrorMessage } from '../ui/Forms/Form';
import { ContactSchema } from './ContactUsSchema';
import { PageWrapper } from './styles';
import FormikControl from '../ui/Forms/FormikControl';
import styled from 'styled-components';

import Doc1 from '../../Assets/Images/doc1.png';
import Doc2 from '../../Assets/Images/doc2.png';
import Doc3 from '../../Assets/Images/doc3.png';
import ElipseBlue from '../../Assets/Images/elipse_blue.png';
import ElipseGreen from '../../Assets/Images/elipse_green.png';
import {useSelector} from "react-redux";

const FormContactWrapper = styled.div`
  min-width: 36.5rem;
  padding: 7rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .contact-header {
    font: 700 1.6rem/1.7rem var(--font-Base-Bold);
    color: var(--green-primary);
    margin-bottom: 3.2rem;
  }
  h3{
  }
  .contact-describe {
    font: 450 1.6rem/1.9rem var(--font-Base);
  }
  .is-center {
    text-align: center;
  }
  .images {
    display: none;
  }
  .images_left {
    display: none;
  }
  @media (min-width: 992px) {
    min-width: 67rem;
  }
  @media (min-width: 1200px) {
    .images {
      position: absolute;
      display: block;
      display: flex;
      flex-direction: row-reverse;
      right: 0;
      margin-bottom: 400px;
      .zoom-in-out-box {
        animation: zoom-in-zoom-out 7s ease infinite;
        animation-delay: 2s;
      }
      @keyframes zoom-in-zoom-out {
        0% {
          transform: scale(1, 1);
        }
        50% {
          transform: scale(1.1, 1.1);
        }
        100% {
          transform: scale(1, 1);
        }
      }
      .images_rigth {
        display: flex;
        flex-direction: column;
        img {
          width: 300px;
        }
        .elipse {
          position: absolute;
          width: 50px;
          top: 200px;
        }
      }
    }
    .images_left {
      position: absolute;
      display: block;
      display: flex;
      flex-direction: column;
      left: 30px;
      margin-bottom: 600px;
      .zoom-in-out-box {
        animation: zoom-in-zoom-out 7s ease infinite;
      }
      @keyframes zoom-in-zoom-out {
        0% {
          transform: scale(1, 1);
        }
        50% {
          transform: scale(1.2, 1.2);
        }
        100% {
          transform: scale(1, 1);
        }
      }
      .elipse {
        position: absolute;
        bottom: -400px;
        img {
          width: 40px !important;
        }
      }
      .docs {
        width: 250px;
        position: absolute;
      }
      .first {
        margin-top: 200px;
        left: 50px;
        z-index: 2;
      }
    }
  }
`;

const initState = {
  name: '',
  email: '',
  message: '',
};

const FormContactUs = (props) => {

  const { loading } = useSelector((state) => state.auth);

  return (
    <FormContactWrapper>
      <h2 className='contact-header is-center'>
        QUEREMOS BRINDARTE EL MEJOR SERVICIO
      </h2>
      <h3 className='contact-describe is-center'>
        Escríbenos si tienes más dudas sobre el programa y uno de nuestros
        agentes te contestará.
      </h3>
      <div className='images'>
        <div className='images_rigth'>
          <img className='zoom-in-out-box' src={Doc1} alt='image_animation' />
          <img
            className='elipse zoom-in-out-box'
            src={ElipseGreen}
            alt='image_animation'
          />
        </div>
      </div>
      <div className='images_left'>
        <img
          className='docs first zoom-in-out-box'
          src={Doc2}
          alt='image_animation'
        />
        <img
          className='docs zoom-in-out-box'
          src={Doc3}
          alt='image_animation'
        />
        <img
          className='elipse zoom-in-out-box'
          src={ElipseBlue}
          alt='image_animation'
        />
      </div>
      <PageWrapper>
        <Formik
          initialValues={initState}
          validationSchema={ContactSchema}
          onSubmit={(values,{resetForm}) => {
            resetForm();
            props.handleSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className='form-item'>
                <FormikControl
                  gray
                  name='name'
                  control='input'
                  type='text'
                  label='Nombre'
                  placeholder='Escribe tu nombre'
                />

                {errors.name && touched.name ? (
                  <StyledInlineErrorMessage>
                    {errors.name}
                  </StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </div>
              <div className='form-item'>
                <FormikControl
                  gray
                  control='input'
                  label='Correo electrónico'
                  name='email'
                  type='email'
                  placeholder='Escribe tu correo electrónico'
                />

                {errors.email && touched.email ? (
                  <StyledInlineErrorMessage>
                    {errors.email}
                  </StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </div>

              <div className='form-item'>
                <FormikControl
                  gray
                  control='textarea'
                  label='Mensaje'
                  name='message'
                  placeholder='¿Qué nos quieres decir?'
                />

                {errors.message && touched.message ? (
                  <StyledInlineErrorMessage>
                    {errors.message}
                  </StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </div>
              <div className='btn-action'>
                <AnimatedButton
                  color={loading && 'disabled'}
                  type='submit'
                  disabled={loading}
                >
                Enviar</AnimatedButton>
              </div>
            </Form>
          )}
        </Formik>
      </PageWrapper>
    </FormContactWrapper>
  );
};

export default FormContactUs;
