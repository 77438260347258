import {
    START_CONTACTUS,
    CONTACTUS_SUCCESS,
    CONTACTUS_FAIL
} from "../types/contactUsTypes";

import SmartPointsApiBase from "../../helpers/SmartPointsBaseApi";


const actions = {
    contactUsAction: (formData) => {
      console.log(formData)
      console.log(2)
        return async (dispatch) => {

            dispatch({
                type: START_CONTACTUS
            })

            try {

              const response = await SmartPointsApiBase.postElementWithoutToken(`/messages`, formData);
              console.log(3)
              console.log(response)
              dispatch({
                    type: CONTACTUS_SUCCESS,
                    payload: response
                })


            } catch (e) {
                dispatch({
                    type: CONTACTUS_FAIL
                })
            }
        }
    }
}
export default actions;
