import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';

const Title = styled.h1`
  color: var(--azul-claro);
  font: 500 1.8rem var(--font-Base-Bold);
  text-align: center;
`;

const Text = styled.div`
  color: var(--azul-oscuro);
  font: ${({ bold }) =>
    bold ? '700 1.4rem var(--font-Base-Bold)' : '450 1.4rem var(--font-Base)'};
  a {
    color: var(--azul-claro);
  }
`;

const Conditions = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs='12'>
        <Title>Condiciones de uso</Title>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Por medio de “[su firma/su confirmación/su click en la casilla de
          aceptación correspondiente]”, de manera informada, voluntaria,
          específica y expresa autorizo a Bayer S.A. de C.V., con domicilio en
          México (en adelante "nosotros", o "Bayer"), a recopilar mis datos
          personales como nombre, documento de identidad, dirección, teléfono
          y/o correo electrónico. Autorizo a Bayer para contactarme a través
          llamadas telefónicas, correos electrónicos u otras comunicaciones
          electronicas, para informarme sobre nuestros servicios, productos o
          eventos relacionados con sus intereses médicos (“Comunicaciones
          Electrónicas de Marketing/Médicas”), gestionar los detalles de su
          participación en eventos de educación continua, o congresos, ya sea
          como participante o ponente, y solicitarle información sobre el
          impacto que han tenido nuestros productos en sus pacientes con fines
          estadísticos y científicos. Entiendo que puedo ejercer mis derechos
          ARCO (acceso, rectificación, cancelación y oposición) sobre mis datos
          personales o realizar consultas sobre el tema; y soy consciente que
          para ello debo ponerme en contacto con Bayer a través del siguiente
          correo electrónico:{' '}
          <a href='mailto:data.protectionmx@bayer.com'>
            data.protectionmx@bayer.com
          </a>
          .
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Si usted desea retirar su consentimiento, puede hacerlo a través de la
          misma página web en la que usted haya creado su perfil, en las
          opciones que encontrará en la sección destinada a configuración del
          perfil. También puede retirar su consentimiento contactando
          directamente con nosotros al correo electrónico{' '}
          <a href='mailto:data.protectionmx@bayer.com'>
            data.protectionmx@bayer.com
          </a>
          . Además, cada comunicación electrónica que le remitamos le dará la
          opción de revocar fácilmente su consentimiento, mediante un enlace que
          le permitirá darse de baja si usted clica sobre el mismo.
        </Text>
      </Grid>
    </Grid>
  );
};

export default Conditions;
