import { useState } from 'react';
import HeroSecondary from '../features/ui/Hero/HeroSecondary';
import backRegister from '../Assets/Images/back_register_mobile.png';
import backRegisterDesktop from '../Assets/Images/back_register_desktop.png';

import styled from 'styled-components';
import FormRestorePassword from '../features/RestorePassword/FormRestorePassword';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../redux/actions';
import { Alert, Snackbar } from '@mui/material';
import RestoreSuccess from '../features/RestorePassword/RestoreSuccess';
import routes from '../helpers/routes';

const StyleLayoutRestorePass = styled.div`
  .restore__wrapper {
    display: flex;
    min-height: 60rem;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .restore_title {
    font: 400 2.4rem/2.6rem var(--font-Base-Bold);
    color: var(--azul-claro);
    text-align: center;
  }

  .restore_description {
    font: 400 1.6rem/2rem var(--font-Base);
    margin-top: 1.1rem;
    text-align: center;
    color: var(--azul-oscuro);
  }

  .restore__form {
    max-width: 40rem;
  }

  .mb-2 {
    margin-bottom: 2.4rem;
  }

  .mt-2 {
    margin-top: 2.4rem;
  }

  .is-blue {
    color: red !important;
  }

  .is-center {
    text-align: center;
  }

  .link a {
    cursor: pointer;
    text-decoration: none;
  }

  .sub-link a {
    cursor: pointer;
    color: var(--azul-claro);
    text-decoration: none;
  }

  @media (min-width: 995px) {
    .restore__wrapper {
      min-width: 38rem;
      align-items: flex-end;
      padding-right: 6%;
    }
  }
`;
const initState = {
  password: '',
  passwordConfirmation: '',
};
const RestoresPass = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(initState);
  const [currentStep, setCurrentStep] = useState(0);

  const { error } = useSelector(state => state.auth);

  const handleSubmit = async data => {
    let restoreObjet = {
      ...data,
      code,
    };
    setData(initState);
    try {
      await dispatch(auth.resetPassword(restoreObjet));
      setCurrentStep(1);
      setTimeout(() => {
        navigate(routes.logIn);
      }, 3000);
    } catch (e) {}
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(auth.handleClose());
  };

  const steps = [
    {
      title: 'Cambia tu contraseña',
      subtitle:
        'Crea una contraseña nueva de ocho caracteres como mínimo. Una contraseña segura tiene una combinación de letras, números y signos de puntuación.\n',
      element: <FormRestorePassword handleSubmit={handleSubmit} data={data} />,
    },
    {
      element: <RestoreSuccess />,
    },
  ];

  return (
    <StyleLayoutRestorePass>
      <HeroSecondary heroBackground={backRegister} heroBackgroundDesktop={backRegisterDesktop}>
        <div className='restore__wrapper'>
          <div className='restore__form'>
            <h1 className='restore_title is-center'>{steps[currentStep].title}</h1>
            <h2 className='restore_description is-center mb-2'>{steps[currentStep].subtitle}</h2>
            {steps[currentStep].element}
          </div>
          {error ? (
            <Snackbar
              open={error}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
                Ocurrio un error
              </Alert>
            </Snackbar>
          ) : (
            ''
          )}
        </div>
      </HeroSecondary>
    </StyleLayoutRestorePass>
  );
};

export default RestoresPass;
