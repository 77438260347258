import React from 'react';

const icn_acerca_de = () => {
  return (
    <svg
      width='19'
      height='17'
      viewBox='0 0 19 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M17.1713 0.499993H1.11972C0.823511 0.499803 0.539358 0.622447 0.329747 0.840956C0.120135 1.05946 0.00222649 1.35595 0.00195312 1.6652V11.4319C0.00213524 11.7409 0.119814 12.0373 0.329138 12.2558C0.538463 12.4744 0.822314 12.5972 1.11834 12.5974H6.6233L8.41338 15.7525C8.74059 16.3353 9.54764 16.3353 9.89548 15.7525L11.6856 12.5974H17.1905C17.4865 12.5972 17.7704 12.4744 17.9797 12.2558C18.189 12.0373 18.3067 11.7409 18.3069 11.4319V1.6652C18.2877 1.02213 17.7872 0.499634 17.1726 0.499634L17.1713 0.499993ZM13.9747 8.96041H4.31353C4.1757 8.96041 4.04351 8.90325 3.94605 8.80149C3.84858 8.69973 3.79383 8.56172 3.79383 8.41782C3.79383 8.27391 3.84858 8.1359 3.94605 8.03415C4.04351 7.93239 4.1757 7.87523 4.31353 7.87523H13.9568C14.0252 7.87494 14.0929 7.88878 14.156 7.91595C14.2192 7.94312 14.2766 7.98307 14.3249 8.03352C14.3732 8.08396 14.4115 8.14388 14.4375 8.20984C14.4636 8.2758 14.4768 8.34649 14.4765 8.41782C14.4806 8.48849 14.4707 8.55928 14.4472 8.62571C14.4238 8.69214 14.3874 8.75278 14.3404 8.80377C14.2933 8.85477 14.2367 8.89502 14.174 8.92198C14.1113 8.94895 14.0439 8.96203 13.9761 8.96041H13.9747ZM13.9747 5.20246H4.31353C4.1757 5.20246 4.04351 5.14529 3.94605 5.04354C3.84858 4.94178 3.79383 4.80377 3.79383 4.65987C3.79383 4.51596 3.84858 4.37795 3.94605 4.2762C4.04351 4.17444 4.1757 4.11727 4.31353 4.11727H13.9568C14.0252 4.11699 14.0929 4.13083 14.156 4.158C14.2192 4.18516 14.2766 4.22512 14.3249 4.27556C14.3732 4.326 14.4115 4.38593 14.4375 4.45189C14.4636 4.51785 14.4768 4.58853 14.4765 4.65987C14.4807 4.73066 14.4706 4.80157 14.4471 4.8681C14.4236 4.93464 14.3871 4.99534 14.3399 5.04636C14.2927 5.09738 14.2359 5.1376 14.173 5.16447C14.1101 5.19134 14.0426 5.20428 13.9747 5.20246Z' />
    </svg>
  );
};

export default icn_acerca_de;
