import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 0 10px;
  max-width: 40rem;
  font-family: var(--font-Base-Bold);
  color: var(--dark-blue);

  p {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 110%;
    color: var(--white);
    color: ${(props) => (props.secundario ? 'var(--green-primary)' : '')};
  }
  h3 {
    font-family: var(--font-Base);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    font-size: ${(props) => (props.secundario ? '36px' : '')};
    line-height: 110%;
    max-width: 100%;
    left: 0;
    right: 0;
    margin: 20px auto 0;
    color: ${(props) => (props.secundario ? '#ffff' : '')};
  }
  h4 {
    margin: 26px auto 0;
    margin-top: 26px;
    //max-width: 90%;
    color: var(--white);
    font: 400 1.6rem/2.5rem var(--font-Base);
  }
  @media (min-width: 900px) {
    max-width: 80rem;
  }
  @media (min-width: 992px) {
    h3 {
      padding: 0 22%;
      max-width: 80%;
      max-width: ${(props) => (props.secundario ? '100%' : '')};
    }
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 32px;
      max-width: 100%;
      font-family: var(--font-Base);
      font-weight: 500;
      font-size: ${(props) => (props.secundario ? '60px' : '')};
    }
  }
`;
export default function HeaderGreen(props) {
  const { greenText, blackText, thirdText } = props;
  return (
    <StyledHeader {...props}>
      <p>{greenText}</p>
      <h3>{blackText}</h3>
      <h4>{thirdText}</h4>
    </StyledHeader>
  );
}
