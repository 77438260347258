import {
    START_WHAT_IS_NEW,
    WHAT_IS_NEW_SUCCESS,
    WHAT_IS_NEW_FAILED
} from "../types/homeTypes";


const initState = {
    loading: false,
    error : null,
    whatIsNew : []
}

const homeReducer = (state = initState , action ) =>{
    switch (action.type){
        case START_WHAT_IS_NEW:
            return{
                ...state,
                loading: true
            }
        case WHAT_IS_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                whatIsNew: action.payload
            }
        case WHAT_IS_NEW_FAILED:
        default: {
            return state
        }
    }
}
export default homeReducer;
