import {
    START_CONTACTUS,
    CONTACTUS_SUCCESS,
    CONTACTUS_FAIL
} from "../types/contactUsTypes";

const initState ={
    loading : false,
    error: null
};
const contactUsReducer = ( state = initState, action ) => {
    switch ( action.type ){
        case START_CONTACTUS:
            return {
                ...state,
                loading: true
            }
        case CONTACTUS_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case CONTACTUS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export  default contactUsReducer;