import styled from 'styled-components';

export const CardLayout = styled.div`
  border-radius: 1rem;
  border: none;
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font: 450 1.5rem/1.4rem var(--font-Base);
`;
