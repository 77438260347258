import React from 'react';
import {CustomSelect} from "./Form";

const Select = (props) => {
    const {label, name, options, ...rest} = props

    return (
        <>
            <CustomSelect id={name} name={name} {...rest}>
                {options.map((option, index) => {
                    if (index === 0) {
                        return (
                            <option key={index} value={""} selected>
                                {label}
                            </option>
                        )
                    }
                    return (
                        <option key={option.value} value={option.value}>
                            {option.key}
                        </option>
                    )
                })}
            </CustomSelect>

        </>
    );
};

export default Select;