import styled from 'styled-components';

const handleColor = color => {
  switch (color) {
    case 'secondary':
      return 'background-color: var(--dark-blue); border: none; color: var(--white);';
    case 'tertiary':
      return 'background-color: var(--azul-claro); border: none; color: var(--white);';
    case 'white':
      return 'background-color: var(--white); border: none; color: var(--dark-blue);';
    case 'outline-secondary':
      return 'background: transparent; border: 0.2rem solid var(--dark-blue); color: var(--dark-blue);';
    case 'outline-tertiary':
      return 'background: transparent; border: 0.2rem solid var(--azul-claro); color: var(--azul-claro);';
    case 'outline-white':
      return 'background: transparent; border: 0.2rem solid var(--white); color: var(--white);';
    case 'ghost':
      return 'background-color: transparent; border: none; color: var(--dark-blue); padding: 1.2rem 0.4rem !important;';
    default:
      return 'background-color: var(--green-primary); border: none; color: var(--white);';
  }
};
const handleHover = color => {
  switch (color) {
    case 'secondary':
      return 'background-color: var(--azul-claro);';
    case 'tertiary':
      return 'background-color: var(--dark-blue);';
    case 'white':
      return 'background-color: var(--dark-blue); color: var(--white);';
    case 'outline-secondary':
      return 'background-color: var(--dark-blue); color: var(--white);';
    case 'outline-tertiary':
      return 'background-color: var(--azul-claro); color: var(--white);';
    case 'outline-white':
      return 'background-color: var(--white); color: var(--dark-blue);';
    case 'ghost':
      return 'text-decoration: underline;';
    default:
      return 'background-color: var(--dark-blue);';
  }
};

const Button = styled.button`
  ${({ color }) => handleColor(color)};
  align-items: center;
  cursor: pointer;
  border-radius: 10rem;
  display: flex;
  font: normal 1.4rem/1rem 'CircularStdFont';
  gap: 0.8rem;
  height: 4.4rem;
  justify-content: center;
  padding: 1.2rem 2.4rem;
  position: relative;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  width: fit-content;
  a {
    color: inherit;
    text-decoration: none;
  }
  input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
  &:hover {
    ${({ color }) => handleHover(color)};
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }
  &:disabled {
    background-color: var(--light-gray);
    cursor: not-allowed;
  }
`;

export default Button;
