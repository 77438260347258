import React from "react";
import styled from "styled-components";

import { Header1, Header5 } from "../../Utilities/FontStles";
import AnimatedButton from "../Buttons/AnimatedButton";
import Navbar from "./Navbar";
import routes from "../../../helpers/routes";
import { NavLink } from "react-router-dom";

const StyledHeader = styled.div`
  font: 450 1.4rem/1.5rem "CircularStdFont";
  background-image: url(${({ heroBackground }) => heroBackground});
  background-size: cover;
  background-position: bottom;
  .header__hero {
    display: flex;
    padding-inline-start: 2.5rem;
    padding-inline-end: 2.5rem;
    padding-block-start: 4.8rem;
    padding-block-end: 14rem;
  }
  .header__content {
    order: 1;
    max-inline-size: 52rem;
  }

  .mb-22 {
    margin-bottom: 2.2rem;
  }
  .mb-23{
    color: var(--azul-oscuro);
  }
  .hero__actions {
    display: flex;
    gap: 1rem;
    a {
      text-decoration: none;
    }
  }

  @media (min-width: 995px) {
    background-image: url(${({ heroBackgroundDesktop }) => heroBackgroundDesktop});
    .header__hero {
      display: flex;
      padding-inline-start: 15rem;
      padding-block-start: 12rem;
    }
    .header__content {
      order: 1;
      max-inline-size: 52rem;
    }
  }
  @media (min-width: 1500px) {
    .header__hero {
      padding-block-start: 26rem;
  }
  }
  @media (min-width: 1800px) {
    .header__hero {
      padding-block-start: 30rem;
  }
  }
  @media (min-width: 2000px) {
    .header__hero {
      padding-block-start: 35rem;
  }
  }
`;

const Header = ({ heroBackground, heroBackgroundDesktop, heroTitle }) => {
  return (
    <StyledHeader
      heroBackground={heroBackground}
      heroBackgroundDesktop={heroBackgroundDesktop}
    >
      <Navbar />
      <div className="header__hero">
        <div className="header__content">
          <Header1 className="mb-22">{heroTitle}</Header1>
          <Header5 className="mb-22 mb-23">
            Creemos en la importancia de crecer juntos para lograr nuevas metas; <br/>
            por eso, con Smart Points te brindamos beneficios exclusivos.
          </Header5>
          <div className="hero__actions">
            <NavLink to={routes.register}>
              <AnimatedButton>Registrarme</AnimatedButton>
            </NavLink>
            <NavLink to={routes.logIn}>
              <AnimatedButton color="dark-blue">Iniciar sesión</AnimatedButton>
            </NavLink>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
