import * as Yup from 'yup';


export const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Por favor, introduce tu nombre'),
    lastName: Yup.string()
        .required('Por favor, introduce tus apellidos'),
   /* TODO
     - add lastname length*/
    email: Yup.string()
        .email('Por favor, introduce una dirección de correo electrónico válida')
        .required('Por favor, introduce tu dirección de correo electrónico'),
    password: Yup.string()
        .required("Por favor, introduce tu contraseña")
        .min(6, "La contraseña debe tener al menos 6 caracteres"),
    confirmPassword: Yup.string()
        .required("Por favor, introduce tu contraseña")
        .min(6, "La contraseña debe tener al menos 6 caracteres")
        .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),

});
