import { useState } from 'react';
import styled from 'styled-components';

import HeroSecondary from '../features/ui/Hero/HeroSecondary';
import backForgot from '../Assets/Images/back_register_mobile.png';
import backForgotDesktop from '../Assets/Images/back_register_desktop.png';
import FormForgotPassword from '../features/ForgotPassword/FormForgotPassword';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../redux/actions';
import { Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SendMail from '../features/ForgotPassword/SendMail';
import routes from '../helpers/routes';

const StyledForgot = styled.div`
  .container_content {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 20px;

    form {
      .buttons {
        display: flex;
        gap: 8px;
        margin-top: 44px;
        padding-bottom: 64px;
        a,
        button {
          width: 100%;
        }
      }
    }
  }

  .top_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 26px;
    font-family: var(--font-Base);
    font-style: normal;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 110%;
      color: var(--azul-oscuro);
    }

    h2 {
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 120%;
      color: var(--gray-primary);
      margin-top: 0.68rem;
      text-align: center;
      margin-bottom: 35px;
    }
  }

  .buttons {
    a {
      text-decoration: none;
    }
  }

  @media (min-width: 995px) {
    .container {
      display: flex;
      justify-content: flex-end;
      margin-right: 16rem;
    }

    .container_content {
      margin: 0;
      display: flex;
      flex-direction: column;

      form {
        .buttons {
          padding-bottom: 222px;
        }
      }
    }

    .top_text {
      margin-top: 100px;
    }
  }
`;

const initState = {
  email: '',
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error } = useSelector(state => state.auth);

  const [data, setData] = useState(initState);
  const [currentStep, setCurrentStep] = useState(0);

  const handleSubmit = async values => {
    try {
      await dispatch(auth.forgotPassword(values));
      setCurrentStep(1);
      setTimeout(() => {
        navigate(routes.logIn);
      }, 3000);
    } catch (e) {}
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(auth.handleClose());
  };

  const handleNextStep = newData => {
    setData(prevState => ({ ...prevState, ...newData }));
    setCurrentStep(prevState => prevState + 1);
  };
  const steps = [
    {
      element: (
        <FormForgotPassword nextStep={handleNextStep} handleSubmit={handleSubmit} data={data} />
      ),
    },
    {
      element: <SendMail />,
    },
  ];

  return (
    <StyledForgot>
      <HeroSecondary heroBackground={backForgot} heroBackgroundDesktop={backForgotDesktop}>
        <div className='container'>
          <div className='container_content'>
            {steps[currentStep].element}
            {error ? (
              <Snackbar
                open={error}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
                  Ocurrio un error
                </Alert>
              </Snackbar>
            ) : (
              ''
            )}
          </div>
        </div>
      </HeroSecondary>
    </StyledForgot>
  );
};
export default ForgotPassword;
