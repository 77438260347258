//register
export const START_REGISTER = "START_REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
//login
export const START_LOGIN = "START_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
//logout
export const START_LOGOUT = "START_LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
//update
export const START_UPDATE = "START_UPDATE";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAIL = "UPDATE_FAIL";
// forgot password
export const START_RECOVERY_PASSWORD = "START_RECOVERY_PASSWORD";
export const RECOVERY_PASSWORD_SUCCESS = "RECOVERY_PASSWORD_SUCCESS";
export const RECOVERY_PASSWORD_FAIL = "RECOVERY_PASSWORD_FAIL";
//restore password
export const START_RESTORE_PASSWORD = "START_RESTORE_PASSWORD";
export const RESTORE_PASSWORD_SUCCESS = "RESTORE_PASSWORD_SUCCESS";
export const RESTORE_PASSWORD_FAIL = "RESTORE_PASSWORD_FAIL";
//
export const UPDATE_ERROR = "UPDATE_ERROR"


export const START_VALIDATE = "START_VALIDATE";
export const VALIDATE_SUCCESS = "VALIDATE_SUCCESS";
export const VALIDATE_FAILED = "VALIDATE_FAILED";

export const DISPATCH_ERROR = "DISPATCH_ERROR";
