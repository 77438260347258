import styled from "styled-components";
import FrequentQuestionsInit from "../FrequentQuestions/FrequentQuestionsInit";
import { Wrapper } from "../ui/Layout/Wrapper";

const FrequentQuestionyWrapper = styled(Wrapper)``;

const Questions = () => {
  return (
    
    <FrequentQuestionyWrapper>
      <FrequentQuestionsInit />
    </FrequentQuestionyWrapper>
  );
};

export default Questions;
