import React from 'react';

const icn_reports = () => {
  return (
    <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.672102 5.24849H17.3279C17.5186 5.24849 17.6945 5.32593 17.8233 5.46638C17.9521 5.60682 18.014 5.7889 17.9974 5.9788L17.1821 15.322C17.1516 15.6717 16.8639 15.9355 16.5128 15.9355H1.70676C1.36373 15.9355 1.0767 15.6784 1.03889 15.3376L0.00423926 5.99435C-0.0170552 5.8019 0.0423618 5.61655 0.171484 5.47228C0.300686 5.32792 0.478459 5.24849 0.672102 5.24849ZM17.1084 4.05217V2.57392C17.1084 2.2435 16.9071 1.97856 16.5766 1.97856H9.93047V1.79185C9.93047 0.817334 9.2784 0.0644531 8.3952 0.0644531H2.42694C1.54374 0.0644531 0.758716 0.817334 0.758716 1.79185V3.7818C0.758716 3.87998 0.812948 3.97154 0.859764 4.05217H17.1084Z'
        fill='#8892AD'
      />
    </svg>
  );
};

export default icn_reports;
