import React from 'react';
import {DateContainer, DateInput} from "./Form";


const DatePicker = (props) => {
    const {label, name, ...rest} = props
    return (
        <DateContainer {...rest}>
            <label htmlFor={name}>{label}</label>
            <DateInput type="date"
                       id={name}
                       name={name}
                       {...rest}
            />
        </DateContainer>
    );
};

export default DatePicker;