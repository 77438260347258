import React from 'react';
import styled from "styled-components";
import SuccessIcon from "../../Assets/icons/icn_contacto.svg"


const FormContactWrapper = styled.div`
  min-width: 36.5rem;
  padding: 7rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  .contact-header{
    font: 700 1.6rem/1.7rem var(--font-Base-Bold);
    color: var(--green-primary);
    margin-bottom: 3.2rem;
  }
  .contact-describe{
    font: 450 1.6rem/1.9rem var(--font-Base);
  }
  .is-center{
    text-align: center;
  }
  h3{
    font: 500 2.4rem/2.6rem var(--font-Base-Bold);
    color: var(--dark-blue);
  }
  p{
    font: 450 1.6rem/2rem var(--font-Base);

  }
  img{
    width: 50%;
  }
  @media(min-width: 992px) {
    min-width: 67rem;
    min-height: 55rem;
  }
`;

const ContactUsSuccess = () => {

    return (
        <FormContactWrapper>
            <h2 className="contact-header is-center">
                QUEREMOS BRINDARTE EL MEJOR SERVICIO
            </h2>
            <img
                src={SuccessIcon}
                alt="icon_succes"
                className="is-center"
            />

            <h3 className="is-center">¡Muchas gracias por enviar el formulario!</h3>
            <p className="is-center">Uno de nuestros agentes te contactará para responder tus dudas. ¡Te vemos pronto!</p>
        </FormContactWrapper>
    );
};

export default ContactUsSuccess;