import * as Yup from "yup";

export const RestorePasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required("Por favor, introduce tu contraseña")
        .min(6, "La contraseña debe tener al menos 6 caracteres"),
    passwordConfirmation: Yup.string()
        .required("Por favor, introduce tu contraseña")
        .min(6, "La contraseña debe tener al menos 6 caracteres")
        .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),

});
