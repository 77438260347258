import React from 'react';
import styled from 'styled-components';

import Button from '../ui/Buttons/Button';

import { WrapperSmartUsers } from './StyledComponents';

import Download from '../../Assets/icons/icn_download.svg';
import Refres from '../../Assets/icons/icn_refresh.svg';
import File from '../../Assets/icons/icn_file.svg';
import layout from '../../Assets/files/ordenes_layout.xlsx';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5.6rem;
  justify-self: center;
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 80%;
  }
`;

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 2.8rem;
  @media (max-width: 1199px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  align-items: center;
  background: var(--white);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 25.5rem;
  @media (min-width: 1199px) {
    margin-top: ${({ mt }) => mt};
  }
`;

const CardHeader = styled.div`
  align-items: center;
  background: var(--azul-oscuro);
  display: flex;
  height: 9rem;
  justify-content: center;
  width: 100%;
`;

const CardContent = styled.div`
  align-items: center;
  display: flex;
  height: 11.8rem;
  padding: 3.2rem;
  text-align: center;
`;

const data = [
  {
    img: Download,
    mt: '9rem',
    text: 'Descarga el layout para poder subir tus archivos en formato excel',
  },
  {
    img: Refres,
    mt: '0',
    text: 'Lleva el registro de tus médicos y sus puntos',
  },
  {
    img: File,
    mt: '9rem',
    text: 'Actualiza cada mes los datos de tus médicos y espera validación',
  },
];

const UploadInstructions = () => {
  return (
    <WrapperSmartUsers>
      <ContentWrapper>
        <TextWrapper>
          <h2>Instrucciones de carga</h2>
          <p>Podrás encontrar aquí una serie de pasos para subir tus archivos</p>
        </TextWrapper>
        <CardsWrapper>
          {data.map(({ img, mt, text }) => (
            <Card mt={mt}>
              <CardHeader>
                <img src={img} alt='icon Card' />
              </CardHeader>
              <CardContent>{text}</CardContent>
            </Card>
          ))}
        </CardsWrapper>
        <Button
          as='a'
          color='secondary'
          download='ordenes_layout'
          href={layout}
          style={{ alignSelf: 'flex-start', textDecoration: 'none' }}
        >
          Descargar layout
        </Button>
      </ContentWrapper>
    </WrapperSmartUsers>
  );
};

export default UploadInstructions;
