import styled from "styled-components";
import {Wrapper} from "../../ui/Layout/Wrapper";
import {useSelector} from "react-redux";
import React from "react";
import {levels} from "../../../redux/actions";
import {CircularProgress} from "@mui/material";
import CardLevel from "./CardLevel";
import {useQuery} from "@tanstack/react-query";
import {getLevels} from "../../../services/levels";
import qs from "qs";

const WrapperLevels = styled(Wrapper)`
  text-align: center;

  .levels-container {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    gap: 3rem;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    @media (min-width: 992px) {
      width: 80%;
    }
  }
`;

const Levels = () => {

  const {level} = useSelector((state) => state.auth);

  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {isLoading, data: levels} = useQuery({
    queryKey: ['levels'],
    queryFn: () => getLevels({queryStr: query}),
  });


  const actualLevel = level.numberLevel;

  return (
    <WrapperLevels>
      <h2>Niveles</h2>
      <p>
        Cumple misiones adicionales para obtener más puntos y poder subir <br/> de
        nivel para disfrutar de tus beneficios con Smart Points.
      </p>
      <div className="levels-container">
        {
          isLoading ?
            <CircularProgress/> :
            levels.map(level => (
              <CardLevel
                key={`level-${level.id}`}
                data={level}
                actualLevel={actualLevel}
              />

            ))
        }

      </div>
    </WrapperLevels>
  );
};

export default Levels;
