import {
  START_DISTRIBUTOR_BY_ID,
  DISTRIBUTOR_BY_ID_SUCCESS,
  GET_DISTRIBUTORS_SUCCESS,
  START_GET_DISTRIBUTORS,
  START_UPDATE_DISTRIBUTOR,
  UPDATE_DISTRIBUTOR_SUCCESS,
  DISTRIBUTOR_BY_ID_FAIL,
  UPDATE_ERROR,
  UPDATE_DISTRIBUTOR_FAIL,
  UPDATE_TMP_DISTRIBUTOR,
  SET_TMP_DISTRIBUTOR,
} from '../types/distributorTypes';

const initState = {
  distributor:
    sessionStorage.getItem('distributor') !== 'undefined'
      ? JSON.parse(sessionStorage.getItem('distributor'))
      : null,
  distributors: [],
  loading: false,
  error: false,
  tmpDistributor: null,
};

const distributorReducer = (state = initState, action) => {
  switch (action.type) {
    case START_GET_DISTRIBUTORS:
    case START_DISTRIBUTOR_BY_ID:
    case START_UPDATE_DISTRIBUTOR:
      return {
        ...state,
        loading: true,
      };
    case DISTRIBUTOR_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        distributor: {
          ...state.distributor,
          ...action.payload,
        },
      };
    case GET_DISTRIBUTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        distributors: action.payload,
      };
    case UPDATE_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        /* distributor: {
                    distributor:{
                        id: action.payload,
                        ...state.distributor
                    }
                }*/
      };
    case DISTRIBUTOR_BY_ID_FAIL:
    case UPDATE_DISTRIBUTOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        error: false,
        success: false,
      };
    case UPDATE_TMP_DISTRIBUTOR:
      return {
        ...state,
        tmpDistributor: action.payload,
      };
    case SET_TMP_DISTRIBUTOR:
      return {
        ...state,
        tmpDistributor: action.payload,
      };
    default:
      return state;
  }
};
export default distributorReducer;
