import styled from 'styled-components';

import { Wrapper } from '../../ui/Layout/Wrapper';

import WithOutDelivery from './WithOuthDelivery';
import HasDelivery from './HasDelivery';
import {  useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const WrapperContactDelivery = styled(Wrapper)`
  justify-content: flex-start;

  .btn_help {
    margin: 0 auto;
  }

  .contact-delivery__info {
    margin-bottom: 4.6rem;
    text-align: center;
  }

  .contact-delivery__table {
    text-align: center;
  }

  .contact-delivery__table span {
    color: var(--azul-claro);
    text-decoration-line: underline;
    cursor: pointer;
  }

  .contact-delivery__line {
    width: 50%;
    height: 0.2rem;
    z-index: 3;
    background: var(--white);
    border-radius: 2rem;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .contact-delivery__help p {
    margin-bottom: 2rem;
  }

  .mt-5 {
    margin-top: 0.5rem;
  }

  .mb-5 {
    margin-bottom: 0.5rem;
  }

  .MuiPaper-root {
    margin-bottom: 5rem;
  }

  @media (min-width: 995px) {
    .contact-delivery__info {
      margin-bottom: 11.7rem;
    }

    .MuiPaper-root {
      margin-bottom: 2.5rem;
    }
  }
`;

const ContactDelivery = () => {
  const { user,loading } = useSelector(state => state.auth);

  if (loading) {
    return <CircularProgress />
  }
  return (
    <WrapperContactDelivery>

          {user.distributor && Object.keys(user.distributor).length ?
            <HasDelivery distributor = {user.distributor} />
           : <WithOutDelivery />
          }

    </WrapperContactDelivery>
  );
};

export default ContactDelivery;
