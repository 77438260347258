
export const START_DISTRIBUTOR_BY_ID = "START_DISTRIBUTOR_BY_ID";
export const DISTRIBUTOR_BY_ID_SUCCESS = "DISTRIBUTOR_BY_ID_SUCCESS";
export const DISTRIBUTOR_BY_ID_FAIL = "DISTRIBUTOR_BY_ID_FAIL";

export const START_GET_DISTRIBUTORS ="START_GET_DISTRIBUTORS";
export const GET_DISTRIBUTORS_SUCCESS ="GET_DISTRIBUTORS_SUCCESS";
export const GET_DISTRIBUTORS_FAIL ="GET_DISTRIBUTORS_FAIL";

export const START_UPDATE_DISTRIBUTOR = "START_UPDATE_DISTRIBUTOR";
export const UPDATE_DISTRIBUTOR_SUCCESS = "UPDATE_DISTRIBUTOR_SUCCESS";
export const UPDATE_DISTRIBUTOR_FAIL = "UPDATE_DISTRIBUTOR_FAIL";

export const UPDATE_ERROR = "UPDATE_ERROR";

export const UPDATE_TMP_DISTRIBUTOR = "UPDATE_TMP_DISTRIBUTOR";

export const SET_TMP_DISTRIBUTOR = "SET_TMP_DISTRIBUTOR";
