import React from 'react';
import CardLevel from "../../ui/Cards/CardLevel";
import CardSettings from "../../ui/Cards/CardSettings";
import routes from "../../../helpers/routes";
import DudasBack from "../../../Assets/Images/dudas_back.png";
import MisionesBack from "../../../Assets/Images/dudas_back_2.png";
import IconMissiones from "../../../Assets/icons/icn_misiones.svg";
import IconQuestions from "../../../Assets/icons/icn_preguntas.svg";

import {useSelector} from "react-redux";
import {CircularProgress} from "@mui/material";
import Button from '../../ui/Buttons/Button';
import {Link} from 'react-router-dom';
import {useQuery} from "@tanstack/react-query";
import qs from "qs";
import {getLevels} from "../../../services/levels";

const MySmart = () => {

  const {loading, level} = useSelector(state => state.auth);



  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {isLoading,data: levels} = useQuery({
    queryKey: ['levels'],
    queryFn: () => getLevels({queryStr: query}),
  });

  const handleMissingLevels = actualLevel => {
    return levels.filter(level => level.attributes.numberLevel > actualLevel)
  }

  if (isLoading) return <CircularProgress/>
  return (
    <div className='my_level'>
      <div className='level'>
        {loading ? <CircularProgress/> : <CardLevel/>}
      </div>

      <div className='settings'>
        <CardSettings
          describeText='Cumple tus misiones para obtener más puntos'
          link={`/dashboard/${routes.dashboardRoutes.missions}`}
          background={MisionesBack}
          textLink={'Ir a misiones'}
          icon={IconMissiones}
        />
        <CardSettings
          describeText='¿Tienes más dudas de nuestro programa?'
          link={`/dashboard/${routes.dashboardRoutes.frequentQuestions}`}
          background={DudasBack}
          textLink={'Ir a preguntas frecuentes'}
          icon={IconQuestions}
        />
      </div>
      <div className='nextLevels'>
        <h3>Los siguientes niveles te esperan</h3>
        <div className='images'>
          {
            isLoading ? <CircularProgress/> :
            handleMissingLevels(level.numberLevel).map((level, index) => {
            let url = `${level.attributes.iconInactive.data.attributes.url}`;
            return <img key={`image-${index}`} src={url} alt='level icon'/>;
          })}
        </div>

        <Button color='secondary'>
          <Link to={routes.dashboardRoutes.levels}>Ver los niveles </Link>
        </Button>
      </div>
    </div>
  );
};

export default MySmart;
