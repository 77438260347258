import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";

import Button from "../../ui/Buttons/Button";


const Filtro = ({location,selectedLocation,setLocation,handleClean}) => {
  return (
    <Grid item  xs={12} lg={12}  xl={12}>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={10} lg={4} xl={4}  >
          <FormControl fullWidth>
            <InputLabel id="select-location">Selecciona un estado</InputLabel>
            <Select
              sx={{ borderRadius: '100px'}}
              labelId="select-location"
              id="select-location"
              value={selectedLocation}
              label="selectedLocation"
              onChange={e => setLocation(e.target.value)}
            >
              {location?.map(( item) => {
                return (
                  <MenuItem  key={item.id} value={item.id}>{item?.attributes?.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>

        </Grid>
        <Grid item xs={2} lg={2} xl={4}>
          <Button color='tertiary' onClick={handleClean}>
            Limpiar
          </Button>
        </Grid>
      </Grid>


    </Grid>

  );
};

export default Filtro;
