import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Tab, Tabs } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../redux/actions';
import { levels } from '../../redux/actions';

import PointsHistory from './PointsHistory/PointsHistory';
import MySmart from './MySmart/MySmart';

const AccountWrapper = styled.div`
  grid-area: main;
  background-color: var(--background-secondary);

  .level {
    grid-area: level;
  }

  .settings {
    grid-area: settings;
    margin-bottom: 5rem;
  }

  .nextLevels {
    grid-area: nextLevels;
    text-align: center;
    width: 33.6rem;
    margin-left: 3.8rem;
    h3 {
      font: 450 1.6rem/1.9rem var(--font-Base);
      color: var(--dark-blue);
    }
    .images {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 30.8rem;
      margin: 2rem auto 3rem auto;
      img {
        width: 9.6rem;
      }
    }
    button {
      margin: 0 auto;
    }
  }

  .my_level {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1fr);
    padding-top: 8rem;
    grid-template-areas:
      'level'
      'nextLevels '
      'settings';
  }
  .Mui-selected {
    color: var(--azul-claro) !important;
    font-family: var(--font-Base) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 110% !important;
  }


  .MuiTabs-indicator{
    border: 1px solid var(--dark-blue) !important;
   margin-left: 4%;
    width: 10% !important;
  }

  .settings {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    flex-wrap: wrap;
  }

  .points-history {
    font: 450 1.6rem/1.9rem var(--font-Base);
    color: var(--gray-text);
    padding-left: 2rem;
    padding-right: 2rem;

    padding-top: 1.8rem;
  }

  .history__filters {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 1.4rem;
    margin-bottom: 2rem;
  }

  .history__filters form {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .form-item {
    display: inherit;
  }

  .date-selector {
    display: flex;
    flex-direction: row;
    gap: 0;
  }

  .date-selector > div:nth-child(1) {
    border-right: 2px solid var(--gris);
  }

  .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
    color: var(--gray-text);
  }
  @media (min-width: 995px) {
    padding-left: 5.4rem;
    padding-right: 5.4rem;

    .my_level {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1.3fr 0.7fr;
      gap: 3.4rem 0;
      grid-template-areas:
        'level settings settings'
        'nextLevels nextLevels nextLevels';
    }
    .MuiTabs-indicator{
    border: 1px solid var(--dark-blue) !important;
   margin-left: 1.8rem;
    width: 5rem !important;
  }
    .settings {
      flex-direction: row;
    }

    .points-history {
      min-height: 80rem;
      background-color: var(--background-secondary);
    }

    .history__describe {
      margin-bottom: 2.5rem;
    }

    .history__filters {
      flex-direction: row;
      gap: 1.6rem;
    }

    .history__filters form {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .btn-action {
      margin-left: 1.6rem;
    }

    .table_misions {
      margin-top: 4.7rem;
    }
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AccountInfo = () => {
  const { user } = useSelector(state => state.auth);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
      if(!user?.totalPoints){
        dispatch(auth.getDataAccountAction(user.id));
      }

  }, [dispatch, user.id]);

  useEffect(() => {
    //dispatch(levels.getLevelsAction());
  }, [dispatch]);

  return (
    <AccountWrapper>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='smart-points label'
          textColor='secondary'
          indicatorColor='secondary'
        >
          <Tab label='Mi nivel' {...a11yProps(0)} style={{ fontFamily: 'CircularStdFont' }} />
          <Tab
            label='Historial de puntos'
            {...a11yProps(1)}
            style={{ fontFamily: 'CircularBook' }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MySmart />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PointsHistory />
      </TabPanel>
    </AccountWrapper>
  );
};

export default AccountInfo;
