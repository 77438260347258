import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import Button from '../ui/Buttons/Button';

import { InputsWrapper, WrapperSmartUsers } from './StyledComponents';

import { getUserDistributors } from '../../services/user-distributors';
import routes from '../../helpers/routes';

const SectionTitle = styled.p`
  color: var(--azul-claro);
  display: flex;
  font: 14px var(--font-Base-Bold);
  line-height: 110%;
  position: relative;
  &::after {
    background: var(--azul-oscuro);
    border-radius: 5rem;
    bottom: -0.6rem;
    content: '';
    height: 0.2rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  margin: 0 auto;
  @media (min-width: 992px) {
    height: 100%;
    width: 80%;
  }
`;

const SearchInputWrapper = styled.div`
  border: 0.1rem solid var(--azul-claro);
  border-radius: 10rem;
  display: flex;
  height: 4.6rem;
  margin: 0 auto;
  width: 60%;
`;

const SearchInput = styled.input`
  background: transparent;
  border: none;
  height: 100%;
  padding: 0 2rem;
  width: 100%;
  :focus-visible {
    outline: none;
  }
  ::placeholder {
    color: var(--azul-oscuro);
    font-weight: 450;
    font-size: 1.4rem;
    line-height: 110%;
  }
`;

const columns = [
  { id: 'username', label: 'Nombre' },
  { id: 'email', label: 'Contacto' },
  { id: 'link' },
];

const SmartUsers = () => {
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const [searchText, setSearchText] = useState('');

  const onInputChange = ({ target }) => {
    const { value } = target;
    setSearchText(value);
  };

  const query = useMemo(
    () =>
      qs.stringify(
        {
          populate: '*',
          filters: {
            contacts_distributor: user?.contact_distributor?.id,
            user: {
              username: {
                $contains: searchText,
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        },
      ),
    [user?.contact_distributor?.id, searchText],
  );

  const { data: userDistributors } = useQuery({
    queryKey: ['userDistributors', query],
    queryFn: () => getUserDistributors({ queryStr: query }),
  });

  const smartUsers = userDistributors?.reduce((acc, { attributes }) => {
    const {
      user: { data },
    } = attributes;
    return [...acc, { ...data.attributes, id: data.id }];
  }, []);

  return (
    <WrapperSmartUsers>
      <SectionTitle>Usuarios</SectionTitle>
      <ContentWrapper>
        <InputsWrapper>
          <SearchInputWrapper>
            <SearchInput placeholder='Busca por nombre de usuario...' onChange={onInputChange} />
            <Button color='tertiary'>Buscar</Button>
          </SearchInputWrapper>
        </InputsWrapper>

        <TableContainer
          sx={{
            background: 'white',
            borderRadius: '20px 20px 8px 8px',
            maxHeight: 440,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns?.map(({ label }, index) => (
                  <TableCell
                    align='center'
                    key={`${index}-${label}`}
                    sx={{
                      background: 'var(--azul-oscuro)',
                      color: 'white',
                      fontSize: 14,
                      fowntWeight: 450,
                      height: 28,
                      p: 0,
                    }}
                  >
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {smartUsers?.map(user => {
                return (
                  <TableRow hover key={user.username}>
                    {columns?.map(({ id }) =>
                      id === 'link' ? (
                        <TableCell align='center' key={`celda-1-${id}`} sx={{ height: 56, p: 0 }}>
                          <Button
                            color='secondary'
                            onClick={() =>
                              navigate(
                                `/dashboard/${routes.dashboardRoutes.smartUserProfile.replace(
                                  ':id',
                                  user?.id,
                                )}`,
                              )
                            }
                          >
                            Ir al perfil de usuario
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell
                          align='center'
                          key={`celda-2-${id}`}
                          sx={{
                            color: 'var(--azul-oscuro)',
                            fontSize: 14,
                            fowntWeight: 450,
                            height: 56,
                            p: 0,
                          }}
                        >
                          {user[id]}
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentWrapper>
    </WrapperSmartUsers>
  );
};

export default SmartUsers;
