import React from 'react';

const icn_smart_users = () => {
  return (
    <svg width='23' height='17' viewBox='0 0 23 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.9593 9.90916C21.0334 8.93149 21.708 7.5219 21.708 5.95493C21.708 3.0026 19.3147 0.609375 16.3624 0.609375C14.1643 0.609375 12.2762 1.93653 11.4551 3.83308C12.4624 4.96955 13.0749 6.4634 13.0749 8.09791C13.0749 9.46868 12.6339 10.7977 11.8377 11.8911C12.5899 12.5548 13.2103 13.3571 13.6635 14.2472H23C22.6118 12.4222 21.4906 10.8686 19.9593 9.90916Z'
        fill='#8892AD'
      />
      <path
        d='M0 16.3898H13.2748C12.8868 14.5649 11.7656 13.0113 10.2342 12.0518C11.3084 11.0741 11.9829 9.66454 11.9829 8.09751C11.9829 5.14525 9.58964 2.75195 6.63732 2.75195C3.68532 2.75195 1.29196 5.14525 1.29196 8.09751C1.29196 9.66454 1.96629 11.0741 3.04054 12.0518C1.50915 13.0113 0.387983 14.5649 0 16.3898Z'
        fill='#8892AD'
      />
    </svg>
  );
};

export default icn_smart_users;
