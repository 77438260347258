import React from 'react';
import QuestionCloseAndOpen from './QuestionCloseAndOpen';
import styled from 'styled-components';

const StyledFrequenQuestions = styled.div`
  background: var(--gris-claro);
  height: 100%;
  padding: 0 2rem;
  padding-top: 8rem;
  padding-bottom: 10rem;
  .questionsTest {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  h3 {
    font-family: var(--font-Base);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    text-align: center;
    color: var(--azul-claro);
  }
  h4 {
    color: var(--gray-text);
    font-family: var(--font-Base);
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 120%;
    margin-top: 2.4rem;
    margin-bottom: 5.1rem;
    text-align: center;
  }
`;
export default function FrequentQuestionsInit() {
  return (
    <StyledFrequenQuestions>
      <h3>
        Preguntas frecuentes
      </h3>
      <h4>¿Aún con dudas? Acá te las resolvemos</h4>
      <div className='questionsTest'>
        {questions.map((e, idx) => {
          return <QuestionCloseAndOpen
                          key={idx}
                          question={e.question}
                          answer={e.answer}
                          control={e.control}
                          answers={e.answers}
                          boldText={e.boldText}
                          linkText={e.linkText}
                          linkRef={e.linkRef}


          />;
        })}
      </div>
    </StyledFrequenQuestions>
  );
}

const questions = [
  {
    question: '¿Qué es Smart Points?',
    answer:'Es un programa que reconoce tu fidelidad y valora el compromiso que tienes con la salud femenina. En Bayer, sabemos sobre el esfuerzo que pones en actualizarte día con día y queremos devolverte un poco de lo que das.',
    control :'text'
  },
  {
    question: "¿Cuántos niveles hay y en qué consisten?",
    answer: 'Existen 3 niveles',
    control: 'isList',
    answers : [
      'Smart: 1 a  300 puntos',
      'Smart Pro: 301 a 600 puntos',
      'Smart Elite: 601 a 1800'
    ]
  },
  {
    question: "¿Qué es una misión?",
    answer: "Cada acción que te ayuda a ganar Smart Points. Esto incluye: adquirir sistemas y dispositivos intrauterinos como Kyleena y Mirena; unirte al Directorio Ginecológico de Bayer o BeScience, adicional a éstas, mes con mes se anuncian nuevos objetivos para que consigas más recompensas.",
    control :'text'
  },
  {
    question: "¿Mis puntos tienen vencimiento?",
    answer:
      "Una vez que te registras y formas parte de Smart Points, tienes tres meses para aprovechar tus puntos y redimirlos pues pasado este tiempo se renueva la plataforma para empezar desde cero.",
    control :'text'
  },
  {
    question: "¿Este programa de lealtad tiene un costo?",
    answer: "No, la inscripción y permanencia a este programa es totalmente",
    control :'isBold',
    boldText: "gratis"
  },
  {
    question: "¿Dónde o con quién puedo hacer la compra de medicamentos?",
    answer: "Una vez registrado al programa, podrás realizar tus compras con uno de nuestros",
    control : 'link',
    linkText : "distribuidores autorizados",
    linkRef :"https://bescience.bayer.com/es/feria-de-distribuidores"
  },
  {
    question: "¿Qué necesito para pertenecer a Smart Points?",
    answer:
      "El primer paso es generar una cuenta nueva. Deberás proporcionar tu nombre completo; especialidad médica; correo electrónico y número de cédula profesional. Una vez activada, recibirás puntos por tus misiones. Si ya te registraste en BeScience puedes usar esos mismos datos para ingresar.",
    control :'text'
  },
  {
    question: "¿Mis puntos se actualizarán diariamente?",
    answer: "No, se actualizan cada mes y se liberan en 10 días hábiles a mes vencido.",
    control :'text'
  },
];
