import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { WrapperSmartUsers } from '../StyledComponents';

import { missions } from '../../../redux/actions';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5.8rem;
  justify-self: center;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 992px) {
    width: 80%;
  }
`;

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 2.8rem;
  @media (max-width: 1199px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  align-items: center;
  background: var(--white);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 25.5rem;
  @media (min-width: 1199px) {
    margin-top: ${({ mt }) => mt};
  }
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  padding: 1.8rem 3.6rem;
  width: 100%;
  img {
    width: 18rem;
  }
`;

const CardBody = styled.div`
  align-items: center;
  background: var(--azul-oscuro);
  color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
  width: 100%;
`;

const CardText = styled.div`
  color: var(--green-primary);
  font: 500 3.2rem var(--font-Base-Bold);
`;

const Missions = () => {
  const { token } = useSelector(state => state.auth);

  const { loading, missionsArray } = useSelector(state => state.missions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(missions.getMissionsAction(token));
  }, [dispatch, token]);

  return (
    <WrapperSmartUsers>
      <ContentWrapper>
        <TextWrapper>
          <h2>Misiones</h2>
          <p>
            Recibe exclusivas recompensas cumpliendo misiones con Smart Points.
            <br />
            Cada una de estas acciones tiene un valor diferente en puntos que puedes
            <br />redimir. Tú eliges cuáles y en qué cantidad.
          </p>
        </TextWrapper>
        <CardsWrapper>
          {loading ? (
            <CircularProgress />
          ) : (
            missionsArray.map(
              (
                {
                  attributes: {

                    name,
                    pointsReward,
                    productImage: {
                      data: {
                        attributes: { url },
                      },
                    },
                  },
                },
                i,
              ) => (
                <Card mt={i % 2 === 0 ? '9rem' : '0'} key={`card-mission-${i}`}>
                  <CardHeader>
                    <img src={`https://smartpointsback-dev.bahia360.mx${url}`} alt={name} />
                    {name}
                  </CardHeader>
                  <CardBody>
                    { i === 2 ? 'Por tu registro' : 'Por cada producto obtén'}

                    <CardText>{pointsReward} puntos</CardText>
                  </CardBody>
                </Card>
              ),
            )
          )}
        </CardsWrapper>
      </ContentWrapper>
    </WrapperSmartUsers>
  );
};

export default Missions;
