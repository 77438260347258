import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import './styles.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Grid, Paper, TableContainer} from '@mui/material';
import TableItem from './TableItem';
import TablePagination from "@mui/material/TablePagination";

import {useQuery} from "@tanstack/react-query";
import {getLocation} from "../../../../services/location";
import qs from "qs";

import Filtro from "../../../Dashboard/ContactDelivery/Filtro";
import Button from "../../Buttons/Button";
import CustomModal from "../../Modal/CustomModal";
import {SectionTitle} from "../../../Dashboard/StyledComponents";
import {auth} from "../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 15,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const TableDistributor = ({distributors = [], showButtonSave=true}) => {

  const { user } = useSelector(state => state.auth);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [distributorsList, setDistributorsList] = useState(distributors ?? [])
  const [distributorFilter, setDistributorFilter] = useState(distributorsList ?? [])
  const [distributorSelected, setDistributorSelected] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const dispatch = useDispatch();

  const query = qs.stringify({
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const cleanFilters = () => {
    setSelectedLocation('');
    setDistributorFilter(distributors)
  };
  const handleClick = ( idItem ) => {
    const itemSelected = distributorsList.filter(  distributor => distributor.id ===idItem);
     setDistributorSelected(itemSelected[0]);

  }
  const isSelected = (idItem) => {
    return distributorSelected?.id === idItem
  }
  const setForm = ()=>{

    setShowModal(false);
    setShowConfirmation(true);

    setSelectedLocation('');
    setDistributorFilter(distributors);
    setRowsPerPage(5);


  }
  const handleConfirmation = () =>{
    setShowConfirmation(true)
  }
  const handleSubmit = (e) =>{

    const userId= user.id;

    let formData = {
     data:{
       contacts_distributor:distributorSelected.id,
       user:userId
     }}

    dispatch(auth.updateDistributor({formData, userId,setForm,handleConfirmation } ));
  }

  const handleCancel = () =>{
    setShowModal(false);
    setSelectedLocation('');
    setDistributorSelected({})
  }

  const { data: location } = useQuery({
    queryKey: ['location'],
    queryFn: () => getLocation({ queryStr: query }),
  });

  useEffect(() => {
    if(selectedLocation){
      const distributorsFilter = distributorsList.filter( item => item.attributes.isActive === true && item?.attributes?.address_contact?.data.id === selectedLocation);
      setDistributorFilter(distributorsFilter);

    }
  }, [distributorsList, location, selectedLocation, distributors]);
  useEffect(()=>{

  }, [distributors])
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="center">

        <Filtro
          location={location}
          selectedLocation={selectedLocation}
          setLocation={setSelectedLocation}
          handleClean={cleanFilters}
        />

      <Grid item xs={12} lg={12}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='distributors table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell className='blue' align='center'>

                  </StyledTableCell>
                  <StyledTableCell className='blue' align='center'>
                    Nombre
                  </StyledTableCell>
                  <StyledTableCell className='blue' align='center'>
                    Distribuidor
                  </StyledTableCell>
                  <StyledTableCell className='blue' align='center'>
                    CD/Estado
                  </StyledTableCell>
                  <StyledTableCell className='blue' align='center'>
                    Contacto
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  distributors.length > 0 ?
                  distributorFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item,index )=>{
                      //const isItemSelected = isSelected(item.id);
                      const isItemSelected = isSelected(item.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return <TableItem
                        deliveryData={item}
                        key={labelId}
                        onClick={() => handleClick(item.id)}
                        selected={isItemSelected}
                        ariaChecked={isItemSelected}
                        labelId={labelId}
                      />
                  }) :
                    'Sin distribuidores'
                }
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={distributors?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Filas por página'
          labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`; }}
          />
      </Grid>
      {
        showButtonSave ?
          <Grid item
                container
                direction="row"
                justifyContent="center"
                alignItems="center" xs={12} lg={6}>
            <Button
              color='secondary'
              disabled={ Object.keys(distributorSelected).length <= 0}
              onClick={ ()=>setShowModal(true)}>
              Guardar cambios
            </Button>
          </Grid>
          : ""
      }

      {showModal && (
        <CustomModal>
          <SectionTitle>
            <span>Confirma tu distribuidor seleccionado</span>
          </SectionTitle>
          <h2>
            {distributorSelected.attributes.name}
          </h2>
          <p>{distributorSelected.attributes.distributor.data.attributes.name} · {distributorSelected.attributes.address_contact.data.attributes.name}</p>
          <Button color='outline-secondary' onClick={handleCancel}>
            Regresar
          </Button>
          <Button

            color='outline-tertiary'
            onClick={handleSubmit}>
            Confirmar
          </Button>
        </CustomModal>
      )}
      {
        showConfirmation && (
          <CustomModal>
            <SectionTitle>
              <span>¡Distribuidor seleccionado exitosamente!</span>
            </SectionTitle>
            <p>En un lapso de 1 semana se te notificará
              de la aprobación de tu nuevo distribuidor</p>

            <Button color='outline-secondary' onClick={handleCancel}>
                Aceptar
            </Button>

          </CustomModal>
        )
      }

   </Grid>
  );
};

export default TableDistributor;
