import React from 'react';

const icn_preguntas = () => {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M17.8296 4.50496C17.8296 4.50496 17.241 1.69208 14.6969 0.703307C13.5007 0.247109 11.2983 0.0190083 9.15282 0C7.026 0.0190083 4.82391 0.247109 3.62641 0.703307C1.08358 1.69208 0.493647 4.50496 0.493647 4.50496C0.113919 6.1003 0 7.71736 0 9.16198C0 10.5876 0.113919 12.2033 0.493647 13.8C0.493647 13.8 1.08223 16.6132 3.62641 17.6017C4.82255 18.0579 7.00599 18.2859 9.15282 18.305C11.2983 18.2859 13.4817 18.0769 14.6792 17.6017C17.222 16.6132 17.812 13.8 17.812 13.8C18.1914 12.2047 18.3246 10.5876 18.3053 9.16198C18.3246 7.71736 18.2104 6.10165 17.8306 4.50496H17.8296ZM9.9299 13.4959H8.48693V12.2607H9.9299V13.4959ZM9.94889 10.3975V11.1198H8.48693V9.04929H9.20842C9.50042 9.04911 9.78042 8.9329 9.9869 8.72618C10.1934 8.51947 10.3095 8.23915 10.3096 7.94681C10.3095 7.65447 10.1934 7.37416 9.9869 7.16744C9.78042 6.96072 9.50042 6.84451 9.20842 6.84433C8.91641 6.84451 8.63642 6.96072 8.42994 7.16744C8.22346 7.37416 8.10739 7.65447 8.10721 7.94681H6.64525C6.64561 7.26635 6.91577 6.61385 7.39638 6.13269C7.87699 5.65153 8.52873 5.38106 9.20842 5.3807C9.8881 5.38106 10.5398 5.65153 11.0205 6.13269C11.5011 6.61385 11.7712 7.26635 11.7716 7.94681C11.7716 9.10631 11.0121 10.0947 9.94889 10.3989V10.3975Z' />
    </svg>
  );
};

export default icn_preguntas;
