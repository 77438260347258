import React, {useState} from 'react';
import styled from "styled-components";
import {Wrapper} from "../../ui/Layout/Wrapper";
import FormContactUs from "../../ContactUs/FormContactUsWithoutImages";
import { contactUs} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import ContactUsSuccess from "../../ContactUs/ContactUsSuccess";

const WrapperContact = styled(Wrapper)`
  justify-content: flex-start;
`
const Contact = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    setCurrentStep(prevState => prevState + 1);
  }
  const handleSubmit = (data) => {
    dispatch(contactUs.contactUsAction({data:data}));
    setCurrentStep(prevState => prevState + 1);
  }
  const steps = [
    {
      element: <FormContactUs handleSubmit={handleSubmit}
                              nextStep={handleNextStep}
      />
    },
    {
      element: <ContactUsSuccess/>
    }
  ]

  return (
    <WrapperContact>
      {steps[currentStep].element}
    </WrapperContact>
  );
};

export default Contact;
