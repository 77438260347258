import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: var(--gris);
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font: 400 1.6rem/1.9rem var(--font-Base);
  padding: 5.4rem 2.5rem;

  h2 {
    color: var(--azul-claro);
    font: 500 2.4rem/2.6rem var(--font-Base-Bold);
    margin-bottom: 1.6rem;
  }

  @media (min-width: 992px) {
    min-width: 67rem;
  }
`;
