import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { formatDate } from '../../../helpers/utils';

const columns = [
  { id: 'name', label: 'Misión' },
  { id: 'quantity', label: 'Cantidad' },
  { id: 'points', label: 'Nivel de puntos' },
  { id: 'buy_date', label: 'Fecha' },
];

const MisionsTable = ({ data }) => {
  return (
    <TableContainer
      sx={{
        background: 'var(--white)',
        borderRadius: '2.0rem 2.0rem .8rem .8rem',
        maxHeight: 440,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(({ label }) => (
              <TableCell
                align='center'
                key={label}
                sx={{
                  color: 'var(--gray-text)',
                  fontSize: 14,
                }}
              >
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(row => (
            <TableRow hover key={row.createdAt}>
              {columns?.map(({ id }, i) => (
                <TableCell
                  align='center'
                  key={`${id} ${i}`}
                  sx={{
                    color: 'var(--azul-oscuro)',
                    fontSize: 14,
                    height: 56,
                    p: 0,
                  }}
                >
                  {id === 'buy_date' ? formatDate({ date: new Date(row[id]) }) : row[id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MisionsTable;
