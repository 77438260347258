import React from 'react';
import { Wrapper } from '../ui/Layout/Wrapper';
import { StyledInlineErrorMessage } from '../Register/styles';
import { Formik, Form } from 'formik';
import FormikControl from '../ui/Forms/FormikControl';
import { StartSessionSchema } from './validationStartSession';
import AnimatedButton from '../ui/Buttons/AnimatedButton';
import styled from 'styled-components';
const initState = {
  email: '',
  password: '',
};
const StyledRecovery = styled.div`
  background-color: var(--gris);
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    max-width: 80%;
    text-align: center;
  }
  padding: 1rem;
  .form_wrapper {
    padding: 25px;
  }
  min-height: 100vh;
  .last_forgot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    hr {
      margin-top: 3.5rem;
      width: 80%;
      border: 1px solid var(--white);
    }
    span {
      margin-top: 2.2rem;
      font-family: var(--font-Base);
      color: var(--gray-text);
      a {
        color: var(--black);
      }
    }
  }
  @media (min-width: 1200px) {
    padding: 0 20%;
  }
  .form_wrapper {
    padding: 0px;
  }
`;
export default function RecoveryPass() {
  const onSubmit = (values) => {};
  return (
    <StyledRecovery>
      <Wrapper>
        <h2>Mi perfil</h2>
        <p>
          Actualiza tu contraseña, te recomendamos usar una contraseña segura
          que no uses en ningún otro sitio. Recuerda que al cambiar tu
          contraseña se actualizará en tu cuenta de BeScience.
        </p>
      </Wrapper>

      <div className='form_wrapper'>
        <Formik
          initialValues={initState}
          validationSchema={StartSessionSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className='form-item'>
                <FormikControl
                  name='password'
                  control='input'
                  type='password'
                  label='Contraseña actual'
                  placeholder='Por seguridad escribe tu contraseña actual'
                  autoComplete='on'
                />

                {errors.password && touched.password ? (
                  <StyledInlineErrorMessage>
                    {errors.password}
                  </StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </div>
              <div className='form-item'>
                <FormikControl
                  name='password'
                  control='input'
                  type='password'
                  label='Nueva contraseña'
                  placeholder='Escribe tu contraseña nueva'
                  autoComplete='on'
                />

                {errors.password && touched.password ? (
                  <StyledInlineErrorMessage>
                    {errors.password}
                  </StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </div>

              <div className='form-item'>
                <FormikControl
                  name='password'
                  control='input'
                  type='password'
                  label='Repite tu nueva contraseña'
                  placeholder='Escribe de nuevo tu nueva contraseña'
                  autoComplete='on'
                />

                {errors.password && touched.password ? (
                  <StyledInlineErrorMessage>
                    {errors.password}
                  </StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  marginTop: '4rem',
                }}
              >
                <AnimatedButton color='dark-blue' type='submit'>
                  Regresar al perfil
                </AnimatedButton>
                <AnimatedButton color='secondary' type='submit'>
                  Guardar cambios
                </AnimatedButton>
              </div>
            </Form>
          )}
        </Formik>
        <div className='last_forgot'>
          <hr />
          <span>
            ¿Olvidaste tu contraseña?
            <a href='/dashboard/mi-perfil'> Recuperemosla</a>
          </span>
        </div>
      </div>
    </StyledRecovery>
  );
}
