import styled from "styled-components";


const handleColor = color => {
    switch (color) {
        case 'green':
            return` 
              color : var(--green-primary);
            `
        default:
            return`
              color : var(--gray);
            `

    }
}



export const Header1 = styled.h1`
  font: 450 2.8rem/3rem var(--font-Base-Bold);
  color: var(--dark-blue) ;
  
  @media(min-width: 992px){
    font: 450 3.8rem/4.1rem var(--font-Base);
    font-weight: 500;
  }
`;


export const Header2 = styled.h1`
  color: (${({ color }) => color || "#86D403"});
  font: 700 2.8rem/2.8rem "CircularStd";
  @media(min-width: 992px){
    font: 500 3.8rem/3.5rem "CircularStd";
  }
`;


export const Header3 = styled.h1`
  
  font: normal 2.4rem/2.6rem var(--font-Base);
  color: ${props => props.color || "#1D2B4F" };
  @media(min-width: 992px){
    font: 500 3.8rem/3.5rem var(--font-Base);
  }
`;

export const Header4 = styled.h4`
  ${({ color }) => handleColor(color)};

  font: normal 1.6rem/1.7rem var(--font-Base-Bold);
  
  @media(min-width: 992px){
    font: normal 1.8rem/1.9rem var(--font-Base-Bold);
  }
`;

export const Header5 = styled.h5`
  font: normal 1.6rem/1.9rem var(--font-Base);
  color: ${({ color }) => color || 'var(--azul-oscuro)'};
  a {
    color: var(--dark-blue);
    text-decoration-line: underline;
  }
  @media (min-width: 992px) {
    font: normal 1.6rem/1.9rem var(--font-Base);
  }
`;
