import React, { useState, Fragment} from 'react';
import Button from '../../ui/Buttons/Button';
import {Box, CircularProgress, Modal} from '@mui/material';
import IconDuda from './../../../Assets/icons/icn_duda.svg';
import './styles.css';
import { Link } from 'react-router-dom';
import routes from '../../../helpers/routes';
import {useQuery} from "@tanstack/react-query";
import {getDistributor} from "../../../services/distributor";
import TableDistributo from "./TableDistributo";
import styled from "styled-components";
import {Wrapper} from "../../ui/Layout/Wrapper";
import {getDistributors} from "../../../services/distributors";
import qs from "qs";
import TableDistributor from "../../ui/Tables/TableDistributor/TableDistributor";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFFFFF',
  border: '1px solid #000',
  borderRadius: 8,
  p: 4,
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 5.4,
  paddingRight: 5.4,
  color: ' #02BCFF',
};
const WrapperContact= styled(Wrapper)`
    justify-content: flex-start;
    padding: 20.4rem 2.5rem;

    .contact-table{
      padding-top: 5rem;
    }
`;

const WrapperHelp = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
`

const HasDelivery = ({distributor}) => {

  const [showModal, setShowModal] = useState(false);

  const { isLoading,data: distributorInfo } = useQuery({
    queryKey: ['distributorInfo'],
    queryFn: () => getDistributor( distributor.id ),
  });


  const query = qs.stringify({
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data: distributors , error, isFetching, status} = useQuery({
    queryKey: ['distributors'],
    queryFn: () => getDistributors({ queryStr: query }),
  });

  const cleanedDistributorInfo = {
    name:distributorInfo?.attributes?.contacts_distributor?.data?.attributes?.name ?? '',
    phone:distributorInfo?.attributes?.contacts_distributor?.data?.attributes?.phone ?? '',
    email:distributorInfo?.attributes?.contacts_distributor?.data?.attributes?.email  ?? '',
    distributor: distributorInfo?.attributes?.contacts_distributor?.data?.attributes?.distributor.data?.attributes?.name ?? '',
    location: distributorInfo?.attributes?.contacts_distributor?.data?.attributes?.address_contact.data?.attributes?.name ?? '',
  }

  if (isLoading) {
    return <CircularProgress />
  }

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  if (isFetching) {
    return <CircularProgress />
  }
  return (
    <>
      <div className='contact-delivery__info'>
        {
          distributor.status === 'pending' ?
            <WrapperContact>
              <div className="contact-description">
                <h2>Estamos revisando tu solicitud</h2>
                <p>En un lapso de 1 semana se te notificará la aprobación de tu nuevo distribuidor</p>
                <p>Mientras tanto puedes contactar a los siguientes distribuidores cerca de ti</p>
              </div>
              <div className="contact-table">
                <TableDistributor
                  showButtonSave={false}
                  distributors={distributors} />
              </div>
            </WrapperContact>
            :
            <>
              <h2>Contacta a tu distribuidor</h2>
              <p>Siempre estarás acompañado a cada paso</p>
            </>
        }
      </div>
      <Fragment>
        {
          distributor.status === 'accepted' &&
          <>
            <div className='contact-delivery__table'>
              <TableDistributo data={cleanedDistributorInfo} />
              <span className='mt-5' onClick={handleOpen}>
                  Cambiar distribuidor
                </span>
            </div>
            <div className='contact-delivery__line'></div>

            <WrapperHelp>
              <p>¿Necesitas ayuda? Comunícate con nosotros para aclarar tus dudas.</p>
              <Button color='secondary'>Ir al contacto</Button>
            </WrapperHelp>
          </>
        }
      </Fragment>

      {showModal && (
        <Modal open={showModal} onClose={handleClose}>
          <Box sx={style}>
            <div>
              <img src={IconDuda} alt='' />
            </div>
            <h1 className='modal-title'>¿Estás seguro de cambiar de distribuidor?</h1>

            <Button color='outline-secondary' onClick={handleClose}>
              No, mantener mi distribuidor actual
            </Button>

            <Button color='outline-tertiary'>
              <Link to={`/dashboard/${routes.dashboardRoutes.updateDelivery}`}>
                Sí, quiero un nuevo distribuidor
              </Link>
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default HasDelivery;
