import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import botonIr from "../../../Assets/icons/btn_ir.svg";
const StyledCard = styled.div`
  border: 2px solid var(--azul-claro);
  width: 276px;
  height: 242px;
  border-radius: 10px;
  margin-bottom: 55px;
  margin-top: 50px;
  transition: all 0.3s linear;
  &:hover {
    box-shadow: 0px 18px 88px -4px #18274b24, 0px 8px 60px -6px #18274b14;
    transition: all 0.3s linear;
    .image {
      img {
        background-color: transparent;
        transition: all 0.3s linear;
      }
    }
  }
  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 10%;
    img {
      position: absolute;
      background-color: white;
      padding: 0px 12px;
      transition: all 0.3s linear;
    }
  }
  .container_text {
    text-align: center;
    h1 {
      margin-top: 60px;
      margin-bottom: 20px;
      font-family: var(--font-Base);
      font-size: 18px;
      margin-left: 25px;
      margin-right: 25px;
      font-weight: bolder;
      line-height: 110%;
      font-size: 18px;
      color: #02bcff;
    }
    p {
      font-family: var(--font-Base);
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 120%;
      color: #808eb0;
      margin: 0 30px;
    }
  }
  .circle_green {
    &:hover {
      transform: scale(1.1);
    }
    img {
      width: 90px;
      height: 90px;
      position: absolute;
      margin-top: 17px;
      margin-left: 90px;
      cursor: pointer;
    }
  }
  @media (min-width: 992px) {
    margin-top: 0px;
  }
`;

export default function Card(props) {
  const { image, title, subtitle, link } = props;
  return (
    <StyledCard className="Card">
      <div className="image">
        <img src={image} alt="icon_doc" />
      </div>
      <div className="container_text">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
      <div className="circle_green">
        <Link to={link}>
        <img src={botonIr} alt="botón ir"/>
        </Link>
      </div>
    </StyledCard>
  );
}
