import React from 'react';
import { Form, Formik } from 'formik';
import { RegisterSchema } from './validationsPersonal';
import FormikControl from '../ui/Forms/FormikControl';
import { StyledInlineErrorMessage } from './styles';
import AnimatedButton from '../ui/Buttons/AnimatedButton';

const PersonalData = (props) => {
  const handleSubmit = (values) => {
    props.nextStep(values);
  };

  return (
    <Formik
      initialValues={props.data}
      validationSchema={RegisterSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className='form-item'>
            <FormikControl
              control='input'
              type='text'
              label='Nombre(s)'
              name='firstName'
              placeholder='Escribe tu nombre'
            />

            {errors.firstName && touched.firstName ? (
              <StyledInlineErrorMessage>
                {errors.firstName}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>

          <div className='form-item'>
            <FormikControl
              control='input'
              type='text'
              label='Apellidos'
              name='lastName'
              placeholder='Escribe tus apellidos'
            />

            {errors.lastName && touched.lastName ? (
              <StyledInlineErrorMessage>
                {errors.lastName}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>
          <div className='form-item'>
            <FormikControl
              control='input'
              type='email'
              label='Correo electrónico'
              name='email'
              placeholder='Escribe tu correo electrónico'
            />

            {errors.email && touched.email ? (
              <StyledInlineErrorMessage>
                {errors.email}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>

          <div className='form-item'>
            <FormikControl
              control='input'
              type='password'
              label='Contraseña'
              name='password'
              placeholder='Escribe tu contraseña'
            />

            {errors.password && touched.password ? (
              <StyledInlineErrorMessage>
                {errors.password}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>

          <div className='form-item'>
            <FormikControl
              control='input'
              type='password'
              label='Repite tu contraseña'
              name='confirmPassword'
              placeholder='Escribe tu contraseña'
            />

            {errors.confirmPassword && touched.confirmPassword ? (
              <StyledInlineErrorMessage>
                {errors.confirmPassword}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>

          <AnimatedButton type='submit'>Siguiente</AnimatedButton>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalData;
