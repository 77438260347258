import React from "react";
import styled from "styled-components";
import arrowDown from "../../../Assets/icons/icon_arrow_down.svg";
const StyledArrowDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  position: ${(props) => (props.secundario ? "absolute" : "")};
  background: ${(props) => (props.secundario ? "var(--gris-claro)" : "")};
  margin-top: -15px;
  margin-top: ${(props) => (props.secundario ? "-44px" : "")};
  left: 0;
  right: 0;
  z-index: ${(props) => (props.secundario ? "-1" : "")};
  height: ${(props) => (props.secundario ? "60px" : "")};
  img {
    :hover {
      transition: transform 0.2s; /* Animation */
      transform: scale(1.3);
      cursor: pointer;
    }
  }
  @keyframes uparrow {
    0% {
      -webkit-transform: translateY(0);
      opacity: 0.4;
    }
    100% {
      -webkit-transform: translateY(-1em);
      opacity: 0.9;
    }
  }
  @keyframes downarrow {
    0% {
      -webkit-transform: translateY(0);
      opacity: 0.4;
    }
    100% {
      -webkit-transform: translateY(0.4em);
      opacity: 0.9;
    }
  }

  .container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
  .arrow {
    border-color: transparent;
    border-style: solid;
    border-width: 0 2em;
    display: block;
    height: 0;
    margin: 10em auto;
    opacity: 0.4;
    text-indent: -9999px;
    transform-origin: 50% 50%;
    width: 0;
  }
  .up {
    animation: uparrow 0.6s infinite alternate ease-in-out;
  }
`;
export default function ArrowDown(props) {
  return (
    <StyledArrowDown {...props}>
      <img
        className="up"
        src={arrowDown}
        alt="flecha"
        onClick={() => window.scrollTo(0, 400)}
      />
    </StyledArrowDown>
  );
}
