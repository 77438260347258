import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import qs from 'qs';
import styled from 'styled-components';
import { Divider, List, ListItem, ListItemButton, Paper, TextField } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import Button from '../ui/Buttons/Button';

import {
  DateInputWrapper,
  InputsWrapper,
  SectionTitle,
  SelectButton,
  SelectWrapper,
  WrapperSmartUsers,
} from './StyledComponents';

import ArrowDown from '../../Assets/icons/icn_flecha_down.svg';
import Filter from '../../Assets/icons/icn_active_filtro.svg';
import MisionsTable from './components/MisionsTable';

import { getBuys } from '../../services/buys';
import { getMissions } from '../../services/missions';
import { getPurchaseDetails } from '../../services/purchase-details';
import { getUserDistributors } from '../../services/user-distributors';
import { downloadReportService } from '../../services/reports';

export const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  margin: 0 auto;
  width: 80%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Reports = () => {
  const { contact_distributor } = useSelector(state => state.auth);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [firstDate, setFirstDate] = useState(dayjs('2021/01/01'));
  const [secondDate, setSecondDate] = useState(dayjs(new Date()));
  const [selectedMission, setSelectedMission] = useState('');

  const smartUsersQuery = useMemo(
    () =>
      qs.stringify(
        {
          populate: '*',
          filters: {
            contacts_distributor: contact_distributor?.id,
          },
        },
        {
          encodeValuesOnly: true,
        },
      ),
    [contact_distributor?.id],
  );

  const { data: userDistributors } = useQuery({
    queryKey: ['userDistributors', smartUsersQuery],
    queryFn: () => getUserDistributors({ queryStr: smartUsersQuery }),
  });

  const smartUsersIds = userDistributors?.reduce((acc, { attributes }) => {
    const {
      user: { data },
    } = attributes;
    return [...acc, data.id];
  }, []);

  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const buysByUserQuery = useMemo(
    () =>
      qs.stringify(
        {
          populate: '*',
          filters: {
            user: {
              id: {
                $in: smartUsersIds,
              },
            },
            buy_date: {
              $gte: firstDate.format(),
              $lte: secondDate.format(),
            },
          },
        },
        {
          encodeValuesOnly: true,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstDate.format(), secondDate.format()],
  );

  const { data: missions } = useQuery({
    queryKey: ['missions'],
    queryFn: () => getMissions({ queryStr: query }),
  });

  const { data: buysByUser } = useQuery({
    queryKey: ['buysByUser', buysByUserQuery],
    queryFn: () => getBuys({ queryStr: buysByUserQuery }),
  });

  const buysByUserIds = buysByUser?.reduce((acc, { id }) => {
    return [...acc, id];
  }, []);

  const purchaseDetailsQuery = useMemo(
    () =>
      qs.stringify(
        {
          populate: '*',
          filters: {
            purchase_id: {
              id: {
                $in: buysByUserIds,
              },
            },
            product: {
              name: {
                $contains: selectedMission,
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        },
      ),
    [buysByUserIds, selectedMission],
  );

  const { data: purchaseDetails } = useQuery({
    queryKey: ['purchaseDetails', purchaseDetailsQuery],
    queryFn: () => getPurchaseDetails({ queryStr: purchaseDetailsQuery }),
  });

  const cleanedPurchaseDetails = purchaseDetails?.reduce((acc, { attributes }) => {
    const {
      product: {
        data: {
          attributes: { name },
        },
      },
      purchase_id: {
        data: {
          attributes: { buy_date },
        },
      },
      ...purchase
    } = attributes;
    return [...acc, { ...purchase, name, buy_date }];
  }, []);

  const cleanFilters = () => {
    setFirstDate(dayjs('2021/01/01'));
    setSecondDate(dayjs(new Date()));
    setSelectedMission('');
  };

  const mutation = useMutation({
    mutationFn: () => downloadReportService(),
    onSuccess: response => {
      const url = encodeURI(window.URL.createObjectURL(new Blob([response.data])));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'reporte.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });

  const downloadReport = () => {
    mutation.mutate();
  };

  return (
    <WrapperSmartUsers>
      <ContentWrapper>
        <SectionTitle>Aquí podrás descargar los reportes</SectionTitle>
        <InputsWrapper>
          <Button color='secondary' onClick={() => setToggleFilters(!toggleFilters)}>
            <img src={Filter} alt='icon filters' /> Filtros
          </Button>

          {toggleFilters && (
            <React.Fragment>
              <SelectWrapper>
                <SelectButton onClick={() => setToggleSelect(!toggleSelect)}>
                  Selecciona las misiones a filtrar <img src={ArrowDown} alt='icon chevron down' />
                </SelectButton>
                {toggleSelect && (
                  <Paper
                    sx={{
                      top: 48,
                      position: 'absolute',
                      width: '100%',
                    }}
                  >
                    <List sx={{ p: 0 }}>
                      {missions.map(({ attributes: { name, slug } }, i) => (
                        <React.Fragment key={`${name} list-item`}>
                          <ListItem
                            sx={{
                              backgroundColor:
                                selectedMission === name ? 'var(--gris-claro)' : 'white',
                            }}
                          >
                            <ListItemButton
                              onClick={() => {
                                setSelectedMission(slug);
                                setToggleSelect(false);
                              }}
                            >
                              {name}
                            </ListItemButton>
                          </ListItem>
                          {i !== missions.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  </Paper>
                )}
              </SelectWrapper>

              <DateInputWrapper>
                De:
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    closeOnSelect
                    inputFormat='MM/DD/YYYY'
                    maxDate={secondDate}
                    minDate={firstDate}
                    value={firstDate}
                    onChange={newValue => setFirstDate(newValue)}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
                a:
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    closeOnSelect
                    inputFormat='MM/DD/YYYY'
                    maxDate={secondDate}
                    minDate={firstDate}
                    value={secondDate}
                    onChange={newValue => setSecondDate(newValue)}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </DateInputWrapper>

              <Button color='tertiary' onClick={cleanFilters}>
                Limpiar
              </Button>
            </React.Fragment>
          )}
        </InputsWrapper>

        <MisionsTable data={cleanedPurchaseDetails} />

        <Button color='secondary' style={{ alignSelf: 'center' }} onClick={downloadReport}>
          Descargar reportes
        </Button>
      </ContentWrapper>
    </WrapperSmartUsers>
  );
};

export default Reports;
