import axios from "./config";

export const uploadHistory = async (data) => {
  return axios
    .post("/buy-upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};
