import * as Yup from "yup";


export const LogInSchema = Yup.object().shape({
    email: Yup.string()
        .email('Por favor, introduce una dirección de correo electrónico válida')
        .required('Por favor, introduce tu dirección de correo electrónico'),
    password: Yup.string()
        .required("Por favor, introduce tu contraseña")
        .min(6, "La contraseña debe tener al menos 6 caracteres")
});