import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import backBlue from '../../../Assets/Images/BackBluePointsMobile.png';
import backBlueDesktop from '../../../Assets/Images/BackBluePoints.png';
import AnimatedButton from '../Buttons/AnimatedButton';
import HeaderGreen from '../../Utilities/HeaderGreen';
import images from '../../../Assets/Images/images_back_blue_points.png';
import routes from '../../../helpers/routes';
const StyledBackBlue = styled.div`
  max-height: 674px;
  .contenedor_back {
    position: relative;
    display: inline-block;
    width: 100%;
    z-index: 1;
    .back_smart_desktop {
      display: none;
    }
    img {
      width: 100%;
      max-height: 700px;
    }
    .images_back {
      display: none;
      position: absolute;
      width: 350px;
      top: 0;
      margin-left: 56%;
      margin-top: 50px;
    }
    #gif-container {
      display: none;
      position: absolute;
      top: 0;
      z-index: 1;
      height: 200px;
      width: 150px;

      img {
        position: absolute;
        display: ${(props) => (props.isGifVisible ? 'block' : 'none')};
        left: 0;
      }
    }
  }
  .text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    button {
      margin-top: 34px;
    }
    a {
      text-decoration: none;
    }
  }
  @media (min-width: 992px) {
    .contenedor_back {
      .back_smart_mobile {
        display: none;
      }
      .back_smart_desktop {
        display: block;
      }
    }
    @media (min-width: 1200px) {
      .contenedor_back {
        img {
        }
        #gif-container {
          display: block;
        }
      }
    }
  }
`;
const greenText = 'EMPIEZA A ACUMULAR PUNTOS';
const blackText = '¿Ya eres parte de smart Points?';
const thirdText =
  'Regístrate y únete e la comunidad Bayer.';
export default class BackBlueHomeClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {
        x: 0,
        y: 0,
      },
    };
    this.container = React.createRef();
  }

  setStateGif(state) {
    this.setState({
      isGifVisible: state,
    });
  }

  componentDidMount() {
    let container = this.container.current;
    let imagesContaiener = document.getElementById('gif-container');

    container.addEventListener('mouseenter', this.setStateGif.bind(this, true));

    container.addEventListener(
      'mouseleave',
      this.setStateGif.bind(this, false)
    );

    container.addEventListener('mousemove', (e) => {
      const images = imagesContaiener.children;

      for (var i = 0; i < images.length; i++) {
        var y = e.clientY - container.getBoundingClientRect().y;
        var x = e.clientX - container.getBoundingClientRect().x;
        const Img = images[i];

        const gifWidth = imagesContaiener.clientWidth / 2;
        const gifHeight = imagesContaiener.clientHeight / 2;

        var correccionX =
          x < gifWidth
            ? x - gifWidth
            : x + gifWidth - container.getBoundingClientRect().width > 0
            ? x + gifWidth - container.getBoundingClientRect().width
            : 0;

        var correccionY =
          y < gifHeight
            ? y - gifHeight
            : y + gifHeight - container.getBoundingClientRect().height > 0
            ? y + gifHeight - container.getBoundingClientRect().height
            : 0;

        Img.style.top = `${y - i * 8 - gifHeight - correccionY}px`;
        Img.style.left = `${x - i * 8 - gifWidth - correccionX}px`;
        const rndInt = Math.floor(Math.random() * images.length) + 3;
        Img.style.transition = `all  .${rndInt * i}00s ease`;
        Img.style.zIndex = rndInt;
      }
    });
  }
  render() {
    return (
      <StyledBackBlue
        ref={this.container}
        isGifVisible={this.state.isGifVisible}
      >
        <div className='contenedor_back'>
          <div id='gif-container'>
            {arrayMouse.map((e, idx) => (
              <img id={'img_' + idx} src={e.image} alt='' key={idx} />
            ))}
          </div>
          <img className='back_smart_mobile' src={backBlue} alt='back_smart' />
          <img
            className='back_smart_desktop'
            src={backBlueDesktop}
            alt='back_smart'
          />
          <div className='images_back'>
            <img src={images} alt='images_back' />
          </div>
          <div className='text'>
            <HeaderGreen
              secundario
              greenText={greenText}
              blackText={blackText}
              thirdText={thirdText}
            />
            <Link to={routes.register}>
              <AnimatedButton color="dark-blue">Registrarme</AnimatedButton>
            </Link>
          </div>
        </div>
      </StyledBackBlue>
    );
  }
}

const arrayMouse = [
  {
    image: require('../../../Assets/Images/animation1.png'),
  },
  {
    image: require('../../../Assets/Images/animation2.png'),
  },
  {
    image: require('../../../Assets/Images/animation3.png'),
  },
];
