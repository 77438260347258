import IcnHome from '../Assets/menu-icons/icn_inicio';
import IcnMissions from '../Assets/menu-icons/icn_missions';
import IcnLevel from '../Assets/menu-icons/icn_level';
import IcnContact from '../Assets/menu-icons/icn_distribuidor';
import IcnAbout from '../Assets/menu-icons/icn_acerca_de';
import IcnQuestion from '../Assets/menu-icons/icn_preguntas';
import IcnReports from '../Assets/menu-icons/icn_reports';
import IcnSmartUsers from '../Assets/menu-icons/icn_smart_users';
import IcnInfo from '../Assets/menu-icons/icn_info';

import routes from './routes';
import { roles } from './roles';

export const routesArray = [
  {
    name: 'Inicio',
    route: '/',
    end: true,
  },
  {
    name: 'Acerca de Smart Points',
    route: '/acerca-de',
  },
  {
    name: 'Preguntas frecuentes',
    route: '/preguntas-frecuentes',
  },
  {
    name: 'Contacto',
    route: '/contactUs',
  },
];

export const routesDashboard = [
  {
    name: 'Inicio',
    route: routes.dashboard,
    end: true,
    icon: IcnHome,
    permission: [roles.USER, roles.ADMIN],
  },
  {
    name: 'Misiones',
    route: routes.dashboardRoutes.missions,
    icon: IcnMissions,
    permission: [roles.USER],
  },
  {
    name: 'Niveles',
    route: routes.dashboardRoutes.levels,
    icon: IcnLevel,
    permission: [roles.USER],
  },
  {
    name: 'Contactar distribuidor',
    route: routes.dashboardRoutes.contactDelivery,
    icon: IcnContact,
    permission: [roles.USER],
  },
  {
    name: 'Smart usuarios',
    route: routes.dashboardRoutes.smartUsers,
    icon: IcnSmartUsers,
    permission: [roles.DISTRIBUTOR],
  },
  {
    name: 'Reportes',
    route: routes.dashboardRoutes.reports,
    icon: IcnReports,
    permission: [roles.DISTRIBUTOR],
  },
  {
    name: 'Instrucciones de carga',
    route: routes.dashboardRoutes.uploadInstructions,
    icon: IcnInfo,
    permission: [roles.DISTRIBUTOR],
  },
  {
    name: 'Regla puntajes',
    route: routes.dashboardRoutes.logicPoints,
    icon: IcnContact,
    permission: [roles.ADMIN],
  },
  {
    name: 'Reglas niveles',
    route: routes.dashboardRoutes.logicLevels,
    icon: IcnContact,
    permission: [roles.ADMIN],
  },
];

export const routesGeneral = [
  {
    name: '¿Qué es Smart Points?',
    route: routes.dashboardRoutes.whatIs,
    icon: IcnAbout,
  },
  {
    name: 'Preguntas frecuentes',
    route: routes.dashboardRoutes.frequentQuestions,
    icon: IcnQuestion,
  },
];
export const routesNavBar = [
  {
    link: `/dashboard`,
    text: "Inicio",
    permission: [roles.USER, roles.ADMIN],
  },
  {
    link: routes.dashboardRoutes.missions,
    text: "Misiones",
    permission: [roles.USER],
  },
  {
    link: routes.dashboardRoutes.levels,
    text: "Niveles",
    permission: [roles.USER],
  },
  {
    link: routes.dashboardRoutes.contactDelivery,
    text: "Contactar distribuidor",
    permission: [roles.USER]
  },
  {
    link: routes.dashboardRoutes.whatIs,
    text: "¿Qué es Smart Points?",
    permission: [roles.USER]
  },
  {
    link: routes.dashboardRoutes.frequentQuestions,
    text: "Preguntas frecuentes",
    permission: [roles.USER],
  },
  {
    link: routes.dashboardRoutes.contact,
    text: "Contacto",
    permission: [roles.USER],
  },
  {
    link: routes.dashboardRoutes.profile,
    text: "Mi perfil",
    permission: [roles.USER]
  }
];
