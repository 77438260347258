import React from "react";
import styled from "styled-components";
import ArrowDown from "../ui/Arrows/ArrowDown";
import Card from "../ui/Slider/Card";

const StyledHomeCards = styled.div`
  margin-bottom: 80px;
  .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header_title {
    margin-top: 38px;
    padding: 0 20px;
    font-family: var(--font-Base-Bold);
    text-align: center;
    p.green {
      font-size: 18px;
      text-transform: uppercase;
      color: var(--green-primary);
    }
    h2 {
      font-family: var(--font-Base);
      font-size: 32px;
      color: var(--dark-blue);
      margin-top: 3rem;
    }
  }
  @media (min-width: 992px) {
    margin-bottom: 100px;
    .header_title {
      margin-top: 100px;
      padding: 0;
    }
    .cards {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 100px;
      .Card {
        :nth-child(2n) {
          margin-bottom: 150px;
          border: none;
        }
      }
    }
  }
`;

export default function HomeCards() {
  return (
    <StyledHomeCards>
      <ArrowDown />
      <div className='header_title'>
        <p className="green">devolverte un poco de lo mucho que das</p>
        <h2>
          Nuestro plan de lealtad es un programa que <br/>
          reconoce tu compromiso con la salud.
        </h2>
      </div>
      <div className='cards'>
        {cards.map((e, idx) => {
          return (
            <Card
              key={idx}
              title={e.title}
              subtitle={e.subtitle}
              image={e.img}
              link={e.link}
            />
          );
        })}
      </div>
    </StyledHomeCards>
  );
}

const cards = [
  {
    title: "Puntos por lealtad",
    subtitle:
      "Conoce Smart Points, los puntos que puedes ganar al realizar compras, pertenecer a al directorio médico y más. ",
    img: require("../../Assets/icons/icn_doc.svg").default,
    link:"/preguntas-frecuentes"
  },
  {
    title: "Comunidad Bayer",
    subtitle:
      "Únete y registrate a la comunidad Bayer y entérate de nuestras novedades para formar parte de nuevas iniciativas.",
    img: require("../../Assets/icons/icn_comunidad.svg").default,
    link:"/inicio-sesion"
  },
  {
    title: "Recompensas por niveles",
    subtitle:
      "Recibirás descuentos y beneficios completando niveles. ¡Tú decides hasta dónde llegar! ¿Quieres saber más?",
    img: require("../../Assets/icons/icn_recompensa.svg").default,
    link:"/acerca-de"
  },
];
