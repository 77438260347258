import styled from "styled-components";
import {Field} from "formik";

export const PageWrapper = styled.section`
  margin-top: 1.6rem;
  max-width: 31rem;
  form{
    display: flex;
    flex-direction: column;
  }
  
  .form-item{
    label{
      display: block;
      color: var(--black);
      font: 400 1.4rem/1.5rem  var(--font-Base);
      margin-left: 1.6rem;
      text-align: left;
      padding-bottom: 2rem;
    }
  }
  .is-radio-button{
    display: inline-block;
    margin-left: 1rem;
  }
  .form-flex{
    display: flex;
    align-items: flex-start;
  }
  .is-center{
    align-self: center;
  }
  .mb-0{
    margin-bottom: 0;
  }
  .form__action-buttons{
    gap: 3rem;
    display: flex;
    justify-content: center;
  }
  .is-center{
    text-align: center;
  }
  
  @media(min-width: 992px) {
    max-width: 30.8rem
  }  

  @media(min-width: 1610px) {
    min-height: 60vh;
  }
  @media(min-width: 2000px) {
    min-height: 65vh;
  }
`;
export const Input = styled(Field)`
  color: var(--gray-secondary);
  background-color: var(--white);
  padding: 1.6rem 1.8rem;
  border: 1px solid var(--white);
  border-radius: 10rem;
  font: 400 1.4rem/1.5rem  var(--font-Base);
  
  
  width: 100%;
  &:focus
  &:active {
    //TODO 
    //- preguntar como seria el estado activo del input
    border: 1px solid var(--sky-blue);
    outline: none;
  }

  /* Autocomplete styles in Chrome
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }    -webkit-text-fill-color: black;*/

`;
export const StyledInlineErrorMessage = styled.div`
  min-height: 1.3rem;
  color: var(--red-secondary);
  display: block;
  margin: 0.4rem 0 1.3rem 1.8rem;
  font: 450 1.2rem/1.3rem var(--font-Base);

`;

export const FormTitle = styled.h3`
  font: 400 1.6rem/1.9rem var(--font-Base-Bold);
  color: var(--azul-claro);
  margin-bottom: 1.6rem;
`;