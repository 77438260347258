import React from 'react';
import {useSelector} from "react-redux";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import routes from "../helpers/routes";

const RequireAuth = () => {


    let location = useLocation();

    const { user } = useSelector(state => state.auth)

    if( !user ) {
        return <Navigate to={routes.register} state={{from : location}} replace/>
    }

    return  <Outlet/>


};

export default RequireAuth;