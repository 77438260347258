import React from 'react';

const icn_missions = () => {
  return (
    <svg
      width='16'
      height='21'
      viewBox='0 0 16 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M8.15471 15.0184V16.626C9.51812 16.9122 10.3331 17.4848 10.3331 17.8812C10.3331 18.4757 8.68264 19.3566 6.17109 19.3566C3.65953 19.3566 2.00908 18.4757 2.00908 17.8812C2.00908 17.4628 2.82064 16.9122 4.18747 16.626V15.0184C1.89119 15.4148 0.3125 16.5159 0.3125 17.8812C0.3125 19.5988 2.82405 20.9201 6.14888 20.9201C9.4737 20.9201 11.9853 19.6224 11.9853 17.8812C12.0092 16.5159 10.4544 15.4148 8.15812 15.0184H8.15471Z' />
      <path d='M13.9197 5.15294L15.8571 2.57648C15.9411 2.45611 15.9896 2.31774 15.998 2.17491C16.0064 2.03208 15.9743 1.88969 15.905 1.7617C15.8363 1.63506 15.7304 1.5287 15.5993 1.45474C15.4682 1.38077 15.3172 1.34216 15.1635 1.3433H7.03082V0.770752C7.0315 0.669329 7.01029 0.568792 6.96844 0.474973C6.92658 0.381154 6.8649 0.295925 6.78698 0.224225C6.70906 0.152525 6.61644 0.0957858 6.51452 0.057298C6.41259 0.0188102 6.30337 -0.000661642 6.1932 1.7155e-05C5.73916 1.7155e-05 5.30861 0.353925 5.30861 0.770752V17.3306C5.30793 17.4319 5.32912 17.5324 5.37095 17.6262C5.41278 17.72 5.47442 17.8053 5.5523 17.8769C5.63017 17.9486 5.72273 18.0054 5.82461 18.0439C5.92649 18.0824 6.03566 18.1019 6.14579 18.1013C6.367 18.0988 6.5784 18.0169 6.73483 17.8728C6.89126 17.7288 6.98032 17.5342 6.98298 17.3306V8.96257H15.1156C15.2693 8.96378 15.4203 8.92519 15.5513 8.85121C15.6824 8.77723 15.7882 8.67084 15.8567 8.54417C15.9261 8.41618 15.9581 8.27379 15.9497 8.13096C15.9414 7.98813 15.8928 7.84976 15.8089 7.7294L13.9197 5.15294Z' />
    </svg>
  );
};

export default icn_missions;
