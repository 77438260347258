import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Check from '../../../Assets/icons/icn_check_green.svg';

const SuccessMessageWrapper = styled.div`
  align-items: center;
  color: var(--azul-oscuro);
  font-size: 14px;
  display: flex;
  gap: 0.4rem;
  position: absolute;
  top: 8rem;
  right: 8rem;
`;

const SuccessMessage = ({ message }) => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  }, [message]);

  return (
    showMessage && (
      <SuccessMessageWrapper>
        <img src={Check} alt='icon check' />
        {message}
      </SuccessMessageWrapper>
    )
  );
};

export default SuccessMessage;
