import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assets/icons/icn_flecha.svg";

const CardSettingsWrapper = styled.div`
  border-radius: 1rem;
  padding-top: 19.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2.6rem;
  min-width: 3.2rem;
  height: max-content;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  background-size: cover;
  background-blend-mode: inherit;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), #11374f),
    url(${(props) => props.background});

  p {
    font: 450 1.6rem/1.9rem var(--font-Base);
    color: var(--white);
  }
  hr {
    display: none;
  }
  a {
    display: none;
  }
  .icon_top{
    display: none;
  }
  :hover {
      height: 39.3rem;
      background-image: linear-gradient(
          220deg,
          rgba(39, 170, 225, 0.8),
          var(--blue-card)
        ),
        url(${(props) => props.background});
      padding-top: 25rem;
      hr {
        display: block;
        margin: 2.2rem 0;
        border: 1.6rem solid (--white);
        width: 100%;
      }
      a {
        display: block;
        display: flex;
        cursor: pointer;
        font: 450 1.6rem/1.9rem var(--font-Base);
        color: var(--white);
        max-width: 15rem;
        img {
          margin-left: 1.3rem;
        }
      }
      .icon_top{
        display: block;
        position: absolute;
        margin-top: -22rem;
      }
    }
  @media (min-width: 995px) {
    margin-left: 0;
    margin-right: 0;
    max-width: 22.9rem;
    min-width: 13.9rem;
    :hover {
      height: 39.3rem;
      background-image: linear-gradient(
          220deg,
          rgba(39, 170, 225, 0.8),
          var(--blue-card)
        ),
        url(${(props) => props.background});
      padding-top: 25rem;
      hr {
        display: block;
        margin: 2.2rem 0;
        border: 1.6rem solid (--white);
        width: 100%;
      }
      a {
        display: block;
        display: flex;
        cursor: pointer;
        font: 450 1.6rem/1.9rem var(--font-Base);
        color: var(--white);
        max-width: 15rem;
        img {
          margin-left: 1.3rem;
        }
      }
      .icon_top{
        display: block;
        position: absolute;
        margin-top: -22rem;
      }
    }
  }
`;


const CardSettings = ({ describeText, link, background, textLink, icon }) => {
  return (
    <CardSettingsWrapper background={background}>
      <img className="icon_top" src={icon ? icon : ''} alt="icon_top"/>
      <p>{describeText}</p>
      <Link to={link}></Link>
      <hr />
      <a href={link}>
        {textLink }  <img src={ArrowLeft} alt="icon_arrow" />
      </a>
    </CardSettingsWrapper>
  );
};

export default CardSettings;
