import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import backBlue from '../../../Assets/Images/back_blue_home_secondary_desktop.png';
import HeaderGreen from '../../Utilities/HeaderGreen';

import AnimatedButton from '../Buttons/AnimatedButton';

import routes from '../../../helpers/routes';

const StyledBackBlue = styled.div`
  max-height: 674px;
  margin-bottom: -4px;
  max-width: 100%;
  .contenedor_back {
    position: relative;
    display: inline-block;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
      max-height: 500px;
    }
    #gif-container {
      display: none;
      position: absolute;
      top: 0;
      z-index: 1;
      height: 200px;
      width: 150px;

      img {
        position: absolute;
        display: ${(props) => (props.isGifVisible ? 'block' : 'none')};
        left: 0;
      }
    }
  }
  .text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button {
      margin-top: 34px;
    }
  }
  @media (min-width: 1200px) {
    .contenedor_back {
      img {
      }
      #gif-container {
        display: block;
      }
    }
  }
`;
const greenText = 'EMPIEZA A ACUMULAR PUNTOS';
const blackText = '¿Ya eres parte de Smart Points?';
const thirdText =
  'Regístrate y únete e la comunidad Bayer.';

export default class BackBlueAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {
        x: 0,
        y: 0,
      },
    };
    this.container = React.createRef();
  }

  setStateGif(state) {
    this.setState({
      isGifVisible: state,
    });
  }

  componentDidMount() {
    let container = this.container.current;
    let imagesContaiener = document.getElementById('gif-container');

    container.addEventListener('mouseenter', this.setStateGif.bind(this, true));

    container.addEventListener(
      'mouseleave',
      this.setStateGif.bind(this, false)
    );

    container.addEventListener('mousemove', (e) => {
      const images = imagesContaiener.children;

      for (var i = 0; i < images.length; i++) {
        var y = e.clientY - container.getBoundingClientRect().y;
        var x = e.clientX - container.getBoundingClientRect().x;
        const Img = images[i];

        const gifWidth = imagesContaiener.clientWidth / 2;
        const gifHeight = imagesContaiener.clientHeight / 2;

        var correccionX =
          x < gifWidth
            ? x - gifWidth
            : x + gifWidth - container.getBoundingClientRect().width > 0
            ? x + gifWidth - container.getBoundingClientRect().width
            : 0;

        var correccionY =
          y < gifHeight
            ? y - gifHeight
            : y + gifHeight - container.getBoundingClientRect().height > 0
            ? y + gifHeight - container.getBoundingClientRect().height
            : 0;

        Img.style.top = `${y - i * 2 - gifHeight - correccionY}px`;
        Img.style.left = `${x - i * 8 - gifWidth - correccionX}px`;
        const rndInt = Math.floor(Math.random() * images.length) + 3;
        Img.style.transition = `all  .${rndInt * i}00s ease`;
        Img.style.zIndex = rndInt;
      }
    });
  }
  render() {
    return (
      <StyledBackBlue
        ref={this.container}
        isGifVisible={this.state.isGifVisible}
      >
        <div className='contenedor_back'>
          <div id='gif-container'>
            {arrayMouse.map((e, idx) => (
              <img id={'img_' + idx} src={e.image} alt='' key={idx} />
            ))}
          </div>
          <img className='back_smart_mobile' src={backBlue} alt='back_smart' />
          <div className='text'>
            <HeaderGreen
              greenText={greenText}
              blackText={blackText}
              thirdText={thirdText}
            />
            <Link to={routes.register}>
              <AnimatedButton color='secondary'>Registrarme</AnimatedButton>
            </Link>
          </div>
        </div>
      </StyledBackBlue>
    );
  }
}

const arrayMouse = [
  {
    image: require('../../../Assets/Images/animation1.png'),
  },
  {
    image: require('../../../Assets/Images/animation2.png'),
  },
  {
    image: require('../../../Assets/Images/animation3.png'),
  },
  {
    image: require('../../../Assets/Images/animation1.png'),
  },
  {
    image: require('../../../Assets/Images/animation2.png'),
  },
  {
    image: require('../../../Assets/Images/animation3.png'),
  },

];
