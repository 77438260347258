import React, { useState, useEffect } from "react";
import arrowLeft from "../../../Assets/icons/arrowLeft.svg";
import arrowRigth from "../../../Assets/icons/arrowRigth.svg";
import styled from "styled-components";

const StyledCarouselHand = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  .back_image {
    position: relative;
    width: 250px;
    img {
      max-width: 300px;
      min-height: 250px;
    }
  }
  .buttons {
    width: 140px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: absolute;
    margin-top: 160px;
    margin-left: -20px;

    .container_butt {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
    img {
      width: 40px;
      cursor: pointer;
    }
    h1 {
      margin-top: 23px;
      font-family: var(--font-Base);
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 110%;
      color: #1d2b4f;
    }
  }
  .steps_description {
    width: 200px;
    margin-left: 120px;
    height: 100%;
    margin-top: 20px;
    h1 {
      margin-bottom: 10px;
      font-family: var(--font-Base);
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 110%;
      color: #1d2b4f;
    }
    p {
      text-align: justify;
      font-family: var(--font-Base);
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 120%;
      /* or 19px */

      /* Gris */

      color: #8892ad;
    }
  }
  @media (min-width: 995px) {
    margin-top: 40px;
    .back_image {
      width: 400px;
      img {
        max-width: 450px;
        min-height: 280px;
      }
    }
    .buttons {
      margin-left: -60px;
      margin-top: 180px;
    }
    .steps_description {
      width: 350px;
      height: 200px;
      margin-left: 100px;
      height: 100%;
      height: 150px;
      margin-top: 20px;
      h1{
        font-size: 32px;
      }
    }
  }
`;
export default function CarouselHand() {
  const [id, setId] = useState(0);
  const nextId = () => {
    setId((id) => {
      if (id < contentArray.length - 1) {
        id += 1;
      } else {
        id = 0;
      }
      return id;
    });
  };

  const prevId = () => {
    setId((id) => {
      if (id !== 0) {
        id -= 1;
      } else {
        id = contentArray.length - 1;
      }
      return id;
    });
  };

  useEffect(() => {
    nextId(id);
  });

  useEffect(() => {
    prevId(id);
  });

  return (
    <StyledCarouselHand>
      <div className="back_image">
        <img src={contentArray[id].imagen} alt="test_image" />
      </div>
      <div className="buttons">
        <div className="container_butt">
          <img onClick={prevId} src={arrowLeft} alt="img_button" />
          <img onClick={nextId} src={arrowRigth} alt="img_button" />
        </div>
        <h1>{contentArray[id].step}</h1>
      </div>
      <div className="steps_description">
        <h1>{contentArray[id].title}</h1>
        <p>{contentArray[id].description}</p>
      </div>
    </StyledCarouselHand>
  );
}

const contentArray = [

  {
    imagen: require("./images_carousel/step_2.png"),
    step: "Paso 1",
    title: "Comienza tus objetivos",
    description:
      "Cumple tus metas realizando misiones como empezar un nuevo curso, unirte a nuestro directorio ginecológico, adquiriendo algunos  dispositivos y sistemas intrauterinos, entre otras acciones.",
  },
  {
    imagen: require("./images_carousel/step_3.png"),
    step: "Paso 2",
    title: "Acumula y canjea",
    description:"Acumula puntos y elige hasta qué nivel quieres llegar, para poder canjear tus puntos en tus próximas compras."
  },
  {
    imagen: require("./images_carousel/step_4.png"),
    step: "Paso 3",
    title: "Invita",
    description:
      "No olvides invitar a tus colegas a ser parte del plan de lealtad de Smart Points con Bayer.",
  },
];
