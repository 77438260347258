import React from 'react';
import styled from 'styled-components';
import BackBlue from '../../../Assets/Images/BackBluePointsMobile.png';
import BackHeader from '../../../Assets/Images/card_header.png';

const CardHeader = styled.div`
  background-image: url(${BackHeader});
  height: 30rem;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 995px) {
    height: 14.5rem;
  }
`;
const CardContent = styled.div`
  background-image: url(${BackBlue});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  background-position: bottom;
  padding: 3.5rem 1.7rem;
  color: var(--white);
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  line-height: 1.5rem;
  min-width: 14.3rem;

  h1 {
    font: 700 1.9rem/1.6rem var(--font-Base-Bold);
    line-height: 1.9rem;
  }
  a {
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-color: var(--white);
    color: var(--white);
    border-radius: 10rem;
    font: normal 1.4rem/1rem var(--font-Base);
    padding: 1.2rem 2.4rem;
    text-align: center;
    text-decoration: none;
    display: block;
    border-style: solid;
    cursor: pointer;
    border-width: 0.2rem;
    &:hover {
      background-color: var(--white);
      color: var(--azul-oscuro);
    }
  }
`;

const CardLink = ({ backgroundHeader, title, description, link, linkText }) => {
  return (
    <React.Fragment>
      <CardHeader backgroundHeader={backgroundHeader} />
      <CardContent>
        <h1>{title}</h1>
        <p>{description}</p>
        <a href={link} target='_blank' rel='noreferrer'>
          {linkText}
        </a>
      </CardContent>
    </React.Fragment>
  );
};

export default CardLink;
