import React from 'react';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import './styles.css';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import IcnPhone from '../../../../Assets/icons/icn_phone.svg';
import IcnMail from '../../../../Assets/icons/icn_mail.svg';
import Checkbox from "@mui/material/Checkbox";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));
const TableItem = ({ deliveryData , onClick, selected, ariaChecked, labelId}) => {

  return (
    <StyledTableRow
      hover
      role="checkbox"
      aria-checked={ariaChecked}
      selected={selected}
      tabIndex={-1}
      onClick={onClick}
      key={deliveryData.id}>
      <TableCell  align='center'>
        <Checkbox
          color="primary"
          checked={selected}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCell>
      <StyledTableCell align='center'>{deliveryData?.attributes?.name ?? ''}</StyledTableCell>
      <StyledTableCell align='center'>{deliveryData?.attributes?.distributor?.data?.attributes?.name  ?? ''}</StyledTableCell>
      <StyledTableCell align='center'>{deliveryData?.attributes?.address_contact?.data?.attributes.name ?? ''}</StyledTableCell>
      <StyledTableCell>
        <div className='cell-contact'>
          <span className='data-contact'>
            <img src={IcnPhone} alt='phone icon' />
            {deliveryData?.attributes?.phone ?? ''}
          </span>
          <span className='data-contact'>
            <img src={IcnMail} alt='phone icon' />
            {deliveryData?.attributes?.email ?? ''}
          </span>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
};
export default TableItem;
