import styled from "styled-components";
import {Wrapper} from "../../ui/Layout/Wrapper";

import {CircularProgress} from "@mui/material";
import TableDistributor from "../../ui/Tables/TableDistributor/TableDistributor";
import qs from "qs";
import {useQuery} from "@tanstack/react-query";
import {getDistributors} from "../../../services/distributors";

const WrapperUpdateDelivery = styled(Wrapper)`
  justify-content: flex-start;
  text-align: center;

  .update-delivery__info {
    margin-bottom: 1.3rem;
  }

  .update-delivery__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.3rem;
  }`;
const UpdateDelivery = () => {

  const query = qs.stringify({
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { isLoading,data: distributors } = useQuery({
    queryKey: ['distributors'],
    queryFn: () => getDistributors({ queryStr: query }),
  });

  if (isLoading) {
    return <CircularProgress />
  }

  return (
        <WrapperUpdateDelivery>
            <div className="update-delivery__info">
                <h2>Elige a tu nuevo distribudor</h2>
                <p>Al seleccionar un nuevo distribuidor, se confirmará en un lapso de 1 semana del cambio</p>
            </div>
          <div className='contact-delivery__table'>
             <TableDistributor
               distributors={distributors}
             />
          </div>
        </WrapperUpdateDelivery>
    );
};

export default UpdateDelivery;
