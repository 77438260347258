import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import routes from '../helpers/routes';
import { useSelector } from 'react-redux';

const PrivateRoutes = ({ hasRole: role, ...rest }) => {
  const { user } = useSelector(state => state.auth);

  if (role && user?.role !== role) return <Navigate to={routes.dashboard} replace />;

  return <Outlet {...rest} />;
};

export default PrivateRoutes;
