import React from 'react';
import styled from 'styled-components';
import {
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { levels } from '../../../helpers/levels';
import { useSelector } from 'react-redux';

const CardLevelContainer = styled.div`
  background-color: var(--white);
  box-shadow: 0 0.4rem 6rem rgba(154, 188, 196, 0.27);
  border-radius: 8px;
  color: var(--negro-claro);
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  justify-content: center;
  align-items: center;
  margin-right: 3.8rem;
  margin-left: 3.8rem;
  min-width: 33.6rem;
  img {
    margin-bottom: 4rem;
  }
  
  h1 {
    font: 500 1.8rem/1.9rem var(--font-Base);
    text-align: center;
    margin-bottom: 1.3rem;
    color: var(--azul-oscuro);
  }

  p {
    text-align: center;
    color: #8892ad;
    padding-bottom: 4rem;
    margin-right: 3rem;
    margin-left: 3rem;
    margin-top: 2rem;
  }
`;

const theme = createTheme({
  palette: {
    primary: {
      main: '#02BCFF;',
    },
    secondary: {
      main: '#1D2B4F;',
    },
    tertiary: {
      main: '#7783A3;',
    },
  },
  text: {
    secondary: '#8892AD',
  },
  typography: {
    fontFamily: ['CircularStdFont', 'sans-serif'].join(','),
  },
});

const CardLevel = () => {
  const { user, level } = useSelector((state) => state.auth);

  const url = `https://smartpointsback-dev.bahia360.mx${
    level.iconActive ? level.iconActive.url : ''
  }`;
  const percentage = (partialValue, totalValue) => {
    return Math.round((100 * partialValue) / totalValue + 1);
  };
  const calculateLevelName = (level) => {
    return levels[level].name;
  };
  const calculateNextLevel = (ceil, actualPoints) => {
    return ceil + 1 - actualPoints;
  };

  return (
    <ThemeProvider theme={theme}>
      <CardLevelContainer>
        <img src={url} alt='level' />

        <h1>Estás en el {level.name}</h1>

        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            size='9rem'
            variant='determinate'
            value={percentage(user.totalPoints, level.roundCeil)}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                color: 'secondary.main',
                fontSize: '2.4rem',
                fontWeight: '500',
                lineHeight: '2.6rem',
                fontFamily: 'CircularStdFont',
              }}
            >
              {user.totalPoints}
            </Box>
            <Box
              sx={{
                color: 'tertiary.main',
                fontSize: '1.2rem',
                fontWeight: '450',
                lineHeight: '1.3rem',
                fontFamily: 'CircularStdFont',
              }}
            >
              / {level.roundCeil - user.totalPoints}
            </Box>
          </Box>
        </Box>
        <p>
          Alcanza el nivel {calculateLevelName(level.numberLevel)} sumando{' '}
          {level.roundCeil - user.totalPoints} puntos más. ¡Tú puedes!
        </p>
      </CardLevelContainer>
    </ThemeProvider>
  );
};

export default CardLevel;
