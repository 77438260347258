import React from 'react';

const icn_distribuidor = () => {
  return (
    <svg
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M17.96 7.88401L14.6844 2.06649C14.2219 1.23541 13.3545 0.740723 12.4296 0.740723H5.8787C5.42098 0.742032 4.97167 0.867069 4.57593 1.10327C4.18019 1.33946 3.85198 1.6785 3.6243 2.08627L0.34902 7.9038C0.12162 8.3102 0.00195312 8.77073 0.00195312 9.23945C0.00195312 9.70817 0.12162 10.1687 0.34902 10.5751L3.6243 16.3926C3.85198 16.8004 4.18019 17.1394 4.57593 17.3756C4.97167 17.6118 5.42098 17.7369 5.8787 17.7382H12.43C12.8877 17.7369 13.337 17.6119 13.7328 17.3757C14.1285 17.1395 14.4567 16.8005 14.6844 16.3926L17.96 10.5751C18.1872 10.165 18.3067 9.70136 18.3067 9.22956C18.3067 8.75776 18.1872 8.29407 17.96 7.88401ZM9.15433 4.48056C9.55829 4.48056 9.95317 4.60357 10.2891 4.83404C10.6249 5.06452 10.8867 5.3921 11.0413 5.77536C11.1959 6.15863 11.2363 6.58036 11.1575 6.98723C11.0787 7.3941 10.8842 7.76784 10.5986 8.06117C10.3129 8.35451 9.94899 8.55428 9.55279 8.63521C9.15659 8.71614 8.74593 8.6746 8.37272 8.51585C7.99951 8.3571 7.68052 8.08825 7.4561 7.74333C7.23167 7.3984 7.11188 6.99287 7.11188 6.57803C7.11024 6.30212 7.16194 6.02861 7.264 5.77337C7.36606 5.51813 7.51645 5.28623 7.70644 5.09113C7.89642 4.89602 8.12223 4.74159 8.37078 4.63678C8.61932 4.53197 8.88565 4.47887 9.15433 4.48056ZM12.507 13.0881C12.5071 13.1375 12.4976 13.1864 12.4793 13.2321C12.4609 13.2777 12.4339 13.3191 12.3999 13.3541C12.3659 13.389 12.3255 13.4167 12.2811 13.4356C12.2366 13.4544 12.189 13.4641 12.1409 13.4641H6.14846C6.10037 13.4641 6.05275 13.4544 6.00831 13.4356C5.96387 13.4167 5.92349 13.389 5.88949 13.3541C5.85549 13.3191 5.82852 13.2777 5.81014 13.2321C5.79175 13.1864 5.78232 13.1375 5.78236 13.0881V12.8309C5.78236 11.9177 6.13559 11.042 6.76434 10.3963C7.3931 9.75061 8.24587 9.38786 9.13506 9.38786C10.0243 9.38786 10.877 9.75061 11.5058 10.3963C12.1345 11.042 12.4878 11.9177 12.4878 12.8309V13.0881H12.507Z' />
    </svg>
  );
};

export default icn_distribuidor;
