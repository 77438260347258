import React from 'react';
import styled from 'styled-components';
import { Modal } from '@mui/material';

import Duda from '../../../Assets/icons/icn_duda.svg';

const Box = styled.div`
  align-items: stretch;
  background: var(--white);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  max-width: 38rem;
  padding: 4.8rem;
  width: 100%;
  text-align: center;
  button {
    width: 100%;
  }
  img {
    align-self: center;
    width: 6.5rem;
  }
  p {
    font-size: 1.4rem;

  }
  span{
    width: 100%;
    text-align: center;
  }
`;

const CustomModal = ({ children }) => {
  return (
    <Modal
      open
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <Box>
        <img src={Duda} alt='icon duda' />
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
