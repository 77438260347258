import { Field, Form, Formik } from 'formik';
import FormikControl from '../ui/Forms/FormikControl';
import { StyledInlineErrorMessage } from './styles';
import AnimatedButton from '../ui/Buttons/AnimatedButton';
import { ProfessionalSchema } from './ProfessionalSchema';
import { useSelector } from 'react-redux';

const ProfessionalData = (props) => {
  const { error, loading } = useSelector((state) => state.auth);

  return (
    <Formik
      initialValues={props.data}
      onSubmit={(values, { resetForm }) => {
        resetForm();
        props.handleSubmit(values);
      }}
      validationSchema={ProfessionalSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <div className='form-item'>
            <FormikControl
              control='input'
              type='text'
              label='Cédula profesional'
              name='licenseNumber'
              placeholder='Escribe tu cédula profesional'
            />

            {errors.licenseNumber && touched.licenseNumber ? (
              <StyledInlineErrorMessage>
                {errors.licenseNumber}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>

          <div className='form-item form-flex'>
            <Field name='isProfessional' type='checkbox' />
            <label
              htmlFor='isProfessional'
              className='is-radio-button is-center mb-0'
            >
              Confirmo que soy un Profesional de la Salud en México.
            </label>
          </div>

          <div className='form-item form-flex'>
            <Field name='isOkSubscribeNewsLater' type='checkbox' />
            <label
              htmlFor='isOkSubscribeNewsLater'
              className='is-radio-button is-center mb-0'
            >
              Acepto recibir información de Smart Points, sus productos, eventos
              y servicios.
            </label>
          </div>

          <div className='form-item form-flex'>
            <Field name='isOkUsePersonalData' type='checkbox' />
            <label
              htmlFor='isOkUsePersonalData'
              className='is-radio-button is-center mb-0'
            >
              Autorizo el uso de mis datos personales para los fines descritos
              en el aviso de privacidad.
            </label>
          </div>
          <div className='form-item'>
            {errors.isProfessional && touched.isProfessional ? (
              <StyledInlineErrorMessage>
                {errors.isProfessional}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}

            {errors.isOkSubscribeNewsLater && touched.isOkSubscribeNewsLater ? (
              <StyledInlineErrorMessage>
                {errors.isOkSubscribeNewsLater}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}

            {errors.isOkUsePersonalData && touched.isOkUsePersonalData ? (
              <StyledInlineErrorMessage>
                {errors.isOkUsePersonalData}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>
          <div className='form__action-buttons form-flex'>
            <AnimatedButton color='dark-blue' onClick={props.prevStep}>
              Regresar
            </AnimatedButton>
            <AnimatedButton
              color={loading && 'disabled'}
              type='submit'
              disabled={loading}
            >
              Siguiente
            </AnimatedButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfessionalData;
