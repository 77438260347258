import {
  START_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  START_LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  START_UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  START_RECOVERY_PASSWORD,
  RECOVERY_PASSWORD_SUCCESS,
  RECOVERY_PASSWORD_FAIL,
  UPDATE_ERROR,
  START_RESTORE_PASSWORD,
  RESTORE_PASSWORD_FAIL,
  RESTORE_PASSWORD_SUCCESS,
  DISPATCH_ERROR, START_VALIDATE, VALIDATE_SUCCESS, VALIDATE_FAILED
} from '../types/authTypes';
import {
  GET_ACCOUNT_INFO_FAILED,
  GET_ACCOUNT_INFO_SUCCESS,
  START_GET_ACCOUNT_INFO,
} from '../types/accountTypes';
import {START_UPDATE_DISTRIBUTOR, UPDATE_DISTRIBUTOR_FAIL, UPDATE_DISTRIBUTOR_SUCCESS} from "../types/distributorTypes";

const initState = {
  token: JSON.parse(sessionStorage.getItem('token')) || null,
  user: JSON.parse(sessionStorage.getItem('user')) || null,
  level: JSON.parse(sessionStorage.getItem('level')) || null,
  loading: false,
  error: false,
  forgotPassword: false,
  errorMessage: '',
  statusRequest: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case START_LOGIN:
    case START_REGISTER:
    case START_GET_ACCOUNT_INFO:
    case START_LOGOUT:
    case START_UPDATE:
    case START_RECOVERY_PASSWORD:
    case START_RESTORE_PASSWORD:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        statusRequest: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.jwt,
        user: {
          id: action.payload.id,
          username: action.payload.username,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          role: action.payload.role.name,
          contact_distributor: action.payload.contacts_distributor,
          avatarUrl: action.payload.avatarUrl,
          avatarId : action.payload.avatarId,
          distributor : action.payload.distributor ?? {}
        },
        level: { ...action.payload.level },
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        statusRequest: action.payload,
      };

    case GET_ACCOUNT_INFO_FAILED:
    case LOGOUT_FAIL:
    case RESTORE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload?.error,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case LOGIN_FAIL:
    case REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
      };
    case GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          licenseNumber: action.payload.licenseNumber,
          totalPoints: action.payload.totalPoints,
          username: action.payload.username,
        },
        level: action.payload.level,
      };
    case LOGOUT_SUCCESS:
      return {
        loading: false,
        token: null,
        user: null,
        level: null,
        error: '',
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          username: action.payload.username,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          avatarUrl : action.payload.avatarUrl,
          distributor: action.payload.distributor
        },
      };
    case RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPassword: action.payload,
      };
    case RECOVERY_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        error: false,
        errorMessage: '',
      };
    case RESTORE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        statusRequest: action.payload,
      };
    case START_UPDATE_DISTRIBUTOR:
      return {
        ...state,
        loading: true
      }
    case UPDATE_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        user:{
          ...state.user,
          distributor: {
            ...action.payload
          }

        }
      }
    case UPDATE_DISTRIBUTOR_FAIL:
      return {
        ...state,
        loading: false
      }
    case  DISPATCH_ERROR:
      return {
        ...state,
        loading: false,
        error : true,
        errorMessage : "Cédula no valida"
      }
    case START_VALIDATE:
      return {
        ...state,
        loading: true
      }
    case VALIDATE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case VALIDATE_FAILED:
      return {
        ...state,
        loading: false,
        error:  "Hubo un error"
      }
    default:
      return state;
  }
};
export default authReducer;
