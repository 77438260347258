import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as More } from "../../Assets/icons/btn_more.svg";
import { ReactComponent as Less } from "../../Assets/icons/btn_less.svg";
import QuestionControl from "./QuestionControl";

const StyledQuestion = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  min-height: 57px;
  height: 100%;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 590px;

  .icon {
    margin-left: 1.5rem;
    cursor: pointer;
  }
  .cuestion_open {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .question_close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
    svg {
      width: 2.7rem;
      margin-left: 1.5rem;
      cursor: pointer;
    }
    p {
      font-family: var(--font-Base);
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 110%;
      color: var(--negro-claro);
      width: 90%;
    }
  }
  .question_open {
    .align_title_svg {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      svg {
        margin-left: 15px;
        cursor: pointer;
      }
    }
    h1 {
      color: var(--azul-claro);
      padding: 12px 0;
      font-family: var(--font-Base);
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 110%;
    }
    p {
      color: var(--gray-third);
      font-family: var(--font-Base);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      padding-bottom: 12px;
      width: 85%;
      text-align: justify;
    }
  }
`;
export default function QuestionCloseAndOpen(props) {

  const { question, control, ...rest } = props;

  const [icon, setIcon] = useState(false);
  return (
    <StyledQuestion>
      {icon ? (
        <div className="question_open">
          <div className="align_title_svg">
            <h1>{question}</h1>
            <div onClick={() => setIcon(!icon)}>
              <Less />
            </div>
          </div>
          <QuestionControl control={control} {...rest}/>
        </div>
      ) : (
        <div className="question_close">
          <p>{question}</p>{" "}
          <div onClick={() => setIcon(!icon)}>
            <More />
          </div>
        </div>
      )}
    </StyledQuestion>
  );
}
