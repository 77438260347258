import React, { useState } from 'react';
import { Button, Container, Modal } from '@mui/material';
import styled from 'styled-components';

import Conditions from '../Modal/Conditions';
import PrivacyNotice from '../Modal/PrivacyNotice';

import bayerCross from '../../../Assets/Images/BayerCross.png';

const FooterWrapper = styled.div`
  align-items: center;
  background: var(--azul-oscuro);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  grid-area: footer;
  padding: 2.4rem;
  width: 100%;
  @media (max-width: 991px) {
    padding: 1.6rem;
  }
`;

const Divider = styled.div`
  position: relative;
  padding: 2rem 0;
  width: 100%;
  @media (min-width: 992px) {
    width: 50%;
  }
  img {
    background: var(--azul-oscuro);
    left: calc(50% - 3.2rem);
    padding: 0 1.6rem;
    position: absolute;
    top: 0;
    width: 7rem;
    @media (min-width: 992px) {
      left: calc(50% - 1.8rem);
    }
  }
`;

const Text = styled.p`
  align-items: center;
  color: white;
  display: flex;
  font-size: 1.2rem;
  justify-content: space-around;
  text-align: center;
  @media (min-width: 992px) {
    width: 45%;
  }
  button {
    color: white;
    font: 450 1.2rem var(--font-Base);
    text-transform: none;
  }
`;

export default function Footer({ shortView = false }) {
  const [showConditions, setShowConditions] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const closeModal = () => {
    showConditions ? setShowConditions(false) : setShowPrivacy(false);
  };

  return (
    <FooterWrapper>
      {!shortView && (
        <>
          <Text>
            Información solo para México. Para reportar algún efecto adverso,
            problema de calidad o cualquier otra situación relacionada con alguno
            de nuestros productos, favor de comunicarse a Servicio al Cliente al
            800 BAYER SC (2293 772) o enviar un mail a:
            servicio.alcliente@bayer.com o farmacovigilancia.mx@bayer.com
          </Text>
        </>

      )}
      <Text>NE: 183300202C0440 / PP-MIR-MX-0212-1</Text>

      <Divider>
        <hr />
        <img src={bayerCross} alt='Bayer' />
      </Divider>
      <Text style={{ maxWidth: 425 }}>
        <Button onClick={() => setShowConditions(true)}>
          Condiciones generales de uso
        </Button>{' '}
        / <Button>Imprimir</Button> /
        <Button onClick={() => setShowPrivacy(true)}>
          Declaración de privacidad
        </Button>
      </Text>
      <Text>
        © {new Date().getFullYear()} Bayer. Todos los derechos reservados.
      </Text>
      <Modal
        disableEnforceFocus
        open={showConditions || showPrivacy}
        onClose={closeModal}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '1rem',
            maxHeight: '90vh',
            p: 4,
            overflow: 'scroll',
          }}
        >
          {showConditions && <Conditions />}
          {showPrivacy && <PrivacyNotice />}
        </Container>
      </Modal>
    </FooterWrapper>
  );
}
