import React from 'react'
import {InputTextArea} from "./Form";

function Textarea (props) {
    const { label, name, placeholder,...rest } = props
    return (
        <>
            <label htmlFor={name}>{label}</label>
            <InputTextArea
                   component="textarea"                   id={name}
                   name={name}
                   placeholder={placeholder}
                   rows="6"
                   cols="50"
                   type="textarea"
                   {...rest} />
        </>
    )
}

export default Textarea