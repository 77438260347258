import {
    START_LEVELS,
    LEVELS_SUCCESS,
    LEVELS_FAIL
} from "../types/levelTypes";

const initState ={
    loading : false,
    error: null,
    levelsArray: []
};

const levelsReducer = ( state = initState, action ) => {
    switch ( action.type ){
        case START_LEVELS:
            return {
                ...state,
                loading: true
            }
        case LEVELS_SUCCESS:
            return {
                ...state,
                levelsArray : action.payload,
                loading: false
            }
        case LEVELS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export  default levelsReducer;
