import React, { useEffect } from "react";
import styled from "styled-components";

import HomeCards from "../features/Home/HomeCards";
import BackBlueHomeClass from "../features/ui/Hero/BackBlueHomeClass";

import backHome from "../Assets/Images/back_top_home.png";
import backHomeDesktop from "../Assets/Images/back_top_home_desktop.png";

import Header from "../features/ui/navbar/Header";
import Carousel from "../features/ui/Carousel/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../redux/actions";
import { CircularProgress } from "@mui/material";

const StyledHome = styled.div`
  .what_is_new {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .what_is_new h1 {
    font: 700 1.8rem/1.9rem var(--font-Base-Bold);
    color: var(--green-primary);
  }

  .what_is_new h2 {
    padding-left: 32rem;
    padding-right: 32rem;
  }

  .swiper-content h1 {
    font-family: var(--font-Base-Bold);
    font-size: 1.8rem !important;
    color: var(--white) !important;
    text-align: start;
  }

  .what_is_new {
    text-align: center;
    padding: 8rem 2.6rem;
  }

  .what_is_new h1 {
    font: 700 1.6rem/1.7rem var(--font-Base-Bold);
    color: var(--green-primary);

  }

  .what_is_new h2 {
    font: 500 2.4rem/2.6rem var(--font-Base-Bold);
    color: var(--azul-oscuro);
    padding: 2.6rem 2.5rem;
  }
  .swiper-slide{
    position: relative;
  }
  @media (min-width: 992px) {
    .swiper-content h1{
      margin-bottom: 7rem;
    }
    .swiper-slide:hover{
      .swiper-content h1{
        margin-bottom: 1rem;
      }
      .swiper-content{
        transform: translate(0%, -10%);
      }
    }
    .swiper-content{
      transform: translate(0%, 60%);
      transition: all .5s;
    }
    .swiper-content:hover{
      transform: translate(0%, -80%);
    }
    \

  }

  .swiper-content {
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: start;
    position: absolute;
  }
  .swiper-content a {
    font: 450 1.6rem/1.9rem var(--font-Base);
    color: var(--white);
    padding-right: 1rem;
    text-decoration: none;
  }

  @keyframes moveInBottom {
    0% {
      opacity: 0;
      transform: translateY(3rem);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  .swiper-animated {
    animation: moveInBottom 0.5s ease-out 0.75s;
    animation-fill-mode: backwards;
  }

  .swiper-content__bar {
    width: 100%;
    height: 0.4rem;
    z-index: 3;
    background: var(--white);
    border-radius: 1.6rem;
    margin-top: 2.2rem;
    margin-bottom: 2.2rem;
  }
`;

const greenText = "EMPIEZA A ACUMULAR PUNTOS";
const blackText = "¿Ya eres parte de smart Points? ";
const thirdText =
  "Regístrate y únete e la comunidad Bayer.";

export default function Home() {
  const { loading, whatIsNew } = useSelector((state) => state.home);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(home.getWhatIsNew());
  }, [dispatch]);

  return (
    <>
      <Header
        heroBackground={backHome}
        heroBackgroundDesktop={backHomeDesktop}
        heroTitle="En Bayer valoramos tu preferencia"
      />
      <StyledHome>
        <HomeCards />
        <BackBlueHomeClass
          greenText={greenText}
          blackText={blackText}
          thirdText={thirdText}
        />
        <div className="what_is_new">
          <h1>¿QUÉ HAY DE NUEVO?</h1>
          <h2>Actualizate en lo más nuevo de la ginecología con BeScience</h2>
          {loading ? <CircularProgress /> : <Carousel data={whatIsNew} />}
        </div>
      </StyledHome>
    </>
  );
}
