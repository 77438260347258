import styled from "styled-components";

import { ReactComponent as Padlock } from "../../../Assets/icons/icn_candado.svg";

const StyledCardLevel = styled.div`
  border-radius: .8rem;
  background-color: var(--white);
  box-shadow: 0 .4rem 6rem rgba(154, 188, 196, 0.27);
  width: 31.7rem;
  font: 450 1.5rem/1.4rem var(--font-Base);
  margin-top: 7rem;

  .card_header {
    background-color: var(--azul-oscuro);
    color: var(--azul-claro);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem 1.7rem;
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem;
    max-height: 4.3rem;
  }
  .card_content {
    padding: 2.4rem 2.1rem;
  }
   .card_content ul {
    padding-left: 2.4rem;
  }

  .card_content ul li{
    font: 450 1.5rem/1.4rem var(--font-Base);
    color : var(--azul-oscuro);
    padding-bottom: 1rem;
    text-align: left;

  }
  .image-container{
    width: 10rem;
    margin-top: -6rem;
  }
  .image-container img{
    max-width: 100%;
    max-height: 100%;
  }
  .is-inactive {
    background-color: var(--gray-text);
    color: var(--white);
  }
`;
const CardLevel = ({data, actualLevel})=> {
    const {
        numberLevel = 0 ,
        roundCeil,
        descriptionOne,
        descriptionTwo,
        iconActive,
        iconInactive
    } = data.attributes;



    const handleImage = (iconInactive,iconActive,numberLevel , actualLevel) => {

      let url= ''
      if (actualLevel=== numberLevel){

        url=`https://smartpointsback-dev.bahia360.mx${iconActive.data.attributes.url}`
      }else if(actualLevel < numberLevel){
        console.log(iconInactive.data.attributes.url);
        url=`${iconInactive.data.attributes.url}`
      }
      //falta la condicion para mayor

      return <img src={`${url}`} alt="icon_level" />

    }
    return (
        <StyledCardLevel>
            <div className={ `card_header ${actualLevel < numberLevel ? "is-inactive" : ""}`}>
                <p>Nivel { numberLevel }</p>
                <div className="image-container">
                    {
                      handleImage(iconInactive,iconActive,numberLevel , actualLevel)
                    }
                </div>
                <div className="your_level locked">
                     { actualLevel < numberLevel ? <> <Padlock /> {roundCeil + 1} </> : "Tu nivel"}
                </div>
            </div>
            <div className="card_content">
                <ul>
                    <li>{descriptionOne}</li>
                    <li>{descriptionTwo}</li>
                </ul>
            </div>
        </StyledCardLevel>
    );
}
export default CardLevel
