import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Check } from '../../Assets/icons/icn_check.svg';
import AnimatedButton from '../ui/Buttons/AnimatedButton';
import routes from '../../helpers/routes';

const StyledSendMail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-base);
  padding: 0 55px;
  margin-top: 40px;

  a {
    text-decoration: none;
  }

  h1 {
    font: 400 2.4rem/2.6rem var(--font-Base-Bold);
    text-align: center;
    color: var(--azul-claro);
    margin-bottom: 15px;
    margin-top: 33px;
  }

  h2 {
    font-weight: 450;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: var(--azul-oscuro);
    margin-bottom: 24px;

    a {
      color: var(--azul-claro);
      cursor: pointer;
      text-decoration: underline;
    }
  }

  button {
    margin-top: 34px;
    margin-bottom: 57px;
  }

  @media (min-width: 995px) {
    margin-top: 165px;
    padding: 0 10px;
    margin-left: 100px;
    button {
      margin-bottom: 212px;
    }
  }
  @media (min-width: 1200px) {
    margin-left: 0px;
    padding: 0 60px;
  }
`;

const RestoreSuccess = () => {
  return (
    <StyledSendMail>
      <Check />
      <h1>Cambia tu contraseña</h1>
      <h2>Cambio de contraseña exitoso.</h2>

      <Link to={routes.logIn}>
        <AnimatedButton color='secondary'>Iniciar sesión</AnimatedButton>
      </Link>
    </StyledSendMail>
  );
};

export default RestoreSuccess;
