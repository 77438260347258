import FormProfile from "./FormProfile";
import {Wrapper} from "../../ui/Layout/Wrapper";

const Profile = () => {

    return (
        <Wrapper>
            <h2>Mi perfil</h2>
            <p>Actualiza tus datos si es necesario</p>
            <FormProfile/>
        </Wrapper>
    );
};

export default Profile;