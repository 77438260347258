import {
  START_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  START_LOGOUT,
  LOGOUT_SUCCESS,
  START_UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  START_RECOVERY_PASSWORD,
  RECOVERY_PASSWORD_SUCCESS,
  RECOVERY_PASSWORD_FAIL,
  START_RESTORE_PASSWORD,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_FAIL,
} from '../types/authTypes';
import SmartPointsApiBase from '../../helpers/SmartPointsBaseApi';

import qs from 'qs';

import {
  GET_ACCOUNT_INFO_FAILED,
  GET_ACCOUNT_INFO_SUCCESS,
  START_GET_ACCOUNT_INFO,
} from '../types/accountTypes';


import {
  START_UPDATE_DISTRIBUTOR, UPDATE_DISTRIBUTOR_FAIL,
  UPDATE_DISTRIBUTOR_SUCCESS
} from '../types/distributorTypes';
import routes from '../../helpers/routes';

const actions = {
  registerAction: (userInfo, navigate) => {
    return async dispatch => {
      dispatch({
        type: START_REGISTER,
      });

      try {
        const userData = {
          email: userInfo.email,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          password: userInfo.password,
          username: `${userInfo.firstName}  ${userInfo.lastName}`,
          licenseNumber: userInfo.licenseNumber,
          level: {
            //Hay que verificar que el id sea el mismo que en la bd
            id: 6,
          },
        };
        //mando a llamar a mi servicio
        await SmartPointsApiBase.postElementWithoutToken(`auth/local/register`, userData);

        dispatch({
          type: REGISTER_SUCCESS,
          payload: true,
        });
        setTimeout(() => {
          navigate(routes.logIn);
        }, 3000);
      } catch (e) {
        dispatch({
          type: REGISTER_FAILED,
          payload: { error: true, errorMessage: e.message },
        });
      }
    };
  },
  logInAction: userData => {
    return async dispatch => {
      dispatch({
        type: START_LOGIN,
      });
      const userObj = {
        identifier: userData.email,
        password: userData.password,
      };
      try {
        const { jwt } = await SmartPointsApiBase.postElementWithoutToken(`/auth/local`, userObj);

        const query = qs.stringify(
          {
            populate: '*',
          },
          {
            encodeValuesOnly: true, // prettify URL
          },
        );
        const infoUser = await SmartPointsApiBase.getElement(`/users/me?${query}`, jwt);

        let avatarId = (infoUser.avatarId === 'noSet') ? 'noSet' : infoUser.avatarId;
        let avatarUrl = infoUser.avatarUrl === 'noSet' ? 'noSet':  infoUser.avatarUrl;

        if(avatarUrl !== 'noSet') {
          const profilePic = await SmartPointsApiBase.getElement(`/upload/files/${parseInt(avatarId)}`,jwt);
          avatarUrl= profilePic?.formats?.thumbnail?.url;
        }
        let userInfo = {
          jwt,
          ...infoUser,
          avatarUrl :avatarUrl,
          avatarId : avatarId,
          distributor : infoUser.user_distributor ?? {}

        };

        dispatch({
          type: LOGIN_SUCCESS,
          payload: userInfo,
        });

        let user = {
          id: userInfo.id,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
          level: userInfo.level,
          role: userInfo.role.name,
          username: userInfo.username,
          avatarUrl : avatarUrl,
          avatarId : avatarId,
          distributor : infoUser.user_distributor ?? {},
          //urlLevel :
        };


        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('token', JSON.stringify(userInfo.jwt));
        sessionStorage.setItem('level', JSON.stringify(userInfo.level));

      } catch (e) {
        dispatch({
          type: LOGIN_FAIL,
          payload: { error: true, errorMessage: e.message },
        });
      }
    };
  },
  getDataAccountAction: userId => {
    return async dispatch => {
      dispatch({
        type: START_GET_ACCOUNT_INFO,
      });

      try {
        const data = await SmartPointsApiBase.getElement(
          `/users/${userId}?populate[0]=role&populate[1]=level&populate[2]=level.iconActive&populate[3]=level.iconLevel`,
        );

        const { level, role, licenseNumber, totalPoints, username } = data;

        dispatch({
          type: GET_ACCOUNT_INFO_SUCCESS,
          payload: { level, role, licenseNumber, totalPoints, username },
        });

        sessionStorage.setItem('roles', role.name);
      } catch (e) {
        console.log('error', e);
        dispatch({
          type: GET_ACCOUNT_INFO_FAILED,
        });
      }
    };
  },
  logOutAction: () => {
    return dispatch => {
      dispatch({
        type: START_LOGOUT,
      });

      sessionStorage.clear();

      dispatch({
        type: LOGOUT_SUCCESS,
      });
    };
  },
  updateAction: (userId, userInfo) => {
    return async dispatch => {
      dispatch({
        type: START_UPDATE,
      });

      try {
        const {email,firstName, lastName, profilePic} =userInfo
        let fileId = userInfo.avatarId;
        let fileUrl = userInfo.avatarUrl;
        if(fileUrl !== 'noSet'){
          //Se elimina el recurso que se tiene
          await SmartPointsApiBase.deleteItemWithToken('/upload/files',parseInt(fileId))
        }
        // 1. Subir a /uploads
        let formData = new FormData();
        formData.append('files', profilePic);
        const response = await SmartPointsApiBase.postElementWithToken('/upload', formData);
        fileId = response[0].id;
        fileUrl = response[0].formats.thumbnail.url;

        let formUpdate ={
          email: email,
          firstName : firstName,
          lastName : lastName,
          username:`${firstName} ${lastName}`,
          avatarId: fileId.toString(),
          avatarUrl: fileUrl
        }
        //2.Actualizar la data con el nuevo id
        const dataUpdate = await SmartPointsApiBase.updateByIdWithToken('/users', userId, formUpdate);

        dispatch({
          type: UPDATE_SUCCESS,
          payload: dataUpdate,
        });
      } catch (e) {
        dispatch({
          type: UPDATE_FAIL,
        });

      }
    };
  },
  forgotPassword: userEmail => {
    return async dispatch => {
      dispatch({
        type: START_RECOVERY_PASSWORD,
      });

      try {
        const { ok } = await SmartPointsApiBase.postElementWithoutToken(
          `auth/forgot-password`,
          userEmail,
        );
        dispatch({
          type: RECOVERY_PASSWORD_SUCCESS,
          payload: ok,
        });
        return true;
      } catch (e) {
        dispatch({
          type: RECOVERY_PASSWORD_FAIL,
          payload: true,
        });
        throw new Error('Sucedio un error');
      }
    };
  },
  resetPassword: restoreData => {
    return async dispatch => {
      dispatch({
        type: START_RESTORE_PASSWORD,
      });
      try {
        await SmartPointsApiBase.postElementWithoutToken(`auth/reset-password`, restoreData);
        dispatch({
          type: RESTORE_PASSWORD_SUCCESS,
          payload: true,
        });
        return true;
      } catch (e) {
        dispatch({
          type: RESTORE_PASSWORD_FAIL,
          payload: { error: true },
        });
        throw new Error('Hubo un error');
      }
    };
  },
  updateDistributor: ({formData, userId}) =>{

    return async dispatch =>{
      dispatch({
        type: START_UPDATE_DISTRIBUTOR,
      });
      try {
        //Crea el nuevo registro en la tabla user_distributor
        const {data} = await SmartPointsApiBase.postElementWithToken('/user-distributors', formData);

        //Actualiza el campo  user_distributor del usuario
        let formUpdate = {
              user_distributor: data?.id
        };

        dispatch({
          type: UPDATE_DISTRIBUTOR_SUCCESS,
          payload :{ distributorId : data.id, status: data.attributes.status}
        });

        await SmartPointsApiBase.updateByIdWithToken('/users', userId, formUpdate);

        setTimeout(async () => {
          //navigate(routes.logIn);
        }, 2000);

      }catch (e) {
        dispatch({
          type: UPDATE_DISTRIBUTOR_FAIL,
        });
      }
    }

  },
  handleClose: () => {
    return dispatch => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: false,
      });
    };
  },
  validateCedula : (cedula) =>{
    return async dispatch => {
      dispatch({
        type: 'START_VALIDATE'
      });
      try {
        const data = await SmartPointsApiBase.postElementWithoutToken('/validate-cedula', {
          "data":{"idCedula": cedula}
        });

        const items = data.items;


        const found = items.find(element => element.idCedula === cedula);

        dispatch({
          type: 'VALIDATE_SUCCESS'
        });
        return found
      }catch (e) {
        dispatch({
          type: 'VALIDATE_FAILED'
        });
      }
    };
  },
  dispatchError : () =>{
    return async dispatch => {
      dispatch({
        type: 'DISPATCH_ERROR'
      });

    }

  }
};
export default actions;
