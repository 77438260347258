import React from 'react';
import styled from "styled-components";

const StyleLayoutTitle = styled.div`
  display: flex;
  padding-inline-start: 2.5rem;
  padding-inline-end: 2.5rem;
  padding-block-start: 4.8rem;
  padding-block-end: 14rem;

  @media(min-width: 995px) {
    
    padding-inline-start: 15rem;
    padding-block-start: 12rem;
  }
`;
const LayoutContent = styled.div`
  order: 1;
  max-inline-size: 22rem;
  @media (min-width: 992px) {
      max-inline-size: 40rem;
  }
  
`;
const LayoutTitle = ({children}) => {
    return (
        <StyleLayoutTitle>
            <LayoutContent>
                {children}
            </LayoutContent>
        </StyleLayoutTitle>

    );
};

export default LayoutTitle;