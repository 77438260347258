const routes = {
  about: '/acerca-de',
  contactUs: '/contactUs',
  frequentQuestions: '/preguntas-frecuentes',
  register: '/registro',
  forgotPassword: '/recuperar-cuenta',
  recoveryPassword: '/restablecer/:code',
  dashboard: '/dashboard',
  privacyNotice: '/aviso-de-privacidad',
  dashboardRoutes: {
    missions: 'misiones',
    levels: 'niveles',
    contactDelivery: 'contactar-distribuidor',
    frequentQuestions: 'preguntas-frecuentes',
    whatIs: 'que-es-smart-points',
    smartUsers: 'smart-usuarios',
    smartUserProfile: 'smart-usuarios/:id',
    smartUserProfileUpdate: 'smart-usuarios/:id/actualizar',
    smartUserProfileHistory: 'smart-usuarios/:id/historial',
    contactInfo: 'informacion-contacto',
    uploadInstructions: 'instrucciones-carga',
    reports: 'reportes',
    adminProfile:'perfil-administrador',
    profile: 'mi-perfil',
    updateDelivery: 'cambio-distribuidor',
    contact: 'contacto',
    recoveryPass: 'recuperar-contraseña',
  },
  logIn: '/inicio-sesion',
  main: '/',
};

export default routes;
