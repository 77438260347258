import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Divider } from '@mui/material';

import Button from '../ui/Buttons/Button';
import CardSettings from '../ui/Cards/CardSettings';

import { WrapperSmartUsers } from './StyledComponents';

import DudasBack from '../../Assets/Images/dudas_back.png';
import Email from '../../Assets/icons/icn_mail.svg';
import Pregunta from '../../Assets/icons/icn_preguntas.svg'
import routes from '../../helpers/routes';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 6.4rem;
  justify-self: center;
  margin: 0 auto;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 1.6rem;
  }
`;

const HalfWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const MailWrapper = styled.div`
  align-items: center;
  background: var(--white);
  border-radius: 15px 15px 3px 3px;
  display: flex;
  flex-direction: column;
  height: 11rem;
  overflow: hidden;
  width: 32.8rem;
`;

const MailHeader = styled.div`
  align-items: center;
  background: var(--azul-oscuro);
  color: var(--white);
  display: flex;
  gap: 2.4rem;
  height: 2.8rem;
  justify-content: center;
  max-width: 32.8rem;
  width: 100%;
`;

const MailContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  gap: 0.6rem;
`;

const SmartUsers = () => {
  const navigate = useNavigate();

  return (
    <WrapperSmartUsers>
      <ContentWrapper>
        <HalfWrapper>
          <TextWrapper>
            <h2>¿Necesitas ayuda?</h2>
            <p>Manda un mensaje al siguiente correo</p>
          </TextWrapper>
          <MailWrapper>
            <MailHeader>Contacto</MailHeader>
            <MailContent>
              <img src={Email} alt='icon mail' />
              smart_points_mx@bayer.com
            </MailContent>
          </MailWrapper>
        </HalfWrapper>
        <Divider orientation='vertical' flexItem />
        <HalfWrapper>
          <CardSettings
            icon={Pregunta}
            describeText='¿Tienes dudas de nuestra plataforma?'
            background={DudasBack}
          />
          <Button
            color='secondary'
            onClick={() => navigate(`/dashboard/${routes.dashboardRoutes.uploadInstructions}`)}
          >
            Ver más
          </Button>
        </HalfWrapper>
      </ContentWrapper>
    </WrapperSmartUsers>
  );
};

export default SmartUsers;
