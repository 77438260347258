import React from 'react';
import {Grid, Paper, Table, TableBody, TableContainer} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import IcnPhone from "../../../Assets/icons/icn_phone.svg";
import IcnMail from "../../../Assets/icons/icn_mail.svg";
import {styled} from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const TableDistributo = ({data}) => {

  const {name, phone, email,distributor, location} = data;
  return (
    <Grid container rowSpacing={2}  justifyContent="center" alignItems="center">
      <Grid item xs={12} lg={12} xl={12}>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 700}} aria-label='distributors table'>
            <TableHead>
              <TableRow>
                <StyledTableCell className='blue' align='center'>
                  Nombre
                </StyledTableCell>
                <StyledTableCell className='blue' align='center'>
                  Distribuidor
                </StyledTableCell>
                <StyledTableCell className='blue' align='center'>
                  CD/Estado
                </StyledTableCell>
                <StyledTableCell className='blue' align='center'>
                  Contacto
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={distributor?.id}>

                <StyledTableCell align='center'>{ name }</StyledTableCell>
                <StyledTableCell
                  align='center'>{distributor}</StyledTableCell>
                <StyledTableCell
                  align='center'>{ location}</StyledTableCell>
                <StyledTableCell>
                  <div className='cell-contact'>
                    <span className='data-contact'>
                      <img src={IcnPhone} alt='phone icon'/>
                      { phone }
                    </span>
                    <span className='data-contact'>
                      <img src={IcnMail} alt='phone icon'/>
                      { email }
                    </span>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>

      </Grid>
    </Grid>

  );
};

export default TableDistributo;
