import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../../../helpers/routes';
import SmartPointLogo from '../../../Assets/icons/smart_point_logo.svg';
import icon_close from '../../../Assets/icons/close.svg';
import icon_menu from '../../../Assets/icons/icon_menu.svg';
import LineGray from '../../../Assets/icons/line_gray.svg';
import AnimatedButton from '../Buttons/AnimatedButton';
import styled from 'styled-components';
import { routesArray } from '../../../helpers/routesArray';

const HeaderContainer = styled.div`
  ${({ fixed }) => fixed && 'position: fixed'};
  ${({ fixed }) => fixed && 'background: white'};

  ${({ fixed }) => fixed && 'box-shadow: 0px 4px 90px rgba(39, 61, 82, 0.33)'};
  top: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .open {
    display: inline-block;
  }
  .top_close_menu{
    background-color: var(--white) !important;
    display: flex;
    justify-content: space-between;
    height: 100px;
    padding: 0 2.5rem;
    img{
      width: 9.2rem;
    }
    button{
      background-color: transparent;
      border: none;
      img{
        width: 2rem;
      }
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    block-size: 8rem;
    padding-inline-end: 4rem;
    padding-inline-start: 4rem;

    .top_close_menu{
      display: none;
    }
    .testtt{
      display: flex;
      flex-direction: row-reverse;
      margin-top: 0px !important;
      gap: 2rem;
    }
    .mobile{
      display: none;
    }
    .desktop{
      display: block;
      margin-top: 1rem;
    }
  }
`;

const NavBarBrand = styled.img`
  src: ${props => props.src};
  width: 11rem;
  opacity: 1;
  @media (min-width: 992px) {
    width: 20rem;
    padding-left: 4rem;
  }
`;

const NavMenu = styled.nav`
  background-color: var(--dark-blue);
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9;
  text-align: left;

  .menu {
    display: flex;
    list-style: none;
    flex-direction: column;
    padding-inline-end: 2rem;
    padding-inline-start: 2rem;
    padding-block-start: 4rem;
  }
  .menu li a {
    display: inline-block;
    color: var(--white);
    text-decoration: none;
    font: 450 3.2rem/5rem 'CircularBook';
  }
  .menu li .active {
    color: var(--azul-claro);
  }
  .icon {
    display: none;
  }
  @media (min-width: 992px) {
    background-color: inherit;
    display: block;
    height: auto;
    position: relative;
    width: auto;
    .menu {
      gap: 2.8rem;
      align-items: center;
      flex-direction: row;
      padding-block-start: 0;
    }
    .icon {
      display: inline-block;
    }
    .menu li a {
      color: var(--dark-blue);
      font: normal 700 1.4rem/110% 'CircularBook';
    }
  }
`;
const NavBarIcon = styled.img`
  src: ${props => props.src};
  @media (min-width: 992px) {
    height: 2.7rem;
  }
`;

const NavBarMenuMobile = styled.nav`
  .navigation_button {
    cursor: pointer;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: 3rem;
  padding-inline-start: 3rem;
  padding-block-end: 4rem;
  padding-block-start: 4rem;
`;

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => setFixed(window.scrollY > 1));
    }
    return () => window.removeEventListener('scroll', () => setFixed(window.scrollY > 1));
  }, []);

  return (
    <HeaderContainer fixed={fixed}>
      <NavWrapper>
        <NavLink to={routes.main}>
          <NavBarBrand src={SmartPointLogo} />
        </NavLink>
        {open ? (
          <NavBarMenuMobile>
            <img src={icon_close} width='28px' alt='icon_close' onClick={() => setOpen(!open)} />
          </NavBarMenuMobile>
        ) : (
          <NavBarMenuMobile>
            <img src={icon_menu} width='28px' alt='icon_menu' onClick={() => setOpen(!open)} />
          </NavBarMenuMobile>
        )}
      </NavWrapper>
      <div className='navigation__background'>&nbsp;</div>
      <NavMenu className={`${open ? 'open' : ''}`}>
      <div className='top_close_menu'>
            <img src={SmartPointLogo} alt="logo_smart" />
            <button onClick={() => setOpen(!open)}><img src={icon_close} alt="close_icon"/></button>
          </div>
        <ul className='menu'>
          {routesArray.map(route => {
            return (
              <li key={route.route}>
                <NavLink
                  to={route.route}
                  className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                >
                  {route.name}
                </NavLink>
              </li>
            );
          })}
          <li className='icon'>
            <NavBarIcon src={LineGray} alt='navbar' />
          </li>
          <div className='testtt' style={{marginTop: "5rem"}}>
          <li >
            <NavLink to={routes.register}
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            >
              <AnimatedButton>Registrarme</AnimatedButton>
            </NavLink>
          </li>
          <li className='mobile'>
            <NavLink
              to={routes.logIn}
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            >
              <AnimatedButton color="dark-blue">Inicia Sesión</AnimatedButton>
            </NavLink>
          </li>
          <li className='icon desktop'>
            <NavLink
              to='/inicio-sesion'
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            >
              Iniciar sesión
            </NavLink>
          </li>
          </div>
        </ul>
      </NavMenu>
    </HeaderContainer>
  );
};

export default Navbar;
