import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Grid } from '@mui/material';

import Button from '../../ui/Buttons/Button.js';
import CustomModal from '../../ui/Modal/CustomModal';
import FormikControl from '../../ui/Forms/FormikControl';
import { SectionTitle } from '../StyledComponents';
import { StyledInlineErrorMessage } from '../../Register/styles';
import { UserSchema } from './UserSchema';
import { auth } from '../../../redux/actions';

import IcnEdit from '../../../Assets/icons/icn_perfil.svg';
import IcnPicture from "../../../Assets/icons/icn_foto.svg";
import PreviewImage from "../../ui/PreviewImage";

const FormProfileWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5.6rem;
  justify-self: center;
  margin: 5.6rem auto 0 auto;
  @media (min-width: 992px) {
    width: 60%;
  }
`;
const IconWrapper = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: var(--azul-claro);
  cursor: pointer;
  display: flex;
  font: 450 1.5rem/1.4rem var(--azul-oscuro);
  gap: 0.8rem;
  justify-content: center;
  text-decoration: underline var(--azul-claro);
  width: 100%;
`;

const PhotoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  img.avatar {
    border-radius: 1.6rem;
    height: 12.8rem;
    object-fit: cover;
    object-position: top;
    width: 12.8rem;
  }
`;

const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1.6rem;
  justify-content: center;
`;

const LinksWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  hr {
    width: 60%;
  }
`;
const LabelWrapper = styled.label`
  color: var(--azul-claro);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .gap-1{
    gap: 1rem;
  }
  .icon-img{
    width: 2.5rem;

  }
  .mt-1{
    margin-top: 1rem;
  }
  .form-center {
    display: flex;
    justify-content: center;
    text-decoration: underline;
  }

`;

const FormProfile = () => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = data => {
    dispatch(auth.updateAction(user.id, data));
  };

  const imgUrl = user.avatarUrl === 'noSet'?
    'https://smartpointsback-dev.bahia360.mx/uploads/Inactiva_18d88a0159.png' :
    user.avatarUrl ;

  return (
    <FormProfileWrapper>
      {showModal && (
        <CustomModal>
          <SectionTitle>
            <span>¿Estas seguro que quieres eliminar tu cuenta?</span>
          </SectionTitle>
          <p>
            Ten en cuenta que la eliminación de la cuenta es definitiva. No habrá manera de
            restaurar la cuenta.
          </p>
          <Button color='outline-secondary' onClick={() => {}}>
            Eliminar mi cuenta
          </Button>
          <Button color='outline-tertiary' onClick={() => setShowModal(false)}>
            No eliminar mi cuenta
          </Button>
        </CustomModal>
      )}

      <Formik initialValues={user} validationSchema={UserSchema} onSubmit={handleSubmit}>
        {({ errors, touched, values,setFieldValue }) => (
          <Form className='form-profile'>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                { isEdit ?
                <LabelWrapper htmlFor="profilePic" className="custom-file-upload form-profile-pic">

                  {
                    values.profilePic ? <PreviewImage file={values.profilePic}/> : <img src={imgUrl} alt="profile pic"/>
                  }

                  <span className="form-center gap-1 align-center mt-1">
                    <img src={IcnPicture} className="icon-img" alt="phone icon"/>
                      Cambiar fotografia
                  </span>

                  <input
                    style={{ display: "none" }}
                    id="profilePic"
                    name="profilePic"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={e => setFieldValue("profilePic",e.target.files[0])}
                  />

                </LabelWrapper>
                  :  <PhotoWrapper>

                    <img src={imgUrl}  alt='' />

                  </PhotoWrapper>}
                {errors.lastName && touched.lastName ? (
                  <StyledInlineErrorMessage>{errors.lastName}</StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormikControl
                  control='input'
                  type='text'
                  label='Nombre(s)'
                  name='firstName'
                  placeholder='Escribe tu nombre'
                  disabled={!isEdit}
                />
                {errors.firstName && touched.firstName ? (
                  <StyledInlineErrorMessage>{errors.firstName}</StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormikControl
                  control='input'
                  type='text'
                  label='Apellidos'
                  name='lastName'
                  placeholder='Escribe tus apellidos'
                  disabled={!isEdit}
                />
                {errors.lastName && touched.lastName ? (
                  <StyledInlineErrorMessage>{errors.lastName}</StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormikControl
                  control='input'
                  type='email'
                  label='Correo electrónico'
                  name='email'
                  placeholder='Escribe tu correo electrónico'
                  disabled={!isEdit}
                />
                {errors.email && touched.email ? (
                  <StyledInlineErrorMessage>{errors.email}</StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormikControl
                  control='input'
                  type='password'
                  label='Contraseña'
                  name='password'
                  placeholder='• • • • • • • •'
                  disabled
                />
                {errors.password && touched.password ? (
                  <StyledInlineErrorMessage>{errors.password}</StyledInlineErrorMessage>
                ) : (
                  <StyledInlineErrorMessage />
                )}
              </Grid>
            </Grid>
            {isEdit ? (
              <ButtonsWrapper>
                <Button color='outline-secondary' onClick={() => setIsEdit(false)}>
                  Regresar al perfil
                </Button>
                <Button color='secondary' type='submit'>
                  Guardar cambios
                </Button>
              </ButtonsWrapper>
            ) : (
              <LinksWrapper>
                <IconWrapper onClick={() => setIsEdit(true)}>
                  <img src={IcnEdit} alt='' />
                  Editar perfil
                </IconWrapper>
                <hr />
                <Button color='ghost' onClick={() => setShowModal(true)}>
                  Eliminar cuenta
                </Button>
              </LinksWrapper>
            )}
          </Form>
        )}
      </Formik>
    </FormProfileWrapper>
  );
};

export default FormProfile;
