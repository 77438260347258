import React from 'react';
import {InputText, StyledLabel} from "./Form";



const Input = (props) => {
    const { label, name,placeholder, ...rest } = props

    return (
        <>
            <StyledLabel htmlFor={name}>{label}</StyledLabel>
            <InputText
                id={name}
                name={name}
                placeholder={placeholder}
                {...rest} />

        </>
    );
};

export default Input;