import * as Yup from "yup";

export const UserSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Por favor, introduce tu nombre'),
    lastName: Yup.string()
        .required('Por favor, introduce tus apellidos'),
    email: Yup.string()
        .email('Por favor, introduce una dirección de correo electrónico válida')
        .required('Por favor, introduce tu dirección de correo electrónico'),

});