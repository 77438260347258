import React from 'react';
import { Modal } from '@mui/material';

import Spinner from '../../../Assets/icons/icn_loader.svg';

const Loader = () => {
  return (
    <Modal
      open
      sx={{
        alignItems: 'center',
        color: 'var(--white)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '2.4rem',
        gap: '2.4rem',
        justifyContent: 'center',
      }}
    >
      <React.Fragment>
        <img className='spinner' src={Spinner} alt='icon loader' />
        <p>Cargando...</p>
      </React.Fragment>
    </Modal>
  );
};

export default Loader;
