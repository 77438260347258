import React from 'react';
import { Grid, List, ListItem } from '@mui/material';
import styled from 'styled-components';

const Title = styled.h1`
  color: var(--azul-claro);
  font: 500 1.8rem var(--font-Base-Bold);
  text-align: center;
`;

const Text = styled.div`
  color: var(--azul-oscuro);
  font: ${({ bold }) =>
    bold ? '700 1.4rem var(--font-Base-Bold)' : '450 1.4rem var(--font-Base)'};
  a {
    color: var(--azul-claro);
  }
`;

const BoldText = styled.b`
  font: 700 1.4rem var(--font-Base-Bold);
`;

const PrivacyNotice = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs='12'>
        <Title>Aviso de privacidad</Title>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Para una compañía innovadora y de carácter global como{' '}
          <BoldText>Bayer de México, S.A. de C.V.</BoldText>, con domicilio en
          Blvd. Miguel de Cervantes Saavedra 259, Col. Granada. México D.F.,
          C.P. 11520, (en adelante “Bayer”) el tratamiento legítimo, controlado
          e informado de sus datos personales es de vital importancia para
          alcanzar los objetivos corporativos a través de todas las áreas del
          negocio y reiterar nuestro compromiso con la privacidad y su derecho a
          la autodeterminación informativa.{' '}
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          El presente Aviso de Privacidad tiene como objeto informarle sobre el
          tratamiento que se le dará a sus datos personales cuando los mismos
          son recabados, utilizados, almacenados y/o transferidos por Bayer, lo
          cual se lleva a cabo de conformidad con las disposiciones de la Ley
          Federal de Protección de Datos Personales en Posesión de los
          Particulares (en adelante la “Ley”).
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>A. Información sobre privacidad de datos</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Con este documento, Bayer de México S.A. de C.V. (en lo sucesivo
          “Bayer”) (en adelante, "nosotros", "nuestro" o "nosotros") desea
          brindarle información sobre cómo manejamos sus datos personales y
          obtener su consentimiento para propósitos específicos como se describe
          a continuación y de conformidad con las disposiciones de la Ley
          Federal de Protección de Datos Personales en Posesión de los
          Particulares y su reglamento (en lo sucesivo “la Ley”).
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>1. Tipo de datos personales y su finalidad</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          A menos que se indique lo contrario en las siguientes secciones, la
          base legal para el manejo de sus datos personales resulta del hecho de
          que para promover nuestros productos y brindarle nuestros servicios
          mediante este sitio web, es necesario la recopilación de sus datos
          según se describirá más adelante.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>Mantener la gestión de la relación con el cliente</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Mantenemos un sistema de gestión de relaciones con los clientes donde
          almacenamos datos personales sobre usted:
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          • Información de contacto: esta categoría de datos personales abarca,
          por ejemplo, su nombre, dirección, número de teléfono / fax / celular,
          correo electrónico u otra información de contacto en línea. Recibimos
          información de contacto de nuestros contactos comerciales individuales
          con usted o de proveedores de datos comerciales, así como de fuentes
          disponibles públicamente, p. Ej. sitios web. Usamos su información de
          contacto para:
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          <List disablePadding sx={{ listStyle: 'circle', pl: 2 }}>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Cumplir con obligaciones derivadas de una relación jurídica que
              con usted se tenga o se llegase a tener,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Hacer cumplir y/o ejecutar un contrato,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Fines estadísticos y científicos,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Intercambiar información científica necesaria para atender un
              determinado padecimiento o emergencia de salud,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Hacer reportes a las autoridades sanitarias relativas a posibles
              experiencias adversas,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Que se lleven a cabo inspecciones por parte de las autoridades,
              auditorías internas, externas y por autoridades,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Invitarlo a participar en programas de lealtad, eventos de
              educación médica continua,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Formar una base de datos de profesionales de la salud,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Contactarlo y hacerle llegar comunicaciones con fines
              promocionales y educativos,
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Realizar análisis a fin de determinar la eficacia de nuestros
              programas, envío o entrega de material de apoyo para pacientes,
              entrega de promociones y premios.
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Registrarlo para recibir información de interés de bayer o un tema
              en particular,
            </ListItem>
          </List>
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          • Analizar las relaciones con los clientes: en caso de que compre
          nuestros productos o haga uso de nuestros servicios, almacenamos su
          historial de clientes. Utilizamos esta información para poder
          comprender mejor sus intereses e informarle sobre nuestros productos y
          servicios que podrían interesarle. Además, documentamos y analizamos
          nuestros contactos individuales con usted para mejorar continuamente
          su experiencia con nuestros productos y servicios. Esto puede incluir
          los temas tratados con usted y su interés en ellos. En este contexto,
          también podemos recibir datos sobre sus intereses de proveedores de
          datos comerciales.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          • Mejorar la colaboración con el cliente: para gestionar nuestra
          colaboración con usted de una manera eficaz y para respaldar el
          desarrollo exitoso del mercado de nuestros productos y servicios,
          tratamos de comprender mejor en qué temas científicos / médicos está
          particularmente interesado o involucrado. La categoría de datos
          personales se basa en nuestros propios datos o experiencias o la
          proporcionan agencias de investigación de mercado o proveedores de
          datos comerciales y abarca, por ejemplo, información sobre su área de
          especialización médica, sus actividades científicas como publicación
          de artículos científicos, participación en proyectos de investigación
          y congresos profesionales.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>B. Realizar estudios de investigación de mercado</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Trabajamos junto con agencias de investigación de mercado totalmente
          independientes que, en nuestro nombre, realizan estudios de
          investigación de mercado a nivel mundial, centrados en nuestros
          intereses y productos científicos. Podemos compartir su información de
          contacto con estas agencias de investigación de mercado para realizar
          estudios de investigación de mercado que sean específicos para
          nuestros clientes.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>C. Entregar comunicaciones médicas / de marketing</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Podemos utilizar su información de contacto para comunicarnos con
          usted a través de llamadas telefónicas, correo directo, correo
          electrónico u otra comunicación electrónica (por ejemplo, fax, chats
          en sitios web, mensajes de texto, mensajes de mensajería o detalles
          remotos / incluidos servicios de atención al cliente a pedido) con el
          fin de ofrecer comunicaciones médicas / de marketing. Podríamos
          utilizar comunicaciones de marketing / médicas para proporcionar
          información sobre servicios, productos o eventos relacionados con su
          interés médico o para recopilar comentarios sobre nuestros productos y
          servicios. Esto puede incluir mostrarle anuncios personalizados
          adaptados a sus intereses en nuestro o en otros sitios web y
          aplicaciones. Sin embargo, las comunicaciones médicas / de marketing
          por correo electrónico u otras comunicaciones electrónicas
          ("Comunicaciones médicas / de marketing electrónico"), así como a
          través de llamadas telefónicas, están sujetas a que usted brinde su
          consentimiento.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>
          D. Analizar su uso de nuestras comunicaciones médicas / de marketing
          electrónico
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Con el fin de personalizar nuestro Marketing electrónico /
          Comunicaciones médicas para satisfacer sus necesidades y preferencias
          y sujeto a su consentimiento, analizamos su uso de nuestro Marketing
          electrónico / Comunicaciones médicas, por ejemplo, si abrió y cómo
          utilizó nuestro Marketing electrónico / Comunicaciones.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>2. Transferencia de datos personales</Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>2.1 Procesamiento encargado</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Utilizamos contratistas de servicios especializados que nos ayudan a
          brindar nuestros servicios. Dichos contratistas de servicios son
          cuidadosamente seleccionados y monitoreados regularmente por nosotros.
          Con base en los respectivos acuerdos de procesamiento de datos, solo
          procesarán datos personales según nuestras instrucciones y
          estrictamente de acuerdo con nuestras directivas.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>2.2 Terceros</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Transferimos o damos acceso a sus datos personales a terceros en las
          siguientes circunstancias:
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          A. Podemos compartir su información de contacto con agencias de
          investigación de mercado totalmente independientes.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          B. Podemos transferir sus datos personales mencionados anteriormente a
          otras filiales de Bayer para los fines especificados anteriormente.
          Sin embargo, otras filiales de Bayer solo le enviarán directamente las
          comunicaciones de marketing correspondientes por correo electrónico u
          otras comunicaciones electrónicas, si proporciona su consentimiento a
          continuación.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          C. También podemos transferir sus datos personales mencionados
          anteriormente a otros socios que deban participar en la gestión de un
          servicio o comunicación hacia usted, p. Ej. hoteles o agencias de
          viajes.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>3. Plazo de conservación de los datos personales</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Conservamos sus datos personales durante el tiempo que sea necesario
          para la relación continua con el cliente, así como durante otros 10
          años después de que haya terminado activamente la relación con el
          cliente o no haya habido actividad durante 2 años, lo que ocurra
          primero. A partir de entonces, anonimizamos los datos para que ya no
          se relacionen con usted. Podemos retener sus datos personales durante
          un período de retención diferente, cuando la ley disponga lo
          contrario.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>4. Información sobre sus derechos</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          El titular de datos personales, es decir, la persona física a la cual
          se refiere la información personal y/o personal sensible, puede
          ejercitar los derechos de acceso, rectificación, cancelación y
          oposición (derechos “ARCO”) respecto a sus datos personales.
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          En general, los siguientes derechos están disponibles para usted de
          acuerdo con las leyes de privacidad de datos aplicables:
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          <List disablePadding sx={{ listStyle: 'decimal', pl: 2 }}>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Derecho de información sobre sus datos personales almacenados por
              nosotros;
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Derecho a solicitar la corrección, supresión o tratamiento
              restringido de sus datos personales;
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Derecho a oponerse a un procesamiento por motivos de nuestro
              propio interés legítimo, interés público o elaboración de
              perfiles, a menos que podamos demostrar que existen razones
              imperiosas y justificadas que reemplazan sus intereses, derechos y
              libertad, o que dicho procesamiento se realiza con fines de la
              afirmación, ejercicio o defensa de reclamaciones legales;
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              Derecho a presentar una queja ante una autoridad de protección de
              datos;
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              En cualquier momento, con efecto futuro, puede retirar su
              consentimiento para la recopilación, el procesamiento y el uso de
              sus datos personales. Si desea retirar su consentimiento, puede
              hacerlo a través del sitio web en el que ha creado su perfil
              dentro de la configuración de su perfil, o comunicándose con
              nosotros a través del correo electrónico:{' '}
              <a href='mailto:data.protectionmx@bayer.com'>
                data.protectionmx@bayer.com
              </a>
              . Además, cada comunicación médica / de marketing electrónico que
              le enviamos incluye una opción para que pueda retirar fácilmente
              su consentimiento haciendo clic en un enlace para darse de baja.
            </ListItem>
          </List>
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          El ejercicio de los derechos previstos en la Ley se podrá llevar a
          cabo a través del envío de su solicitud, en los términos establecidos
          por la Ley y su Reglamento, o el envío del formato sugerido al
          Departamento de Protección de Datos de Bayer ubicado en Blvd. Miguel
          de Cervantes Saavedra 259, Col. Granada. México D.F. CP. 11520 o al
          correo electrónico:{' '}
          <a href='mailto:data.protectionmx@bayer.com'>
            data.protectionmx@bayer.com
          </a>
          .
        </Text>
      </Grid>
      <Grid item xs='12'>
        <Text bold>5. Modificación de la declaración de privacidad</Text>
      </Grid>
      <Grid item xs='12'>
        <Text>
          Es posible que actualicemos nuestra Declaración de privacidad de vez
          en cuando. Las actualizaciones de nuestra Declaración de privacidad se
          publicarán en nuestro sitio web. Cualquier enmienda entrará en
          vigencia tras su publicación en nuestro sitio web
          www.bayer.com/es/mexico-home . Por lo tanto, le recomendamos que
          visite el sitio con regularidad para mantenerse informado sobre
          posibles actualizaciones.
        </Text>
      </Grid>
    </Grid>
  );
};

export default PrivacyNotice;
