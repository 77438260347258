import {useState} from 'react';
import {FormTitle, PageWrapper} from "./styles";

import PersonalData from "./PersonalData";
import ProfessionalData from "./ProfessionalData";
import {useDispatch, useSelector} from "react-redux";

import {auth} from "../../redux/actions";
import {Alert, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";

const initState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    licenseNumber: '',
    isProfessional: false,
    isOkSubscribeNewsLater: false,
    isOkUsePersonalData: false
}


const FormRegister = () => {

    const {error, errorMessage, statusRequest, loading} = useSelector(state => state.auth)

    const [data, setData] = useState(initState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit =  async (data) => {
     //valida la cedula
      const cedulaValida = await dispatch(auth.validateCedula(data.licenseNumber))
      if (!cedulaValida){
        dispatch(auth.dispatchError())

      }else{

        dispatch(auth.registerAction(data, navigate))

      }


    }

    const [currentStep, setCurrentStep] = useState(0);

    const handleNextStep = newData => {
        setData(prevState => ({...prevState, ...newData}))
        setCurrentStep(prevState => prevState + 1);

    }

    const handlePrevStep = newData => {
        setData(prevState => ({...prevState, ...newData}))
        setCurrentStep(prevState => prevState - 1);
    }

    const steps = [
        {
            title: "Información personal",
            element: <PersonalData
                nextStep={handleNextStep}
                data={data}
            />
        },
        {
            title: "Información profesional",
            element: <ProfessionalData
                prevStep={handlePrevStep}
                data={data}
                handleSubmit={handleSubmit}
            />
        }

    ];

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        dispatch(auth.handleClose())
    };

    return (
        <PageWrapper>
            <FormTitle className="is-center">{steps[currentStep].title}</FormTitle>
            {steps[currentStep].element}
            {
                error ?
                    <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                        <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                    : ""
            }
            {
                statusRequest ?
                    <Snackbar open={statusRequest} autoHideDuration={3000} onClose={handleClose}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                        <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                            Usuario creado exitosamente
                        </Alert>
                    </Snackbar>
                    : ""
            }
        </PageWrapper>
    );
};


export default FormRegister;
