import styled from "styled-components";
import {Field} from "formik";


const   InputForm = styled(Field)`
  color: var(--gray-secondary);
  background-color: ${({ gray }) => gray  ? "#ECF3FE" : "#FFFFFF" };
  padding: 1.6rem 1.8rem;
  border: 1px solid var(--white);
  font: 400 1.4rem/1.5rem  var(--font-Base);
  width: 100%;

  &:focus,
  &:active {
    border: 1px solid var(--azul-claro);
    outline: none;
  }
`;

export const Input = styled(Field)`
  color: var(--gray-secondary);
  background-color: var(--white);
  padding: 1.6rem 1.8rem;
  border: 1px solid var(--white);
  border-radius: 10rem;
  font: 400 1.4rem/1.5rem  var(--font-Base);

  width: 100%;

  &:focus,
  &:active {
    border: 1px solid red;
    outline: none;
  }


`;
export const InputTextArea = styled(InputForm)`
  border-radius: 2rem;
  margin-top: 1rem;
  color: var(--azul-oscuro);
`;

export const InputText = styled(InputForm)`
  margin-top: 1rem;
  border-radius: 10rem;
  color: var(--azul-oscuro);
`;
export const StyledInlineErrorMessage = styled.div`
  min-height: 1.3rem;
  color: var(--red-secondary);
  display: block;
  margin-top: .4rem;
  font: 450 1.2rem/1.3rem var(--font-Base);
  margin-left: 1.8rem;
`;
export const StyledLabel = styled.label`
  font: 450 1.4rem/1.5rem var(--font-Base-Bold);
  color: var(--black);
`;
export const CustomSelect = styled.select`
  background-color: var(--white);
  border-radius: 10rem;
  border: none;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1.8rem;
  font: 450 1.5rem/1.4rem var(--font-Base);
  color: var(--azul-oscuro);
  cursor: pointer;
  display: inline-block;
  position: relative;
  &:after {
    color:var(--azul-oscuro)
  }
   & select{
     border: var(--azul-oscuro);
   }
  &:active, &.active {
    background-color: var(--azul-oscuro);
  }
`;

export const DateInput = styled(Field)`
  border: none;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1.2rem;
  color: var(--azul-claro);
  border-top-right-radius:  ${({isRight}) => ( isRight ? "10rem" :  0 )} ;
  border-bottom-right-radius:  ${({isRight}) => ( isRight ? "10rem" :  0 )} ;

`
export const DateContainer = styled.div`
  border-top-left-radius: ${({isRight}) => ( isRight ? "0rem" : "10rem" )} ;
  border-bottom-left-radius: ${({isRight}) => ( isRight ? "0rem" : "10rem" )};

  border-top-right-radius:  ${({isRight}) => ( isRight ? "10rem" :  0 )} ;
  border-bottom-right-radius:  ${({isRight}) => ( isRight ? "10rem" :  0 )} ;

  background-color: var(--white);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 1.8rem;
  label{
    font: 450 1.5rem/1.4rem var(--font-Base);
    color: var(--azul-oscuro);
    margin-left: 1.2rem ;
  }

`
