import React from 'react';
import { Form, Formik } from 'formik';
import FormikControl from '../ui/Forms/FormikControl';
import { StyledInlineErrorMessage } from '../Register/styles';
import AnimatedButton from '../ui/Buttons/AnimatedButton';
import { RestorePasswordSchema } from './RestorePasswordSchema';
import { useSelector } from 'react-redux';

const FormRestorePassword = (props) => {
  const { loading } = useSelector((state) => state.auth);

  return (
    <Formik
      initialValues={props.data}
      validationSchema={RestorePasswordSchema}
      onSubmit={(values, { resetForm }) => {
        resetForm();
        props.handleSubmit(values);
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className='form-item'>
            <FormikControl
              control='input'
              type='password'
              label='Contraseña'
              name='password'
              placeholder='Escribe tu contraseña'
            />

            {errors.password && touched.password ? (
              <StyledInlineErrorMessage>
                {errors.password}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>

          <div className='form-item'>
            <FormikControl
              control='input'
              type='password'
              label='Repite tu contraseña'
              name='passwordConfirmation'
              placeholder='Escribe tu contraseña'
            />

            {errors.passwordConfirmation && touched.passwordConfirmation ? (
              <StyledInlineErrorMessage>
                {errors.passwordConfirmation}
              </StyledInlineErrorMessage>
            ) : (
              <StyledInlineErrorMessage />
            )}
          </div>

          <div className='buttons'>
            {loading ? (
              <AnimatedButton color='secondary' type='submit'>
                Cargando
              </AnimatedButton>
            ) : (
              <AnimatedButton color='secondary' type='submit'>
                Continuar
              </AnimatedButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormRestorePassword;
