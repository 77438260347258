export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes) {
  const { role } = JSON.parse(sessionStorage.getItem('user'));
  let roles = [];
  roles.push(role);

  return routes.filter(({ permission }) => {
    return permission.includes(role);
  });
}

export function handleError(error) {
  const { message } = error.response.data.error;
  switch (message) {
    case 'Email or Username are already taken':
      return {
        message: 'El usuario o el email ya existen',
      };
    case 'Invalid identifier or password':
      return {
        message: 'Usuario o contraseña incorreto',
      };
    default:
      return {
        message: 'Hubo un error',
      };
  }
}

export function formatDate({
  date,
  format = { day: '2-digit', month: 'long', year: 'numeric' },
  locale = 'es-MX',
}) {
  if (!date) return;
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const instance = new Intl.DateTimeFormat(locale, format);
  return instance.format(dateObj);
}
