import QuestionParagraph from "./QuestionParagraph";
import QuestionList from "./QuestionList";
import QuestionBold from "./QuestionBold";
import QuestionLink from "./QuestionLink";

const QuestionControl = (props) => {
  const {control, ...rest} = props
  switch (control){
    case 'text':
      return <QuestionParagraph {...rest}/>
    case 'isList':
      return <QuestionList {...rest}/>
    case 'isBold':
      return <QuestionBold {...rest}/>
    case 'link':
      return <QuestionLink {...rest}/>
    default:
      return null
  }
};

export default QuestionControl;
