import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import qs from 'qs';

import IcnNotifications from '../../../Assets/icons/icn_noti.svg';
import ArrowDown from '../../../Assets/icons/icn_flecha_down.svg';
import ArrowUp from '../../../Assets/icons/icn_arrow_up.svg';

import {
  Avatar,
  Badge,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../redux/actions';
import routes from '../../../helpers/routes';
import { useQuery } from '@tanstack/react-query';
import { getNotifications } from '../../../services/notifications';

const NavbarWrapper = styled.div`
  grid-area: header;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5.4rem;
  color: var(--dark-blue);
  font-family: var(--font-Base);

  .title {
    font: 450 1.4rem/1.5rem var(--font-Base);
    color: var(--negro-claro);
    text-align: center;
  }

  .subTitle {
    font: 500 2.4rem/2.6rem var(--font-Base-Bold);
    color: var(--azul-claro);
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.4rem;
  }
  .account__menu {
    display: none;
  }
  @media (min-width: 992px) {
    max-height: 68px;
    .actual {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .account__menu {
      display: flex;
    }
  }
`;

const MenuItemTitle = styled.div`
  color: var(--azul-claro);
  font-weight: 450;
  font-size: 1.4rem;
  white-space: break-spaces;
  span {
    color: var(--azul-oscuro);
  }
`;

const MenuItemText = styled.div`
  color: var(--gray-text);
  font-weight: 450;
  font-size: 1.2rem;
  margin: 0.4rem 0 0 1.2rem;
  max-width: 26rem;
  white-space: break-spaces;
  a {
    color: var(--green-primary);
  }
`;

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const [first, second] = name.split(' ');

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${first?.[0] || ''}${second?.[0] || ''}`,
  };
}

const NavBarDashboard = () => {
  const { user, level, loading } = useSelector(state => state.auth);

  const url = `https://smartpointsback-dev.bahia360.mx${
    level.iconLevel ? level.iconLevel.url : ''
  }`;

  const [anchorEl, setAnchorEl] = useState(null);

  const [anchorElNotif, setAnchorElNotif] = useState(null);

  const open = Boolean(anchorEl);

  const openNotif = Boolean(anchorElNotif);

  const dispatch = useDispatch();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotif = event => {
    setAnchorElNotif(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setAnchorElNotif(null);
  };

  const handleLogout = () => {
    dispatch(auth.logOutAction());
  };

  const query = qs.stringify(
    {
      filters: {
        user_id: user.id,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  );

  const { data: notifications } = useQuery({
    queryKey: ['notifications'],
    queryFn: () => getNotifications({ queryStr: query }),
  });

  return (
    <NavbarWrapper>
      <div className='points__data wrapper'>
        {user.role === 'USER' && (
          <div className='total'>
            <h2 className='title'>Puntos Smart</h2>
            {loading ? (
              <CircularProgress />
            ) : (
              <h3 className='subTitle'>{user?.totalPoints ?? 0}pts.</h3>
            )}
          </div>
        )}
        {user.role === 'USER' && (
          <div className='actual'>
            <h2 className='title'>Nivel</h2>
            {loading ? <CircularProgress /> : <img src={url} alt='level' />}
          </div>
        )}
      </div>

      <div className='points__person wrapper'>
        <div className='point__notifications' style={{ position: 'relative' }}>
          <IconButton
            id='notifications-button'
            aria-controls={openNotif ? 'notifications-list' : undefined}
            aria-haspopup='true'
            aria-expanded={openNotif ? 'true' : undefined}
            onClick={handleClickNotif}
          >
            <Badge
              badgeContent={notifications?.data?.length}
              color='primary'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <img src={IcnNotifications} alt='icon notifications' />
            </Badge>
          </IconButton>

          <Menu
            id='notifications-list'
            anchorEl={anchorElNotif}
            open={openNotif}
            onClose={handleCloseNotif}
            MenuListProps={{
              'aria-labelledby': 'notifications-button',
            }}
          >
            <MenuList>
              {notifications?.data?.length > 0 ? (
                notifications?.data?.map(({ attributes: { title, content, link = '/' } }, i) => (
                  <React.Fragment key={i}>
                    <MenuItem>
                      <Link to={link} style={{ textDecoration: 'none' }}>
                        <MenuItemTitle>
                          <span>&#9679;</span> {title}
                        </MenuItemTitle>
                        <MenuItemText>{content}</MenuItemText>
                      </Link>
                    </MenuItem>
                    {!(notifications?.data?.length - 1 === i) && <Divider />}
                  </React.Fragment>
                ))
              ) : (
                <MenuItem>
                  <MenuItemTitle>Aún no tienes notificaciones</MenuItemTitle>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </div>

        <div className='account__menu wrapper'>
          {loading ? <CircularProgress /> : <p> {user?.username}</p>}
          {loading ? <CircularProgress /> : <Avatar {...stringAvatar(`${user.username}`)} />}

          <Tooltip title='Account settings'>
            <IconButton
              onClick={handleClick}
              size='small'
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              {open ? (
                <img src={ArrowUp} alt='icon notifications' />
              ) : (
                <img src={ArrowDown} alt='icon notifications' />
              )}
            </IconButton>
          </Tooltip>
        </div>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Link
            to={
              user.role === 'DISTRIBUTOR'
                ? routes.dashboardRoutes.adminProfile
                : routes.dashboardRoutes.profile
            }
            style={{ textDecoration: 'none' }}
          >
            <MenuItem>
              <MenuItemTitle>Ver perfil</MenuItemTitle>
            </MenuItem>
          </Link>
          {user.role === 'DISTRIBUTOR' && (
            <Link to={routes.dashboardRoutes.contactInfo} style={{ textDecoration: 'none' }}>
              <MenuItem>
                <MenuItemTitle>Ayuda</MenuItemTitle>
              </MenuItem>
            </Link>
          )}
          <Divider />
          <MenuItem onClick={handleLogout}>
            <MenuItemTitle style={{ color: 'var(--gray-text)' }}>Cerrar sesión</MenuItemTitle>
          </MenuItem>
        </Menu>
      </div>
    </NavbarWrapper>
  );
};

export default NavBarDashboard;
