import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import qs from 'qs';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Divider, Grid, List, ListItem, ListItemButton, Paper, TextField } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import {
  DateInputWrapper,
  InputsWrapper,
  SectionTitle,
  SelectButton,
  SelectWrapper,
} from '../StyledComponents';

import Button from '../../ui/Buttons/Button';
import CardLink from '../../ui/Cards/CardLink';
import MisionsTable from '../components/MisionsTable';

import Filter from '../../../Assets/icons/icn_active_filtro.svg';
import ArrowDown from '../../../Assets/icons/icn_flecha_down.svg';

import { getBuys } from '../../../services/buys';
import { getMissions } from '../../../services/missions';
import { getPurchaseDetails } from '../../../services/purchase-details';

const PointsHistory = () => {
  const { user } = useSelector(state => state.auth);

  const [toggleFilters, setToggleFilters] = useState(false);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [firstDate, setFirstDate] = useState(dayjs('2021/01/01'));
  const [secondDate, setSecondDate] = useState(dayjs(new Date()));
  const [selectedMission, setSelectedMission] = useState('');

  const cleanFilters = () => {
    setFirstDate(dayjs('2021/01/01'));
    setSecondDate(dayjs(new Date()));
    setSelectedMission('');
  };

  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const buysByUserQuery = useMemo(
    () =>
      qs.stringify(
        {
          populate: '*',
          filters: {
            user: user.id,
            buy_date: {
              $gte: firstDate.format(),
              $lte: secondDate.format(),
            },
          },
        },
        {
          encodeValuesOnly: true,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstDate.format(), secondDate.format()],
  );
  const { data: missions } = useQuery({
    queryKey: ['missions'],
    queryFn: () => getMissions({ queryStr: query }),
  });


  const { data: buysByUser } = useQuery({
    queryKey: ['buysByUser', buysByUserQuery],
    queryFn: () => getBuys({ queryStr: buysByUserQuery }),
  });

  const buysByUserIds = buysByUser?.reduce((acc, { id }) => {
    return [...acc, id];
  }, []);

  const purchaseDetailsQuery = useMemo(
    () =>
      qs.stringify(
        {
          populate: '*',
          filters: {
            purchase_id: {
              id: {
                $in: buysByUserIds,
              },
            },
            product: {
              name: {
                $contains: selectedMission,
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        },
      ),
    [buysByUserIds, selectedMission],
  );

  const { data: purchaseDetails } = useQuery({
    queryKey: ['purchaseDetails', purchaseDetailsQuery],
    queryFn: () => getPurchaseDetails({ queryStr: purchaseDetailsQuery }),
    enabled: buysByUserIds?.length > 0,
  });

  const cleanedPurchaseDetails = purchaseDetails?.reduce((acc, { attributes }) => {
    const {
      product: {
        data: {
          attributes: { name },
        },
      },
      purchase_id: {
        data: {
          attributes: { buy_date },
        },
      },
      ...purchase
    } = attributes;
    return [...acc, { ...purchase, name, buy_date }];
  }, []);

  return (
    <div className='points-history'>
      <Grid container gap={4}>
        <Grid item xs={12} lg={8}>
          <SectionTitle>
            Aquí encontrarás las misiones completadas y los puntos que has logrado acumular:
          </SectionTitle>
        </Grid>
        <Grid item xs={12} lg={8}>
          <InputsWrapper>
            <Button color='secondary' onClick={() => setToggleFilters(!toggleFilters)}>
              <img src={Filter} alt='icon filters' /> Filtros
            </Button>

            {toggleFilters && (
              <React.Fragment>
                <SelectWrapper>
                  <SelectButton onClick={() => setToggleSelect(!toggleSelect)}>
                    Selecciona las misiones a filtrar{' '}
                    <img src={ArrowDown} alt='icon chevron down' />
                  </SelectButton>
                  {toggleSelect && (
                    <Paper
                      sx={{
                        top: 48,
                        position: 'absolute',
                        width: '100%',
                      }}
                    >
                      <List sx={{ p: 0 }}>
                        {missions.map(({ attributes: { name, slug } }, i) => (
                          <React.Fragment key={`${name} list-item`}>
                            <ListItem
                              sx={{
                                backgroundColor:
                                  selectedMission === name ? 'var(--gris-claro)' : 'white',
                              }}
                            >
                              <ListItemButton
                                onClick={() => {
                                  setSelectedMission(slug);
                                  setToggleSelect(false);
                                }}
                              >
                                {name}
                              </ListItemButton>
                            </ListItem>
                            {i !== missions.length - 1 && <Divider />}
                          </React.Fragment>
                        ))}
                      </List>
                    </Paper>
                  )}
                </SelectWrapper>

                <DateInputWrapper>
                  De:
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      closeOnSelect
                      inputFormat='MM/DD/YYYY'
                      maxDate={secondDate}
                      minDate={firstDate}
                      value={firstDate}
                      onChange={newValue => setFirstDate(newValue)}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  a:
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      closeOnSelect
                      inputFormat='MM/DD/YYYY'
                      maxDate={secondDate}
                      minDate={firstDate}
                      value={secondDate}
                      onChange={newValue => setSecondDate(newValue)}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </DateInputWrapper>

                <Button color='tertiary' onClick={cleanFilters}>
                  Limpiar
                </Button>
              </React.Fragment>
            )}
          </InputsWrapper>
        </Grid>

        <Grid item xs={12} lg={8}>
          <MisionsTable data={cleanedPurchaseDetails} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <div className='card-container'>
            <CardLink
              title='¿Quieres conocer lo más nuevo en el área de ginecología?'
              description='Visita BeScience y conoce el contenido exclusivo que te ayudará a ampliar tus conocimientos.'
              link='https://bescience.bayer.com/es'
              linkText='Ir al BeScience'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PointsHistory;
