import {
    START_WHAT_IS_NEW,
    WHAT_IS_NEW_SUCCESS
} from "../types/homeTypes";

import SmartPointsApiBase from "../../helpers/SmartPointsBaseApi";

const actions = {
    getWhatIsNew : () =>{
        return async (dispatch) =>{
            dispatch({
                type : START_WHAT_IS_NEW
            })
            try{
                const {data} = await SmartPointsApiBase.getElementWithoutToken(`/what-is-news?populate=%2A`);
                dispatch({
                    type : WHAT_IS_NEW_SUCCESS,
                    payload: data
                })

            }catch (e) {
                /* TODO
                 - Add actions when dispatch an error */
            }
        }
    }
}
export default actions;
