import {
    MISSIONS_FAIL,
    MISSIONS_SUCCESS,
    START_MISSIONS,
    START_MISSION_BY_USER,
    FAIL_MISSION_BY_USER,
    SUCCESS_MISSION_BY_USER
} from "../types/missionTypes";

import SmartPointsApiBase from "../../helpers/SmartPointsBaseApi";

const actions = {
    getMissionsAction: (token) => {

        return async (dispatch) => {
            dispatch({
                type: START_MISSIONS
            })
            try {
                const {data} = await SmartPointsApiBase.getElement('/missions?populate=%2A');

                dispatch({
                    type: MISSIONS_SUCCESS,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: MISSIONS_FAIL
                })
                /* TODO
                 - Add actions when dispatch an error */
            }
        }
    },
    getMissionByUserAction : (userId)=>{
        return async (dispatch)=>{
            dispatch({
                type: START_MISSION_BY_USER
            });

            try{
                const {data} = await SmartPointsApiBase.getElement(`/mission-by-users?filters[idUser][id][$eq]=${userId}&filters[completedAt][$notNull]=true&populate=%2A`);


                dispatch({
                    type: SUCCESS_MISSION_BY_USER,
                    payload : data
                })

            }catch (e) {

                dispatch({
                    type: FAIL_MISSION_BY_USER
                });

            }

        }

    }
}
export default actions;
