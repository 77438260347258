import React from 'react';

const icn_inicio = () => {
  return (
    <svg
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.00663 11.1193C1.37889 12.1883 -0.670951 9.93175 0.836425 8.44733L8.85476 0.489909C9.57794 -0.163303 10.6632 -0.163303 11.3264 0.489909L19.4051 8.44733C20.9124 9.93175 18.8022 12.1886 17.2349 11.1193V18.3046H12.2309V11.7725H7.95053V18.3046H3.00697V11.1193H3.00663Z'
      />
    </svg>
  );
};

export default icn_inicio;
