import styled from 'styled-components';

import { Wrapper } from '../ui/Layout/Wrapper';

export const WrapperSmartUsers = styled(Wrapper)`
  align-items: flex-start;
  color: var(--azul-oscuro);
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 450;
  gap: 4.8rem;
  justify-content: center;
  line-height: 110%;
  min-height: calc(100vh - 154px);
  padding: 3.2rem;
  @media (max-width: 991px) {
    padding: 3.2rem 1.6rem;
    justify-content: flex-start;
  }
`;

export const SectionTitle = styled.p`
  align-items: center;
  color: var(--gray-text);
  display: flex;
  line-height: 110%;
  position: relative;
  span {
    color: var(--azul-claro);
    font: 500 1.4rem/1.6rem var(--font-Base-Bold);
  }
`;

export const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 8rem;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 4rem;
  }
`;

export const Card = styled.div`
  align-items: center;
  background: var(--negro-claro);
  border-radius: 0.8rem;
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  margin-top: 12rem;
  min-width: 25rem;
  padding: 2.4rem;
  @media (max-width: 991px) {
    margin-top: 4rem;
    width: 100%;
  }
  img {
    border-radius: 1.6rem;
    height: 12.8rem;
    margin-top: -8.4rem;
    object-fit: cover;
    object-position: top;
    width: 12.8rem;
  }
`;

export const CardHeader = styled.p`
  font-size: 1.8rem;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const CardText = styled.p`
  font-size: 1.4rem;
  text-align: center;
`;

export const RightWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  margin: 0 auto;
  width: 100%;
`;

export const InputsWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  z-index: 10;
`;

export const SelectButton = styled.button`
  align-items: center;
  background: var(--white);
  border-radius: 10rem;
  border: none;
  color: var(--azul-oscuro);
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  gap: 0.8rem;
  height: 4.3rem;
  justify-content: center;
  padding: 1.4rem 1.6rem;
  position: relative;
  width: 100%;
`;

export const DateInputWrapper = styled.div`
  align-items: center;
  background: var(--white);
  border-radius: 10rem;
  color: var(--azul-oscuro);
  display: flex;
  font-size: 1.4rem;
  font-weight: 450;
  gap: 0.8rem;
  flex: 1;
  height: 4.3rem;
  padding: 0.8rem 1.6rem;
  input {
    padding: 0;
    font-size: 1.4rem;
    color: var(--azul-claro);
  }
  fieldset {
    border: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
  width: 100%;
`;

export const UpdateWrapper = styled.div`
  align-items: flex-end;
  background: var(--gray-terciary);
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 4rem 4.8rem;
  width: 100%;
  @media (max-width: 991px) {
    padding: 1.6rem;
  }
`;

export const UploadWrapper = styled.div`
  align-items: center;
  background: var(--white);
  border: 0.1rem dashed #1d2b4f;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  padding: 4rem 4.8rem;
  width: 100%;
  @media (max-width: 991px) {
    padding: 1.6rem;
  }
  p {
    color: var(--azul-claro);
    font: 500 1.8rem/2.4rem var(--font-Base-Bold);
  }
`;

export const DragInput = styled.div`
  align-items: center;
  border: 0.16rem dashed #1d2b4f;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.6rem 3rem;
`;
