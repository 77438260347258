import React from 'react';
import HeroSecondary from "../features/ui/Hero/HeroSecondary";
import {Header5} from "../features/Utilities/FontStles";
import FormLogin from "../features/Login/FormLogin";
import styled from 'styled-components';
import backRegister from "../Assets/Images/back_register_mobile.png";
import backRegisterDesktop from "../Assets/Images/back_register_desktop.png";

import {Link} from "react-router-dom";
import routes from "../helpers/routes";
import {Alert, Snackbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {auth} from "../redux/actions";

const StyleLayoutLogin = styled.div`

  .register__wrapper {
    display: flex;
    min-height: 60rem;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .register_title{
    font: 400 2.4rem/2.6rem var(--font-Base);
    color: var(--black);
    text-align: center;
  }
  .register_description{
    font: 400 1.6rem/2rem var(--font-Base);
    margin-top: 1.1rem;
    text-align: center;
  }
  .register__form{
    max-width: 40rem;

  }
  .mb-2{
    margin-bottom: 2.4rem;
  }
  .mt-2{
    margin-top: 2.4rem;
  }
  .is-blue{
    color: red !important;
  }

  .is-center{
    text-align: center;
  }
  .link a{
    cursor: pointer;
    text-decoration: none;
  }
  .sub-link a{
    cursor: pointer;
    color: var(--azul-claro);
    text-decoration: none;


  }
  @media( min-width: 995px){
    .register__wrapper{
      min-width: 38rem;
      align-items: flex-end;
      padding-right: 6%;
    }
  }
`;

const LogIn = () => {
    const {error, errorMessage} = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        dispatch(auth.handleClose())
    };

    return (
        <StyleLayoutLogin>

                <HeroSecondary
                    heroBackground={backRegister}
                    heroBackgroundDesktop={backRegisterDesktop}
                >
                    <div className="register__wrapper">
                        <div className="register__form">
                            <h1 className="register_title is-center">Inicia sesión</h1>
                            <h2 className="register_description is-center mb-2">
                              Entra a Smart Points y disfruta de tus beneficios. Si ya realizaste una cuenta desde BeScience, utiliza los mismos datos para ingresar.
                            </h2>
                            <FormLogin/>
                            <Header5 className="is-center mt-2 link">
                                ¿Olvidaste tu contraseña?  <Link to={routes.forgotPassword} className="is_blue is_underlined"> Recupérala</Link>
                            </Header5>
                            <p className="is-center mt-2 sub-link">¿Aún no tienes cuenta? <Link to={routes.register}> Regístrate </Link></p>

                        </div>
                        {
                            error ?
                                <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}
                                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                                    <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                                        {errorMessage}
                                    </Alert>
                                </Snackbar>
                                : ""
                        }
                    </div>
                </HeroSecondary>
        </StyleLayoutLogin>
    );
};

export default LogIn;
