import {useState} from "react";
import styled from "styled-components";
const ImagenWrapper = styled.img`
    width: 20rem;
    height: 18rem;
    border-radius: 1.6rem;
`
const PreviewImage = ({file}) =>{
  const [preview, setPreview] = useState(null);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () =>{
    setPreview(reader.result);
  }
  return (
    <div>
      {preview ? <ImagenWrapper src={preview} alt="preview" /> : "loading"}
    </div>
  );
};
export default PreviewImage;
