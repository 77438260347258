import styled from 'styled-components';

export const PageWrapper = styled.div`
  margin-top: 6rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }

  .form-item {
    label {
      display: block;
      color: var(--black);
      font: 400 1.4rem/1.5rem var(--font-Base);
      margin-left: 1.6rem;
    }
    input {
      :focus {
        border-color: var(--azul-claro);
      }
    }
  }
  .btn-action {
    display: flex;
    justify-content: center;
  }
  @media (min-width: 992px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;
