import styled from "styled-components";
import HeroSecondary from "../ui/Hero/HeroSecondary";
import LayoutTitle from "../ui/Layout/LayoutTitle";
import { Header1 } from "../Utilities/FontStles";
import back_desktop from "../../Assets/Images/back_top_questions_desktop.png";
import back_mobile from "../../Assets/Images/back_top_questions_mobile.png";
import FrequentQuestionsInit from "../FrequentQuestions/FrequentQuestionsInit";
import BackBlueAbout from "../ui/Hero/BackBlueAbout";
import ArrowDown from "../../features/ui/Arrows/ArrowDown";

const FrequentQuestionyWrapper = styled.div`

`;

const QuestionsPublic = () => {
  return (
    
    <FrequentQuestionyWrapper>
      <HeroSecondary
        heroBackground={back_mobile}
        heroBackgroundDesktop={back_desktop}
      >
        <LayoutTitle>
          <Header1>¿Aún con dudas? <br/>Acá te las resolvemos</Header1>
        </LayoutTitle>
        <ArrowDown secundario/>
      </HeroSecondary>
     
      <FrequentQuestionsInit />
      <BackBlueAbout />
    </FrequentQuestionyWrapper>
  );
};

export default QuestionsPublic;
