import styled from "styled-components";

const ListStyled = styled.ul`
  padding-left: 1.5rem;
`
const Item = styled.li`
  color: var(--gray-text);
  font: 450 1.6rem var(--font-Base);

`;

const QuestionList = (props) => {

  const {answer, answers} = props;
  return (
    <>
      <p>{answer}</p>
      <ListStyled>
        {answers.map( item =>(
          <Item>
            {item}
          </Item>
        ))}
      </ListStyled>
    </>
  );
};

export default QuestionList;
