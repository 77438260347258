import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import layout from '../../../Assets/files/ordenes_layout.csv';

import Button from '../../ui/Buttons/Button';
import CustomModal from '../../ui/Modal/CustomModal';
import Loader from '../../ui/Modal/Loader';

import {
  ContentWrapper,
  DragInput,
  RightWrapper,
  SectionTitle,
  UpdateWrapper,
  UploadWrapper,
  WrapperSmartUsers,
} from '../StyledComponents';

import Close from '../../../Assets/icons/close.svg';
import Upload from '../../../Assets/icons/icn_upload.svg';
import UserCard from '../components/UserCard';
import { useMutation } from '@tanstack/react-query';

import { uploadHistory } from '../../../services/history';
import { getUser } from '../../../services/users';
import routes from '../../../helpers/routes';

const SmartUserProfileUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedFiles, setSelectedFiles] = useState([]);

  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser({ id, queryStr: query }),
  });

  const mutation = useMutation({
    mutationFn: uploadHistory,
  });

  const handleUploadFiles = () => {
    const files = document.getElementById('fileInput').files;
    setSelectedFiles(Object.values(files));
  };

  const handleDeleteFile = index => {
    const cleanedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(cleanedFiles);
  };

  const handleFileUpload = () => {
    const formData = new FormData();

    selectedFiles.forEach(file => {
      formData.append('file', file);
    });
    mutation.mutate(formData);
  };

  return (
    <WrapperSmartUsers>
      {(mutation.isSuccess || mutation.isError) && (
        <CustomModal>
          <SectionTitle>
            <span>
              {mutation.isSuccess && 'Recibirás una notificación cuando tus datos sean validados'}
              {mutation.isError && 'Ocurrió un problema con la carga. Vuelve a cargar tus datos.'}
            </span>
          </SectionTitle>
          <Button color='outline-secondary' onClick={() => navigate(-1)}>
            Regresar al perfil
          </Button>
          <Button
            color='outline-tertiary'
            onClick={() => navigate(`/dashboard/${routes.dashboardRoutes.smartUsers}`)}
          >
            Volver al inicio
          </Button>
        </CustomModal>
      )}

      {mutation.isLoading && <Loader />}

      <SectionTitle>
        <Button
          color='ghost'
          onClick={() => navigate(`/dashboard/${routes.dashboardRoutes.smartUsers}`)}
        >
          Usuarios /
        </Button>
        <Button color='ghost' onClick={() => navigate(-1)}>
          {user?.username} /
        </Button>
        <span>Actualización de puntos</span>
      </SectionTitle>

      <ContentWrapper>
        <UserCard user={user} />
        <RightWrapper>
          <UpdateWrapper>
            <UploadWrapper>
              {!selectedFiles.length > 0 ? (
                <React.Fragment>
                  <DragInput>
                    <img src={Upload} alt='icon upload' />
                    <p>Sube aquí tus archivos</p>
                  </DragInput>
                  <Button color='outline-secondary'>
                    <input id='fileInput' multiple type='file' onChange={handleUploadFiles} />
                    Buscalos desde tu ordenador
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <List sx={{ width: '100%' }}>
                    {selectedFiles?.map((file, index) => {
                      return (
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            disableTypography
                            sx={{
                              font: '500 1.8rem/2.4rem var(--font-Base-Bold);',
                            }}
                          >
                            {file.name}
                          </ListItemText>
                          <ListItemIcon>
                            <IconButton onClick={() => handleDeleteFile(index)}>
                              <img src={Close} alt='icon close' />
                            </IconButton>
                          </ListItemIcon>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Button onClick={handleFileUpload}>Subir archivos</Button>
                </React.Fragment>
              )}
            </UploadWrapper>
            <Button
              as='a'
              color='secondary'
              download='ordenes_layout'
              href={layout}
              style={{ textDecoration: 'none' }}
            >
              Descargar layout
            </Button>
          </UpdateWrapper>
          <Button color='outline-tertiary' onClick={() => navigate(-1)}>
            Regresar
          </Button>
        </RightWrapper>
      </ContentWrapper>
    </WrapperSmartUsers>
  );
};

export default SmartUserProfileUpdate;
