import React from "react";
import styled from "styled-components";
import { Wrapper } from "../ui/Layout/Wrapper";
import CarouselHand from "../ui/Carousel/CarouselHand";
const FrequentQuestionyWrapper = styled(Wrapper)`
  .dudes {
    h3 {
      font: 500 1.8rem;
      line-height: 110%;
      text-align: center;
      color: var(--azul-oscuro);
    }
    h4{

      font: 500 1.6rem;
      line-height: 110%;
      text-align: center;
      color: var(--azul-oscuro);
      margin-top: 3.2rem;
      color: var(--gray-text);
    }

    a{
        text-decoration: underline;
        color: black;
    }
  }
  p {
    font-family: var(--font-Base);
    text-align: center;
    max-width: 70rem;
  }
  h3 {
    font: 700 1.6rem var(--font-Base-Bold);
    font-style: normal;
    line-height: 110%;
    text-align: center;
    text-transform: uppercase;
    color: var(--verde);
    margin-top: 4.8rem;
  }

  @media (min-width: 995px) {
    .dudes{
        display: none;
    }
  }
`;
const WhatIs = () => {
  return (
    <FrequentQuestionyWrapper>
      <h2>¿Qué es Smart points?</h2>
      <p>
        Es un programa enfocado en recompensar tu fidelidad, la constante
        búsqueda de aprendizaje y actualización médica. En Bayer queremos ser
        parte de tu esfuerzo para seguir dando lo mejor en salud femenina y
        devolverte un poco de lo mucho que das.
      </p>
      <h3>¿CÓMO FUNCIONA SMART POINTS?</h3>
      <div className="carousel">
        <CarouselHand />
      </div>
      <div className="dudes">
        <h3>¿Aún con dudas?</h3>
        <h4>
          Ve a
          <a href="/dashboard/preguntas-frecuentes">
            <span> preguntas frecuentes </span>
          </a>
          y te poyaremos a resolveras.
        </h4>
      </div>
    </FrequentQuestionyWrapper>
  );
};

export default WhatIs;
