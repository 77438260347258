import React, {useState} from 'react';
import styled from 'styled-components';
import {Link, NavLink} from 'react-router-dom';

import CardHelp from '../Cards/CardHelp';

import routes from '../../../helpers/routes';
import {routesGeneral, routesNavBar} from '../../../helpers/routesArray';

import SmartPointLogo from '../../../Assets/icons/smart_point_logo.svg';
import SmartPointMobile from '../../../Assets/icons/dash_logo_mobile.svg';
import icon_close from '../../../Assets/icons/close.svg';
import icon_menu from '../../../Assets/icons/icon_menu.svg';
import menu_badge from '../../../Assets/Images/menu-badge.svg';
import {auth} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import AnimatedButton from "../Buttons/AnimatedButton";

const NavbarLeftWrapper = styled.div`
  grid-area: sidebar;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 6.5rem;
  padding-right: 6.5rem;

  @media (min-width: 992px) {
    flex-direction: column;
    margin-left: 2rem;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;

    .menu__mobile {
      display: none;
    }
  }
`;
const NavigationNav = styled.nav`
  height: 100vh;
  width: 100%;
  background-color: var(--azul-oscuro);
  position: fixed;
  top: 14.5rem;
  right: 0;
  z-index: 3;
  // transform: scale(10);
  @media (min-width: 992px) {
    display: none;
  }
`;
const NavigationList = styled.ul`
  position: absolute;
  top: 34%;
  left: 42%;
  transform: translate(-50%, -50%);
  list-style: none;

  & li:last-child {
    margin-top: 2rem;
  }

  & li:last-child a {
    color: var(--azul-claro);

  }

`;
const Item = styled.li`
  padding-bottom: 1.5rem;
  .link {
    text-decoration: none;
    color: var(--white);
    font-family: var(--font-Base);
    font-style: normal;
    font-weight: 450;
    font-size: 3rem;
    line-height: 140%;
  }



`;
const NavItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
  font: 450 1.4rem/1.5rem var(--font-Base-Bold);
  text-decoration: none;

`;
const NavBarBrand = styled.picture`
  img {
    margin: 1.5rem 0;
  }

  @media (min-width: 992px) {
    img {
      min-width: 14rem;
      margin: 1.5rem 5rem;
    }
  }
`;
const NavBar = styled.ul`
  display: none;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-left: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 3.8rem;
  }

  a {
    color: var(--gray-fourty);
    text-decoration: none;

    svg {
      fill: var(--gray-fourty);
    }
  }

  a.active {
    color: var(--azul-claro);
    position: relative;

    li {
      gap: 2rem;
    }

    svg {
      fill: var(--azul-claro);
      z-index: 1;
    }

    &:before {
      content: '';
      background: url(${menu_badge}) no-repeat;
      height: 11.5rem;
      left: -1.6rem;
      position: absolute;
      top: -5.2rem;
      width: 4.8rem;
      z-index: 0;
    }
  }
`;

const Header2 = styled.h2`
  display: none;
  @media (min-width: 992px) {
    color: var(--negro-claro);
    display: block;
    font: 700 1.4rem/1.5rem var(--font-Base-Bold);
    padding-left: 1.6rem;
  }
`;


const Sidebar = ({routesByRol, renderGeneral}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {

    setOpen(false)
  }
  const handleLogout = () => {
    dispatch(auth.logOutAction());
  };
  return (
    <>
      <NavbarLeftWrapper>
        <Link to={routes.dashboard}>
          <NavBarBrand>
            <source srcSet={SmartPointLogo} media='(min-width: 992px)'/>
            <img src={SmartPointMobile} alt=''/>
          </NavBarBrand>
        </Link>
        <div className="menu__mobile">
          {
            open ?
              <img src={icon_close} alt="icon menu" height={30} onClick={() => setOpen(false)}/> :
              <img src={icon_menu} alt="icon menu" height={30} onClick={() => setOpen(true)}/>
          }
        </div>
        <NavBar>
          {routesByRol.map((route, index) => {
            return (
              <NavLink end={route.end} key={`route-${index}`} to={route.route}>
                <NavItem>
                  <route.icon/>
                  {route.name}
                </NavItem>
              </NavLink>
            );
          })}
        </NavBar>
        {renderGeneral && (
          <React.Fragment>
            <Header2>Información general</Header2>
            <NavBar>
              {routesGeneral.map((route, index) => (
                <NavLink key={`route-${index}`} to={route.route}>
                  <NavItem>
                    <route.icon/>
                    {route.name}
                  </NavItem>
                </NavLink>
              ))}
            </NavBar>
            <CardHelp/>
          </React.Fragment>
        )}
      </NavbarLeftWrapper>
      {
        open ?
          <NavigationNav>
            <NavigationList>
              {
                routesNavBar.map((route, index) => {
                  return (
                    <Item key={`route-${index}`} onClick={() => handleClick()}>
                      <NavLink className="link" to={route.link}>
                        {route.text}
                      </NavLink>
                    </Item>
                  )
                })
              }
              <AnimatedButton onClick={handleLogout}>Cerrar sesión</AnimatedButton>
            </NavigationList>
          </NavigationNav>
          :
          ""
      }

    </>

  )
    ;

};

export default Sidebar;
