import * as Yup from "yup";
// import SmartPointsApiBase from "../../helpers/SmartPointsBaseApi";

export const ProfessionalSchema = Yup.object().shape({

    licenseNumber: Yup.string()

        .required('Por favor, introduce una cédula profesional válida')
        .min(7, "Cédula no válida")
        /*.test('cedulaValida', 'Cédula no válida.', async (data) =>{
            return new Promise(async (resolve,reject)=>{
                try{
                    const response= await SmartPointsApiBase.postProxySmartPoints(`/valida-cedula`, {idCedula:data});
                    if(response.length > 0){
                        resolve(true)
                    }
                   reject(false)
                }catch (e) {
                    reject(false)
                }
            })

        })*/,
    isProfessional: Yup.bool()
        .oneOf([true], '- Autoriza que eres un Profesional de la Salud en México.'),
    isOkSubscribeNewsLater : Yup.bool()
        .oneOf([true], '- Autoriza recibir información de Smart Points.'),
    isOkUsePersonalData : Yup.bool()
        .oneOf([true], '- Autoriza el uso de datos personales.'),
});
