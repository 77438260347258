import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Divider, List, ListItem, ListItemButton, Paper, TextField } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import Button from '../../ui/Buttons/Button';

import {
  DateInputWrapper,
  InputsWrapper,
  SectionTitle,
  SelectButton,
  SelectWrapper,
  WrapperSmartUsers,
} from '../StyledComponents';

import ArrowDown from '../../../Assets/icons/icn_flecha_down.svg';
import Filter from '../../../Assets/icons/icn_active_filtro.svg';
import MisionsTable from '../components/MisionsTable';

import { getBuys } from '../../../services/buys';
import { getMissions } from '../../../services/missions';
import { getPurchaseDetails } from '../../../services/purchase-details';
import { getUser } from '../../../services/users';

import routes from '../../../helpers/routes';

export const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  margin: 0 auto;
  width: 80%;
  height: 100%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const SmartUserProfileHistory = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [toggleFilters, setToggleFilters] = useState(false);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [firstDate, setFirstDate] = useState(dayjs('2021/01/01'));
  const [secondDate, setSecondDate] = useState(dayjs(new Date()));
  const [selectedMission, setSelectedMission] = useState('');

  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const buysByUserQuery = useMemo(
    () =>
      qs.stringify(
        {
          populate: '*',
          filters: {
            user: id,
            buy_date: {
              $gte: firstDate.format(),
              $lte: secondDate.format(),
            },
          },
        },
        {
          encodeValuesOnly: true,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstDate.format(), secondDate.format()],
  );

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser({ id, queryStr: query }),
  });

  const { data: missions } = useQuery({
    queryKey: ['missions'],
    queryFn: () => getMissions({ queryStr: query }),
  });

  const { data: buysByUser } = useQuery({
    queryKey: ['buysByUserAll', buysByUserQuery],
    queryFn: () => getBuys({ queryStr: buysByUserQuery }),
  });

  const buysByUserIds = buysByUser?.reduce((acc, { id }) => {
    return [...acc, id];
  }, []);

  const purchaseDetailsQuery = useMemo(
    () =>
      qs.stringify(
        {
          populate: '*',
          filters: {
            purchase_id: {
              id: {
                $in: buysByUserIds,
              },
            },
            product: {
              name: {
                $contains: selectedMission,
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        },
      ),
    [buysByUserIds, selectedMission],
  );

  const { data: purchaseDetails } = useQuery({
    queryKey: ['purchaseDetails', purchaseDetailsQuery],
    queryFn: () => getPurchaseDetails({ queryStr: purchaseDetailsQuery }),
  });

  const cleanedPurchaseDetails = purchaseDetails?.reduce((acc, { attributes }) => {
    const {
      product: {
        data: {
          attributes: { name },
        },
      },
      purchase_id: {
        data: {
          attributes: { buy_date },
        },
      },
      ...purchase
    } = attributes;
    return [...acc, { ...purchase, name, buy_date }];
  }, []);

  const cleanFilters = () => {
    setFirstDate(dayjs('2021/01/01'));
    setSecondDate(dayjs(new Date()));
    setSelectedMission('');
  };

  return (
    <WrapperSmartUsers>
      <SectionTitle>
        <Button
          color='ghost'
          onClick={() => navigate(`/dashboard/${routes.dashboardRoutes.smartUsers}`)}
        >
          Usuarios /
        </Button>
        <Button color='ghost' onClick={() => navigate(-1)}>
          {user?.username} /
        </Button>
        <span>Historial</span>
      </SectionTitle>

      <ContentWrapper>
        <SectionTitle>Aquí encontrarás el historial de movimientos por usuario</SectionTitle>
        <InputsWrapper>
          <Button color='secondary' onClick={() => setToggleFilters(!toggleFilters)}>
            <img src={Filter} alt='icon filters' /> Filtros
          </Button>

          {toggleFilters && (
            <React.Fragment>
              <SelectWrapper>
                <SelectButton onClick={() => setToggleSelect(!toggleSelect)}>
                  Selecciona las misiones a filtrar <img src={ArrowDown} alt='icon chevron down' />
                </SelectButton>
                {toggleSelect && (
                  <Paper
                    sx={{
                      top: 48,
                      position: 'absolute',
                      width: '100%',
                    }}
                  >
                    <List sx={{ p: 0 }}>
                      {missions.map(({ attributes: { name, slug } }, i) => (
                        <React.Fragment key={`${name} list-item`}>
                          <ListItem
                            sx={{
                              backgroundColor:
                                selectedMission === name ? 'var(--gris-claro)' : 'white',
                            }}
                          >
                            <ListItemButton
                              onClick={() => {
                                setSelectedMission(slug);
                                setToggleSelect(false);
                              }}
                            >
                              {name}
                            </ListItemButton>
                          </ListItem>
                          {i !== missions.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  </Paper>
                )}
              </SelectWrapper>

              <DateInputWrapper>
                De:
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    closeOnSelect
                    inputFormat='MM/DD/YYYY'
                    maxDate={secondDate}
                    minDate={firstDate}
                    value={firstDate}
                    onChange={newValue => setFirstDate(newValue)}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
                a:
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    closeOnSelect
                    inputFormat='MM/DD/YYYY'
                    maxDate={secondDate}
                    minDate={firstDate}
                    value={secondDate}
                    onChange={newValue => setSecondDate(newValue)}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </DateInputWrapper>

              <Button color='tertiary' onClick={cleanFilters}>
                Limpiar
              </Button>
            </React.Fragment>
          )}
        </InputsWrapper>

        <MisionsTable data={cleanedPurchaseDetails} />

        <Button color='secondary' style={{ alignSelf: 'center' }}>
          Descargar historial
        </Button>
      </ContentWrapper>
    </WrapperSmartUsers>
  );
};

export default SmartUserProfileHistory;
