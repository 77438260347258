import React from 'react';
import styled from 'styled-components';

const BoldText = styled.strong`
  font-weight: bold;
  color:var(--azul-oscuro);
  font: var(--font-Base-Bold);
`
const QuestionBold = (props) => {
  const {answer, boldText} =props;

  return <p>{answer} <BoldText>{boldText}</BoldText>.</p>
};

export default QuestionBold;
