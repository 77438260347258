import { combineReducers} from "redux";
import authReducer from "./authReducer";
import contactUsReducer from "./contactUsReducer";
import missionsReducer from "./missionsReducer";
import levelsReducer from "./levelsReducer";
import homeReducer from "./homeReducer";
import distributorReducer from "./distributorReducer";

export default combineReducers({
    auth : authReducer,
    contactUs : contactUsReducer,
    missions: missionsReducer,
    levels: levelsReducer,
    home: homeReducer,
    distributor: distributorReducer

});
