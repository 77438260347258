import React from "react";
import {Swiper, SwiperSlide }from "swiper/react";
import SwiperCore, { Autoplay,  Navigation } from 'swiper';
import IconArrow from "../../../Assets/icons/icn_flecha.svg";

import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";


SwiperCore.use([Autoplay]);

const Carousel = ({data}) => {
  const cards = data.filter(item =>item.attributes.isActive === true );

  return (
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Navigation]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}

        className="mySwiper"
      >
        {
          cards.map(card =>{
            const {title, linkText, linkTo, backgroundImage} = card.attributes;
            const url = `https://smartpointsback-dev.bahia360.mx${backgroundImage.data.attributes.url}`;
            return(
              <SwiperSlide
                key={card.id}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0), #11374F), url(${url})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "inherit",

                }}

              >
                <div className="swiper-content">

                  <h1 className="swiper-content__title">{title}</h1>

                  <div className="swiper-content__bar"></div>
                  <a
                    href={linkTo}
                    target="_blank"
                    rel="noreferrer">  {linkText}
                  <img
                    src={IconArrow}
                    alt="arrow"/>
                  </a>
                </div>
              </SwiperSlide>
            )
          })

        }

      </Swiper>
    );
};

export default Carousel;
